import React from 'react'
import CountUp from 'react-countup'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
const useStyles = makeStyles(theme => ({
  root: {
    height: '50vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  header: {
    marginBottom: 24
  }
}))

export default () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant='h1' className={classes.header}>
        <CountUp
          end={404}
          separator=''
        />
      </Typography>
      <Typography variant='subtitle1' color='textSecondary'>
        Sidan du söker hittades inte
      </Typography>
    </div>
  )
} 