import React, { useCallback, useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { Form, Formik, FastField as Field, FieldArray } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import { useDropzone } from 'react-dropzone'
import { Route, Switch, Link } from 'react-router-dom'
import ReactToPrint from 'react-to-print'

import WarningIcon from '@material-ui/icons/Warning'
import { DialogTitle, DialogContentText, FormHelperText, Tooltip } from '@material-ui/core'

import ViewIcon from '@material-ui/icons/RemoveRedEye'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Check from '@material-ui/icons/Check'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'

import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import LinkIcon from '@material-ui/icons/Link'

import Chip from '@material-ui/core/Chip'

import KeyboardIcon from '@material-ui/icons/Keyboard'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'


import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import StepConnector from '@material-ui/core/StepConnector'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'

import TextField from '../fields/TextField'
import BarcodeField from '../fields/BarcodeField'
import { dataService } from '../../services'
import Barcode from '../../icons/BarcodeIcon'
import Button from '../Button'
import ReactSelect from '../fields/ReactSelect'

import RadioGroupField from '../fields/RadioGroupField'
import LatestUpdates from './LatestUpdates'
import CheckboxField from '../fields/CheckboxField'
import ViewUpdates from './ViewUpdates'
import AllUpdates from './AllUpdates'

import BarcodeGenerator from '../BarcodeGenerator'
import { error, primary, background } from '../colors'

import { TablePagination } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const TabPanel = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && children}
  </div>
)

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    minWidth: 'auto',
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />);

const QontoConnector = withStyles({
  active: {
    '& $line': {
      borderColor: '#1a76d0',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1a76d0',
    },
  },
  line: {
    borderWidth: 3
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
    width: 26
  },
  active: {
    color: '#1a76d0',
    '&>$circle': {
      animation: `$pulseAnimation 1s infinite`
    }
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#1a76d0',
    zIndex: 1,
    fontSize: 18,
  },
  '@keyframes pulseAnimation': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(26,118,208, 0.4)'
    },
    '70%': {
      boxShadow: '0 0 0 10px rgba(26,118,208, 0)'
    },
    '100%': {
      boxShadow: ' 0 0 0 0 rgba(26,118,208, 0)'
    }
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

// const testLoading = () => {
//   return new Promise(resolve => {
//     setTimeout(() => {
//       resolve()
//     }, 500)
//   })
// }


const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: 70,
    overflow: 'scroll'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  },
  wrapper: {
    width: '100%',
    padding: 24,
    height: '100%'
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20
  },
  fabIcon: {
    marginRight: 10
  },
  stepContent: {
    borderWidth: 3
  },
  activeLabel: {
    fontSize: '1.5rem'
  },
  flexLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dialogRoot: {
    display: 'flex',
    flexDirection: 'column'
  },
  dropzone: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderColor: '#cbcbcb',
    borderStyle: 'dashed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 113,
    // width: 113,
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
    transition: 'transform .2s cubic-bezier(.29,1.09,.74,1.3), opacity .2s ease-in-out',
    padding: 10,
    paddingTop: '56.25%',
    cursor: 'pointer',
    outline: 'none',
    marginTop: 20,
    '&:hover': {
      // transform: 'scale(1.05)',
      opacity: 0.7
    }
  },
  errorDropzone: {
    borderColor: error
  },
  notEmpty: {
    border: 0
  },
  dropzoneWrapper: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateY(-50%)',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  dropActive: {
    transform: 'scale(0.95)',
    opacity: 0.7
  },
  warningRoot: {
    display: 'flex',
    alignItems: 'center'
  },
  warningActions: {

  },
  rowWrapper: {
    display: 'flex'
  },
  bundleWrapper: {
    // borderRight: `1px solid ${primary}`,
    // borderLeft: `1px solid ${primary}`,
    backgroundColor: background,
    padding: '0 20px'
  },
  firstBundleWrapper: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: 'column',
    // borderTop: `1px solid ${primary}`,
    paddingTop: 20,
    marginTop: 10
  },
  lastBundleWrapper: {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    // borderBottom: `1px solid ${primary}`,
    paddingBottom: 20,
    marginBottom: 10
  },
  firstBundleRow: {
    display: 'flex'
  },
  showQuantity: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10
  },
  quantity: {
    marginRight: 10
  },
  // helperText: {
  //   margin:
  // }
}))

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

  return [htmlElRef, setFocus]
}

const buildFilter = params => Object.keys(params)
  .filter(key => params[key] !== null)
  .reduce((result, key, index) =>
    `${result}${index > 0 ? '&' : ''}filter[${index}][key]=${key}&filter[${index}][value]=${params[key]}`
    , '')

export default ({ location, match }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [activeStep, setActiveStep] = useState(0)
  const [storage, setStorage] = useState({})
  const [storages, setStorages] = useState([])
  const [statuses, setStatuses] = useState([])
  const [storageLocations, setStorageLocations] = useState([])
  const [openChecklist, setOpenChecklist] = useState({ open: false, label: '' })
  const [openOldFirst, setOpenOldFirst] = useState({ open: false, change: [], label: '' })
  const [openLocked, setOpenLocked] = useState({ open: false, items: [], label: '' })
  const [receipt, setReceipt] = useState([])
  const [total, setTotal] = useState(0)
  const [dataId, setDataId] = useState(null)
  const [code, setCode] = useState(false)
  const [hook, setHook] = useState('Ingen_inleverans')
  const [loading, setLoading] = useState(true)
  const [imageUpload, setImageUpload] = useState(false)
  const [inputRef, setInputFocus] = useFocus()
  // const [imagePath, setImagePath] = useState(null)
  const [scannerMode, setScannerMode] = useState(true)
  const [refresh, setRefresh] = useState(Date)
  const [value, setValue] = useState(0)
  const [barcode, setBarcode] = useState({ code: '' })
  const [loadEan, setLoadEan] = useState('')
  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)

  const cooldown = useRef(false)


  const componentRef = useRef()

  const onDropAccepted = ({ acceptedFiles, setFieldValue }) => {
    const values = new FormData()
    setImageUpload(true)
    acceptedFiles.forEach(file => {
      values.append('image', file)
    })
    dataService.createData({ model: 'stock_updates/image', values })
      .then(
        data => {
          const { url } = data
          setImageUpload(false)
          setFieldValue('image_url', url)
          // setImagePath(url)
        },
        error => {
          debugger
        })
  }

  const onDropRejected = () => {
    debugger
  }

  useEffect(() => {
    dataService.getData({ model: 'storages', params: '?pagination=off' })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label, check_in: option.check_in })
        })
        setStorages(options)
      })
    dataService.getData({ model: 'statuses', params: '?pagination=off' })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        setStatuses(options)
      })
  }, [])


  const getArticle = (item, arrayHelpers, setFieldValue) => {
    const { form: { values: { items } } } = arrayHelpers
    if (item && item.length > 4) {
      if (items && items.some(i => i.serial_number === item)) {
        setFieldValue('item', '')
        enqueueSnackbar('Redan scannad', { variant: 'error' })
        return
      }
      // console.log(item)
      dataService.getData({ model: 'items', params: `?filter[0][key]=serial_number&filter[0][value]=${item}&pagination=off` })
        .then(data => {
          const { result } = data
          // console.log(result)
          setFieldValue('item', '')
          setInputFocus()
          if (result && result.length > 0) {
            const {
              id,
              article,
              serial_number,
              storage,
              storage_location,
              pallet,
              box,
              bundle,
            } = result[0]
            if (Boolean(bundle && bundle.items && bundle.items.length > 1)) {
              bundle.items.map(item => (
                arrayHelpers.push({
                  id: item.id,
                  article: item.article,
                  serial_number: item.serial_number,
                  storage: item.storage,
                  storage_location: item.storage_location,
                  pallet: item.pallet,
                  box: item.box,
                  bundle_id: item.bundle_id,
                  include: true,
                  type: 'individ'
                })
              ))
            } else {
              return arrayHelpers.push({
                id,
                article,
                serial_number,
                storage,
                storage_location,
                pallet,
                box,
                type: 'individ',
                include: true
              })
            }
          }
          else {
            enqueueSnackbar(`${item} finns inte i systemet`, { variant: 'error' })
          }
        })
    }
    return
  }

  const getItems = (value, key, arrayHelpers, setFieldValue) => {
    if (cooldown.current) {
      return
    }

    const { form: { values: { items } } } = arrayHelpers

    if (value && value.replace(String.fromCharCode(16), "").length >= 4) {
      if (items && items.find(i => i.value === value && i.type === key)) {
        setFieldValue('item', '')
        enqueueSnackbar('Redan scannad', { variant: 'error' })
        return
      }
      dataService.createData({ model: 'items/find_and_group', values: { [key]: [value] } })
        .then(data => {
          const { total, result } = data
          setFieldValue('item', '')
          setInputFocus()
          if (total > 0) {
            result.forEach(res => arrayHelpers.push(({ ...res, include: true })))
            return true

          } else {
            enqueueSnackbar(`${value} finns inte i systemet`, { variant: 'error' })
          }
        })

    }
  }

  useEffect(() => {
    if (dataId === null) return
    setLoading(true)
    dataService.getData({ model: `stock_updates/${dataId}/items?&page=${page}&per_page=${perPage}`, params: '' })
      .then(data => {
        setReceipt(data.result)
        setTotal(data.total)
        // console.log(data.total)
        setLoading(false)
      })
  }, [dataId, page, perPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = event => {
    setPerPage(event.target.value)
    setPage(1)
  }

  const getStorageLocations = storage_id => {
    setStorageLocations([])
    dataService.getData({ model: 'storage_locations', params: `?filter[0][key]=storage_id&filter[0][value]=${storage_id}&pagination=off` })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        setStorageLocations(options)
      })
  }

  const setStorageValues = ({ storage_location_id, storage_id, status_id }) => {
    handleNext()
    setStorage({ storage_location_id, storage_id, status_id })
  }

  const goBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleCloseChecklist = () => {
    setOpenChecklist({ open: false, label: '' })
  }

  const handleOldFirst = () => {
    setOpenOldFirst({ open: false, change: [], label: '' })
  }

  const cancelSubmission = ({ resetForm }) => {
    setOpenOldFirst({ open: false, change: [], label: '' })
    setOpenLocked({ open: false, items: [], label: '' })
    resetForm()
  }

  const getStorageByCode = ({ ean, setFieldValue, submitForm, storages }) => {
    dataService.getData({ model: 'storage_locations', params: `?filter[0][key]=ean&filter[0][value]=${ean}` })
      .then(data => {
        const { result } = data
        if (result.length > 0) {
          const options = []
          result.forEach(option => {
            options.push({ value: option.id, label: option.label })
          })
          setStorageLocations(options)
          const storage = storages.find(st => st.value === result[0].storage_id)
          setFieldValue('storage_id', storage.value)
          setFieldValue('storage_location_id', result[0].id)
          setCode(ean)
          submitForm()
        }
      })
  }

  const tabProps = index => (
    {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    }
  )

  const tabChange = (e, newValue) => {
    cooldown.current = true
    setValue(newValue)
    setTimeout(() => {
      cooldown.current = false
    }, 1000)
  }

  const createEan = useCallback(type => {
    if (!dataId) {
      return
    }

    setLoadEan(type)
    dataService.getData({ model: 'stock_updates/ean', params: '' })
      .then(
        code => {
          // const values = receipt.map(item => ({ id: item.id, [type]: code, [`keep_${type}`]: true }))
          const values = {
            keep_box: true,
            keep_pallet: true,
            [type]: code,
          }
          dataService.editData({ model: `stock_updates/${dataId}/items/update`, values })
            .then(
              data => {
                setLoadEan('')
                setBarcode({ code })
              },
              error => {
                setLoadEan('')
                // console.log(error)
              }
            )
        },
        error => {
          setLoadEan('')
          // console.log(error)
        }
      )
  }, [dataId])


  const ImageDrop = ({ setFieldValue, value, errors, touched }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDropAccepted: acceptedFiles => onDropAccepted({ acceptedFiles, setFieldValue }),
      onDropRejected
    })
    return (
      <React.Fragment>
        <div {...getRootProps()} className={clsx(classes.dropzone, isDragActive && classes.dropActive, Boolean(value) && classes.notEmpty, {
          [classes.errorDropzone]: Boolean(touched && touched['image_url'] && errors && errors['image_url'])
        })}>
          <div
            className={classes.dropzoneWrapper}
            style={{ backgroundImage: `url(${value})` }}
          >
            {imageUpload ? (
              <CircularProgress size={45} />
            ) : (
              <React.Fragment>
                {!Boolean(value) && (
                  <React.Fragment>
                    <AddAPhotoIcon style={{ fontSize: 60, fill: '#cbcbcb', marginBottom: 10 }} />
                    <Typography variant='body2' color='textSecondary'>Lägg till bild</Typography>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <input
              name='image_url'
              {...getInputProps()}
            />
          </div>
        </div>
        {Boolean(touched && touched['image_url'] && errors && errors['image_url']) && (
          <FormHelperText
            variant='outlined'
            error={true}
            className={clsx(classes.helperText, {
              [classes.error]: touched && touched['image_url'] && errors && errors['image_url']
            })}
          >
            {errors['image_url']}
          </FormHelperText>
        )}
      </React.Fragment>
    )
  }

  const displayProps = {
    disabled: true,
    variant: 'outlined',
    margin: 'normal',
    type: 'text',
    fullWidth: true,
    component: TextField,
    style: {
      marginRight: 16,
    },
    InputLabelProps: {
      shrink: true
    },
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={8}>
        <Switch>
          <Route
            exact
            path={match.url}
            render={props => (
              <Paper className={classes.paper}>
                <Toolbar className={classes.toolbar}>
                  <Typography variant='h6'>
                    Lagerflytt
                  </Typography>
                </Toolbar>
                <div className={classes.wrapper}>
                  <Stepper activeStep={activeStep} connector={<QontoConnector />} orientation='vertical'>
                    <Step>
                      <StepLabel
                        classes={{
                          active: classes.activeLabel
                        }}
                        StepIconComponent={QontoStepIcon}
                        onClick={activeStep > 0 && activeStep !== 3 ? () => goBack() : undefined}
                      >
                        Välj lager & lagerplats
                        {activeStep > 0 && activeStep !== 3 && (
                          <IconButton>
                            <EditIcon style={{ fontSize: 18 }} />
                          </IconButton>
                        )}
                      </StepLabel>
                      <StepContent classes={{
                        root: classes.stepContent
                      }}>
                        <Formik
                          initialValues={{}}
                          enableReinitialize
                          onSubmit={(values, { setSubmitting, resetForm }) => {
                            setStorageValues(values)
                            setSubmitting(false)
                            resetForm()
                          }}
                          validationSchema={Yup.object().shape({
                            storage_id: Yup.number().required('Du måste välja lager')

                          })}
                        >
                          {({
                            submitForm,
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                            setFieldTouched
                          }) => (
                            <Form>
                              <Field
                                name='barcode'
                                label='Skanna lagerplats'
                                variant='outlined'
                                margin='normal'
                                autoFocus
                                type='text'
                                fullWidth
                                onComplete={scannerMode ? data => getStorageByCode({ ean: data, setFieldValue, submitForm, storages }) : undefined}
                                InputProps={{
                                  inputProps: {
                                    onBlur: !scannerMode ? () => getStorageByCode({ ean: values.barcode, setFieldValue, submitForm, storages }) : undefined
                                  },
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton
                                        onClick={() => setScannerMode(!scannerMode)}
                                      >
                                        {scannerMode ? (
                                          <Barcode />
                                        ) : (
                                          <KeyboardIcon />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                component={scannerMode ? BarcodeField : TextField}
                              />
                              <Typography
                                variant='subtitle1'
                                color='primary'
                                style={{
                                  textAlign: 'center'
                                }}
                              >
                                eller
                              </Typography>
                              <ReactSelect
                                name='storage_id'
                                placeholder='Välj lager'
                                label='Till lager'
                                options={storages}
                                isDisabled={code}
                                onChange={option => {
                                  getStorageLocations(option ? option.value : null)
                                  setHook(option ? option.value : null)
                                  return setFieldValue('storage_id', option ? option.value : null)
                                }}
                                onBlur={() => setFieldTouched('storage_id', true)}
                                errors={errors}
                                touched={touched}
                                value={storages.find(option => option.value === values['storage_id'])}
                              />
                              <ReactSelect
                                name='storage_location_id'
                                placeholder='Välj lagerplats'
                                label='Till lagerplats'
                                options={storageLocations}
                                onChange={option => setFieldValue('storage_location_id', option ? option.value : null)}
                                onBlur={() => setFieldTouched('storage_location_id', true)}
                                errors={errors}
                                touched={touched}
                                isDisabled={!values['storage_id'] || !storageLocations.length || code}
                                value={storageLocations.find(option => option.value === values['storage_location_id'])}
                              />
                              <Button
                                type='submit'
                                variant='contained'
                                aria-label='submit'
                                color='primary'
                                disabled={isSubmitting}
                              >
                                Nästa
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepLabel
                        classes={{
                          active: classes.activeLabel
                        }}
                        StepIconComponent={QontoStepIcon}
                        onClick={activeStep > 1 && activeStep !== 3 ? () => goBack() : undefined}
                      >
                        Välj status
                        {activeStep > 1 && activeStep !== 3 && (
                          <IconButton>
                            <EditIcon style={{ fontSize: 18 }} />
                          </IconButton>
                        )}
                      </StepLabel>
                      <StepContent
                        classes={{
                          root: classes.stepContent
                        }}
                      >
                        <Formik
                          initialValues={storage}
                          enableReinitialize
                          onSubmit={(values, { setSubmitting, resetForm }) => {
                            setStorageValues(values)
                            setSubmitting(false)
                            resetForm()
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                            submitForm,
                            resetForm,
                            setFieldTouched
                          }) => (
                            <Form>
                              <ReactSelect
                                name='storage_id'
                                isDisabled
                                label='Till lager'
                                options={storages}
                                errors={errors}
                                touched={touched}
                                value={storages.find(option => option.value === values['storage_id'])}
                              />
                              <ReactSelect
                                name='storage_location_id'
                                isDisabled
                                placeholder='Ingen'
                                label='Till lagerplats'
                                options={storageLocations}
                                errors={errors}
                                touched={touched}
                                value={storageLocations.find(option => option.value === values['storage_location_id'])}
                              />
                              <ReactSelect
                                name='status_id'
                                placeholder='Välj status'
                                label='Till status'
                                options={statuses}
                                onChange={option => setFieldValue('status_id', option ? option.value : null)}
                                onBlur={() => setFieldTouched('status_id', true)}
                                errors={errors}
                                touched={touched}
                              />
                              <Button
                                type='submit'
                                variant='contained'
                                aria-label='submit'
                                color='primary'
                                disabled={isSubmitting}
                              >
                                Nästa
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepLabel
                        classes={{
                          active: classes.activeLabel
                        }}
                        StepIconComponent={QontoStepIcon}
                      >
                        Scanna individer
                      </StepLabel>
                      <StepContent classes={{
                        root: classes.stepContent
                      }}>
                        <Formik
                          initialValues={{ ...storage, image_url: '', description: '' }}
                          enableReinitialize
                          validationSchema={Yup.object().shape({
                            description: Yup.string().when('update_status', {
                              is: 'not_ok',
                              then: Yup.string().required('Du måste skriva en felbeskrivning'),
                              otherwise: Yup.string()
                            }),
                            image_url: Yup.string().when('update_status', {
                              is: 'not_ok',
                              then: Yup.string().required('Du måste ladda upp en bild'),
                              otherwise: Yup.string()
                            })
                          })}
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            const {
                              storage_id,
                              storage_location_id,
                              status_id,
                              description,
                              update_status,
                              ignore_old_first,
                              ignore_lock,
                              image_url
                            } = values

                            const payload = {
                              storage_id,
                              storage_location_id,
                              status_id,
                              update_status,
                              description,
                              image_url,
                              ignore_old_first,
                              ignore_lock,
                              query: values.items.filter(i => i.include)
                            }

                            setOpenChecklist(prev => ({ ...prev, open: false }))
                            setOpenOldFirst(prev => ({ ...prev, open: false }))
                            setOpenLocked(prev => ({ ...prev, open: false }))

                            try {
                              const data = await dataService.createData({ model: 'stock_updates', values: payload })
                              setSubmitting(false)
                              setRefresh(Date)
                              setReceipt([])
                              setOpenChecklist({ open: false, label: '' })
                              setOpenOldFirst({ open: false, change: [], label: '' })
                              setOpenLocked({ open: false, items: [], label: '' })
                              resetForm()
                              handleNext()
                              setDataId(data.id)

                            } catch (error) {
                              if (error.status === 423) {
                                const { error: { type, label, change, result } } = error
                                switch (type) {
                                  case 'show_checklist':
                                    setOpenChecklist({ open: true, label })
                                    break
                                  case 'old_first':
                                    setOpenOldFirst({ open: true, change, label })
                                    break
                                  case 'locked_status':
                                    setOpenLocked({ open: true, items: result, label })
                                    break
                                  default:
                                    setOpenChecklist({ open: true, label })
                                    break
                                }
                              }
                              setSubmitting(false)
                            }
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                            submitForm,
                            resetForm,
                            setFieldTouched
                          }) => (
                            <Form>
                              <ReactSelect
                                name='storage_id'
                                isDisabled
                                label='Till lager'
                                options={storages}
                                errors={errors}
                                touched={touched}
                                value={storages.find(option => option.value === values['storage_id'])}
                              />
                              <ReactSelect
                                name='storage_location_id'
                                isDisabled
                                placeholder='Ingen'
                                label='Till lagerplats'
                                options={storageLocations}
                                errors={errors}
                                touched={touched}
                                value={storageLocations.find(option => option.value === values['storage_location_id'])}
                              />
                              <FieldArray
                                name='items'
                                render={arrayHelpers => (

                                  <React.Fragment>
                                    {values.items && values.items.map((item, index) => (
                                      <div
                                        key={index}
                                        className={clsx(classes.rowWrapper, {
                                          [classes.bundleWrapper]: item.bundle_id,
                                          [classes.firstBundleWrapper]: item.bundle_id && (!values.items[index - 1] || item.bundle_id !== values.items[index - 1].bundle_id),
                                          [classes.lastBundleWrapper]: item.bundle_id && (!values.items[index + 1] || item.bundle_id !== values.items[index + 1].bundle_id)
                                        })}
                                      >
                                        {item.bundle_id && (!values.items[index - 1] || item.bundle_id !== values.items[index - 1].bundle_id) ? (
                                          <React.Fragment>
                                            <LinkIcon color='primary' />
                                            <div className={classes.firstBundleRow}>
                                              <Field
                                                type='hidden'
                                                name={`items[${index}][id]`}
                                              />
                                              <Field
                                                name={`items[${index}][article]`}
                                                label={'Artikel'}
                                                {...displayProps}
                                              />
                                              <Field
                                                name={`items[${index}][serial_number]`}
                                                label={'Serienummer'}
                                                {...displayProps}
                                              />
                                              {item.params.storage_location_id ? (
                                                <Field
                                                  name={`items[${index}][labels][storage_location]`}
                                                  label={'Lagerplats'}
                                                  {...displayProps}
                                                />
                                              ) : (
                                                <Field
                                                  name={`items[${index}][labels][storage]`}
                                                  label={'Lager'}
                                                  {...displayProps}
                                                />
                                              )}
                                              <Field
                                                name={`items[${index}][include]`}
                                                component={CheckboxField}
                                                type='checkbox'
                                                color='primary'
                                                checked={values['items'][index]['include']}
                                                value={values['items'][index]['include']}
                                                handleToggle={event => {
                                                  setFieldValue(`items[${index}][include]`, event.target.checked)
                                                }}
                                              />
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          item.type === 'serial_number' ? (
                                            <React.Fragment>
                                              <Field
                                                name={`items[${index}][value]`}
                                                label={'Serienummer'}
                                                {...displayProps}
                                              />
                                              <Field
                                                name={`items[${index}][labels][article]`}
                                                label={'Artikel'}
                                                {...displayProps}
                                              />
                                              {item.params.storage_location_id ? (
                                                <Field
                                                  name={`items[${index}][labels][storage_location]`}
                                                  label={'Lagerplats'}
                                                  {...displayProps}
                                                />
                                              ) : (
                                                <Field
                                                  name={`items[${index}][labels][storage]`}
                                                  label={'Lager'}
                                                  {...displayProps}
                                                />
                                              )}
                                              <Field
                                                name={`items[${index}][labels][status]`}
                                                label={'Status'}
                                                {...displayProps}
                                              />

                                              <Field
                                                name={`items[${index}][include]`}
                                                component={CheckboxField}
                                                type='checkbox'
                                                color='primary'
                                                checked={values['items'][index]['include']}
                                                value={values['items'][index]['include']}
                                                handleToggle={event => {
                                                  setFieldValue(`items[${index}][include]`, event.target.checked)
                                                }}
                                              />
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              <Field
                                                name={`items[${index}][value]`}
                                                label={item.type === 'pallet'
                                                  ? 'Pallnummer'
                                                  : item.type === 'box'
                                                    ? 'Lådnummer'
                                                    : item.type === 'order_number'
                                                      ? 'Ordernummer'
                                                      : 'Streckkod'
                                                }
                                                {...displayProps}
                                              />
                                              <Field
                                                name={`items[${index}][labels][article]`}
                                                label={'Artikel'}
                                                {...displayProps}
                                              />
                                              {item.params.storage_location_id ? (
                                                <Field
                                                  name={`items[${index}][labels][storage_location]`}
                                                  label={'Lagerplats'}
                                                  {...displayProps}
                                                />
                                              ) : (
                                                <Field
                                                  name={`items[${index}][labels][storage]`}
                                                  label={'Lager'}
                                                  {...displayProps}
                                                />
                                              )}
                                              <Field
                                                name={`items[${index}][labels][status]`}
                                                label={'Status'}
                                                {...displayProps}
                                              />
                                              <Field
                                                name={`items[${index}][total]`}
                                                label={'Antal'}
                                                {...displayProps}
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position='end'>

                                                      <Tooltip title='Visa' aria-label='read' >
                                                        <IconButton
                                                          component={Link}
                                                          to={`${match.url.split('/move').join('')}?${buildFilter(item.params)}`}
                                                          target={'_blank'}
                                                        >
                                                          <ViewIcon />
                                                        </IconButton>
                                                      </Tooltip>

                                                    </InputAdornment>
                                                  )
                                                }}
                                              />
                                              <Field
                                                name={`items[${index}][include]`}
                                                component={CheckboxField}
                                                type='checkbox'
                                                color='primary'
                                                checked={values['items'][index]['include']}
                                                value={values['items'][index]['include']}
                                                handleToggle={event => {
                                                  setFieldValue(`items[${index}][include]`, event.target.checked)
                                                }}
                                              />
                                            </React.Fragment>

                                          )
                                        )}
                                      </div>
                                    ))}
                                    <div className={classes.showQuantity}>
                                      {values.items && Boolean(values.items.filter(item => item.type === 'pallet').length === 1) ? (
                                        <Typography className={classes.quantity} variant='body2' color='textSecondary'>{values.items.filter(item => item.type === 'pallet').length} Pall</Typography>
                                      ) : values.items && Boolean(values.items.filter(item => item.type === 'pallet').length > 1) ? (<Typography className={classes.quantity} variant='body2' color='textSecondary'>{values.items.filter(item => item.type === 'pallet').length} Pallar</Typography>) : null}

                                      {values.items && Boolean(values.items.filter(item => item.type === 'box').length === 1) ? (
                                        <Typography className={classes.quantity} variant='body2' color='textSecondary'>{values.items.filter(item => item.type === 'box').length} Låda</Typography>
                                      ) : values.items && Boolean(values.items.filter(item => item.type === 'box').length > 1) ? (<Typography className={classes.quantity} variant='body2' color='textSecondary'>{values.items.filter(item => item.type === 'box').length} Lådor</Typography>) : null}

                                      {values.items && Boolean(values.items.filter(item => item.type === 'individ').length === 1) ? (
                                        <Typography className={classes.quantity} variant='body2' color='textSecondary'>{values.items.filter(item => item.type === 'individ').length} Individ</Typography>
                                      ) : values.items && Boolean(values.items.filter(item => item.type === 'individ').length > 1) ? (<Typography className={classes.quantity} variant='body2' color='textSecondary'>{values.items.filter(item => item.type === 'individ').length} Individer</Typography>) : null}

                                      {values.items && Boolean(values.items.filter(item => item.type === 'order_number').length === 1) ? (
                                        <Typography className={classes.quantity} variant='body2' color='textSecondary'>{values.items.filter(item => item.type === 'order_number').length} Order</Typography>
                                      ) : values.items && Boolean(values.items.filter(item => item.type === 'order_number').length > 1) ? (<Typography className={classes.quantity} variant='body2' color='textSecondary'>{values.items.filter(item => item.type === 'order_number').length} Ordrar</Typography>) : null}
                                    </div>

                                    <StyledTabs
                                      value={value}
                                      onChange={tabChange}
                                      aria-label='tabs'
                                    >
                                      <StyledTab label='Individ' {...tabProps(0)} />
                                      <StyledTab label='Order' {...tabProps(1)} />
                                      <StyledTab label='Pall' {...tabProps(2)} />
                                      <StyledTab label='Låda' {...tabProps(3)} />
                                    </StyledTabs>
                                    <TabPanel value={value} index={0}>
                                      <Field
                                        name='item'
                                        label='EAN'
                                        variant='outlined'
                                        margin='normal'
                                        autoFocus
                                        type='text'
                                        fullWidth
                                        disabled={isSubmitting}
                                        onComplete={(!cooldown.current && scannerMode) ? data => getItems(data, 'serial_number', arrayHelpers, setFieldValue) : undefined}
                                        // onComplete={scannerMode ? data => getArticle(data, arrayHelpers, setFieldValue) : undefined}
                                        // ref={refContainer}
                                        // onBlur = {() => getArticle(values.item, arrayHelpers, setFieldValue)}
                                        InputProps={{
                                          inputProps: {
                                            ref: inputRef,
                                            onBlur: (!cooldown.current && scannerMode) ? () => getItems(values.item, 'serial_number', arrayHelpers, setFieldValue) : undefined
                                          },
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <IconButton
                                                onClick={() => setScannerMode(!scannerMode)}
                                              >
                                                {scannerMode ? (
                                                  <Barcode />
                                                ) : (
                                                  <KeyboardIcon />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          )

                                        }}
                                        // onBlur={() => getArticle(values.item, arrayHelpers, setFieldValue)}
                                        component={scannerMode ? BarcodeField : TextField}
                                      />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                      <Field
                                        name='item'
                                        label='Ordernummer'
                                        variant='outlined'
                                        margin='normal'
                                        autoFocus
                                        type='text'
                                        fullWidth
                                        disabled={isSubmitting}
                                        // ref={refContainer}
                                        // onBlur = {() => getArticle(values.item, arrayHelpers, setFieldValue)}
                                        InputProps={{
                                          inputProps: {
                                            ref: inputRef,
                                            // ref: input => input && !openChecklist.open && !openOldFirst.open && !openLocked.open && !testLoader && input.focus(),
                                            onBlur: !cooldown.current ? () => getItems(values.item, 'order_number', arrayHelpers, setFieldValue) : undefined
                                          },
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <KeyboardIcon />
                                            </InputAdornment>
                                          )

                                        }}
                                        // onBlur={() => getArticle(values.item, arrayHelpers, setFieldValue)}
                                        component={scannerMode ? BarcodeField : TextField}
                                      />
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                      <Field
                                        name='item'
                                        label='EAN'
                                        variant='outlined'
                                        margin='normal'
                                        autoFocus
                                        type='text'
                                        fullWidth
                                        disabled={isSubmitting}
                                        onComplete={(!cooldown.current && !scannerMode) ? data => getItems(data, 'pallet', arrayHelpers, setFieldValue) : undefined}
                                        // ref={refContainer}
                                        // onBlur = {() => getArticle(values.item, arrayHelpers, setFieldValue)}
                                        InputProps={{
                                          inputProps: {
                                            ref: inputRef,
                                            // ref: input => input && !openChecklist.open && !openOldFirst.open && !openLocked.open && input.focus(),
                                            onBlur: (!cooldown.current && !scannerMode) ? () => getItems(values.item, 'pallet', arrayHelpers, setFieldValue) : undefined
                                          },
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <IconButton
                                                onClick={() => setScannerMode(!scannerMode)}
                                              >
                                                {scannerMode ? (
                                                  <Barcode />
                                                ) : (
                                                  <KeyboardIcon />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          )

                                        }}
                                        // onBlur={() => getArticle(values.item, arrayHelpers, setFieldValue)}
                                        component={scannerMode ? BarcodeField : TextField}
                                      />
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                      <Field
                                        name='item'
                                        label='EAN'
                                        variant='outlined'
                                        margin='normal'
                                        autoFocus
                                        type='text'
                                        fullWidth
                                        disabled={isSubmitting}
                                        onComplete={(!cooldown.current && scannerMode) ? data => getItems(data, 'box', arrayHelpers, setFieldValue) : undefined}
                                        // ref={refContainer}
                                        // onBlur = {() => getArticle(values.item, arrayHelpers, setFieldValue)}
                                        InputProps={{
                                          inputProps: {
                                            ref: inputRef,
                                            // ref: input => input && !openChecklist.open && !openOldFirst.open && !openLocked.open && input.focus(),
                                            onBlur: (!cooldown.current && !scannerMode) ? () => getItems(values.item, 'box', arrayHelpers, setFieldValue) : undefined
                                          },
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <IconButton
                                                onClick={() => setScannerMode(!scannerMode)}
                                              >
                                                {scannerMode ? (
                                                  <Barcode />
                                                ) : (
                                                  <KeyboardIcon />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          )

                                        }}
                                        // onBlur={() => getArticle(values.item, arrayHelpers, setFieldValue)}
                                        component={scannerMode ? BarcodeField : TextField}
                                      />
                                    </TabPanel>
                                    <Button
                                      type='submit'
                                      variant='contained'
                                      aria-label='submit'
                                      color='primary'
                                      disabled={isSubmitting || !values.items || values.items.length < 1}
                                    >
                                      {isSubmitting ? (
                                        <CircularProgress size={24} />
                                      ) : (
                                        <React.Fragment>Slutför</React.Fragment>
                                      )}

                                    </Button>
                                    {/* <Button /> */}
                                    <Dialog
                                      open={openLocked.open}
                                      onClose={() => cancelSubmission({ resetForm })}
                                      fullWidth
                                      maxWidth={'md'}
                                      aria-labelledby='warning-title'
                                      aria-describedby='warning-description'
                                    >
                                      <DialogTitle
                                        id='warning-title'
                                        disableTypography={true}
                                        classes={{
                                          root: classes.warningRoot
                                        }}
                                      >
                                        <WarningIcon color='error' style={{ marginRight: 10 }} />
                                        <Typography variant='h6' component='h2'>{openLocked.label}</Typography>
                                      </DialogTitle>
                                      <DialogContent
                                        classes={{
                                          root: classes.dialogRoot
                                        }}
                                      >
                                        <DialogContentText
                                          id='warning-description'
                                        >
                                          <Table size='small'>
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>
                                                  Typ
                                                </TableCell>
                                                <TableCell>
                                                  Nummer
                                                </TableCell>
                                                <TableCell>
                                                  Artikel
                                                </TableCell>
                                                <TableCell>
                                                  Plats
                                                </TableCell>
                                                <TableCell>
                                                  Status
                                                </TableCell>
                                                <TableCell align={'center'}>
                                                  Visa
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {openLocked.items.map(item => (
                                                <TableRow key={item.id} variant='subtitle1'>
                                                  <TableCell>
                                                    {item.type === 'serial_number'
                                                      ? 'Individ'
                                                      : item.type === 'box'
                                                        ? 'Låda'
                                                        : item.type === 'pallet'
                                                          ? 'Pall'
                                                          : item.type === 'order_number'
                                                            ? 'Order'
                                                            : 'Objekt'
                                                    }
                                                  </TableCell>
                                                  <TableCell>
                                                    {item.value}
                                                  </TableCell>
                                                  <TableCell>
                                                    {item.labels.article}
                                                  </TableCell>
                                                  <TableCell>
                                                    {item.params.storage_location_id
                                                      ? item.labels.storage_location
                                                      : item.labels.storage
                                                    }
                                                  </TableCell>
                                                  <TableCell>
                                                    {item.labels.status}
                                                  </TableCell>
                                                  <TableCell align={'center'}>
                                                    <Tooltip title='Visa' aria-label='read' >
                                                      <IconButton
                                                        component={Link}
                                                        to={`${match.url.split('/move').join('')}?${buildFilter(item.params)}`}
                                                        target={'_blank'}
                                                        size={'small'}
                                                      >
                                                        <ViewIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </DialogContentText>
                                      </DialogContent>
                                      <DialogActions
                                        classes={{
                                          root: classes.warningActions
                                        }}
                                      >
                                        <Field
                                          name={'ignore_lock'}
                                          type='checkbox'
                                          value={values['ignore_lock']}
                                          checked={values['ignore_lock']}
                                          handleToggle={event => { setFieldValue('ignore_lock', event.target.checked) }}
                                          label='Ignorera lås'
                                          component={CheckboxField}
                                          color='primary'
                                        />
                                        <Button
                                          onClick={() => cancelSubmission({ resetForm })}
                                          style={{ margin: 0 }}
                                          color='secondary'
                                        >
                                          Avbryt lagerflytt
                                        </Button>
                                        <Button
                                          onClick={() => submitForm()}
                                          style={{ margin: 0 }}
                                          color='primary'
                                          disabled={!values['ignore_lock'] || isSubmitting}
                                        >
                                          {isSubmitting ? (
                                            <CircularProgress size={24} />
                                          ) : (
                                            <React.Fragment>Gå vidare</React.Fragment>
                                          )}

                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                    <Dialog
                                      open={openChecklist.open}
                                      onClose={handleCloseChecklist}
                                      fullWidth
                                      maxWidth={'sm'}
                                    // aria-labelledby='barcode-title'
                                    // aria-describedby='barcode-description'
                                    >
                                      <DialogContent
                                        classes={{
                                          root: classes.dialogRoot
                                        }}
                                      >
                                        <Field
                                          name={'update_status'}
                                          value={values.update_status}
                                          onChange={event => { setFieldValue('update_status', event.target.value) }}
                                          label={'Status'}
                                          component={RadioGroupField}
                                          selects={[{ value: 'ok', label: 'Mottagning ok' }, { value: 'not_ok', label: 'Mottagning EJ ok' }]}
                                        />
                                        {values.update_status === 'not_ok' && (
                                          <React.Fragment>
                                            <Field
                                              name={'description'}
                                              variant='outlined'
                                              margin='normal'
                                              multiline
                                              type='text'
                                              component={TextField}
                                              label='Felbeskrivning *'
                                              placeholder='Skriv en felbeskrivning'
                                              rows={4}
                                            />
                                            <ImageDrop
                                              setFieldValue={setFieldValue}
                                              value={values.image_url}
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </React.Fragment>
                                        )}
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          onClick={() => submitForm()}
                                          color='primary'
                                          disabled={isSubmitting || !values.update_status}
                                        >
                                          {isSubmitting ? (
                                            <CircularProgress size={24} />
                                          ) : (
                                            <React.Fragment>Gå vidare</React.Fragment>
                                          )}

                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                    <Dialog
                                      open={openOldFirst.open}
                                      onClose={() => cancelSubmission({ resetForm })}
                                      fullWidth
                                      maxWidth={'md'}
                                    // aria-labelledby='barcode-title'
                                    // aria-describedby='barcode-description'
                                    >
                                      <DialogContent
                                        classes={{
                                          root: classes.dialogRoot
                                        }}
                                      >
                                        <Table size='small'>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>
                                                Typ
                                              </TableCell>
                                              <TableCell>
                                                Nummer
                                              </TableCell>
                                              <TableCell>
                                                Artikel
                                              </TableCell>
                                              <TableCell>
                                                Plats
                                              </TableCell>
                                              <TableCell>
                                                Datum
                                              </TableCell>
                                              <TableCell>
                                                Antal
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {openOldFirst.change.map((change, index) => (
                                              <>
                                                <TableRow key={index}>
                                                  <TableCell>
                                                    {change.original.type === 'serial_number'
                                                      ? 'Individ'
                                                      : change.original.type === 'box'
                                                        ? 'Låda'
                                                        : change.original.type === 'pallet'
                                                          ? 'Pall'
                                                          : change.original.type === 'order_number'
                                                            ? 'Order'
                                                            : 'Objekt'
                                                    }
                                                  </TableCell>
                                                  <TableCell>
                                                    {change.original.value}
                                                  </TableCell>
                                                  <TableCell>
                                                    {change.original.labels.article}
                                                  </TableCell>
                                                  <TableCell>
                                                    {change.original.params.storage_location_id
                                                      ? change.original.labels.storage_location
                                                      : change.original.labels.storage
                                                    }
                                                  </TableCell>
                                                  <TableCell>
                                                    {change.original.params.production_date}
                                                  </TableCell>
                                                  <TableCell align={'center'}>
                                                    {change.total}
                                                  </TableCell>
                                                </TableRow>
                                                {change.substitutes.map((sub, subindex) => (
                                                  <TableRow key={`${index}.${subindex}`}>
                                                    <TableCell align={'center'}>
                                                      <SwapHorizIcon />
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                      {sub.params[sub.type]}
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                      {sub.labels.article}
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                      {sub.params.storage_location_id
                                                        ? sub.labels.storage_location
                                                        : sub.labels.storage
                                                      }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                      {sub.params.production_date}
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                      {/* {sub.total} */}
                                                      <Tooltip title='Visa' aria-label='read' >
                                                        <IconButton
                                                          component={Link}
                                                          to={`${match.url.split('/move').join('')}?${buildFilter(sub.params)}`}
                                                          target={'_blank'}
                                                          size={'small'}
                                                        >
                                                          <ViewIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                    </TableCell>
                                                  </TableRow>
                                                ))}
                                              </>
                                            ))}
                                          </TableBody>
                                        </Table>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '1em' }}>
                                          <Field
                                            name={'ignore_old_first'}
                                            type='checkbox'
                                            value={values['ignore_old_first']}
                                            checked={values['ignore_old_first']}
                                            handleToggle={event => { setFieldValue('ignore_old_first', event.target.checked) }}
                                            label='Ignorera varning'
                                            component={CheckboxField}
                                            color='primary'
                                          />
                                          <Typography variant='subtitle1'>
                                            Det finns äldre varianter av de valda individerna.
                                          </Typography>
                                        </div>
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          onClick={() => cancelSubmission({ resetForm })}
                                          color='secondary'
                                        >
                                          Avbryt lagerflytt
                                        </Button>
                                        <Button
                                          onClick={() => submitForm()}
                                          color='primary'
                                          disabled={!values['ignore_old_first'] || isSubmitting}
                                        >
                                          {isSubmitting ? (
                                            <CircularProgress size={24} />
                                          ) : (
                                            <React.Fragment>Gå vidare</React.Fragment>
                                          )}
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                    {/* <Fab
                                      variant='extended'
                                      aria-label='submit'
                                      color='primary'
                                      type='submit'
                                      disabled={isSubmitting || !values.items || values.items.length < 1}
                                      className={classes.fab}
                                    >
                                      <SaveIcon className={classes.fabIcon} />
                                      {isSubmitting && <CircularProgress style={{position: 'absolute', left: 5, color: '#fff'}} size={45} />}
                                      Spara
                                    </Fab> */}
                                  </React.Fragment>
                                )}
                              />
                            </Form>
                          )}
                        </Formik>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepLabel
                        classes={{
                          active: clsx(classes.activeLabel, classes.flexLabel)
                        }}
                        StepIconComponent={QontoStepIcon}
                      >
                        Kvittens
                        {activeStep === 3 && (
                          <div style={{ display: 'flex' }}>
                            <Button
                              color='primary'
                              onClick={() => createEan('pallet')}
                              disabled={loadEan !== ''}
                            >
                              {loadEan === 'pallet' && <CircularProgress style={{ position: 'absolute' }} size={24} />}
                              Skapa pall
                            </Button>
                            <Button
                              color='primary'
                              onClick={() => createEan('box')}
                              disabled={loadEan !== ''}
                            >
                              {loadEan === 'box' && <CircularProgress style={{ position: 'absolute' }} size={24} />}
                              Skapa låda
                            </Button>
                            <Button
                              variant='contained'
                              color='primary'
                              style={{ marginLeft: 10 }}
                              onClick={(() => setActiveStep(0))}
                            >
                              Skapa ny lagerflytt
                            </Button>
                            <Dialog
                              open={Boolean(barcode.code)}
                              onClose={() => setBarcode({ code: '' })}
                            >
                              <DialogContent>
                                <BarcodeGenerator barcode={barcode} ref={componentRef} />
                              </DialogContent>
                              <DialogActions>
                                <ReactToPrint
                                  trigger={() => (
                                    <Button
                                      color='primary'
                                      autoFocus
                                    >
                                      Skriv ut
                                    </Button>
                                  )}
                                  content={() => componentRef.current}
                                />
                                <Button
                                  onClick={() => setBarcode({ code: '' })}
                                  color='secondary'
                                >
                                  Stäng
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        )}
                      </StepLabel>
                      <StepContent classes={{
                        root: classes.stepContent
                      }}>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                Artikel
                              </TableCell>
                              <TableCell>
                                Serienummer
                              </TableCell>
                              <TableCell>
                                Lager
                              </TableCell>
                              <TableCell>
                                Lagerplats
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!loading ? receipt.map(item => (
                              <TableRow key={item.id}>
                                <TableCell>
                                  {item.article}
                                </TableCell>
                                <TableCell>
                                  {item.serial_number}
                                </TableCell>
                                <TableCell>
                                  {item.storage}
                                </TableCell>
                                <TableCell>
                                  {item.storage_location}
                                </TableCell>
                              </TableRow>
                            )) : receipt.map(item => (
                              <TableRow key={item.id}>
                                <TableCell>
                                  <Skeleton variant='rect' className={classes.skeleton} />
                                </TableCell>
                                <TableCell>
                                  <Skeleton variant='rect' className={classes.skeleton} />
                                </TableCell>
                                <TableCell>
                                  <Skeleton variant='rect' className={classes.skeleton} />
                                </TableCell>
                                <TableCell>
                                  <Skeleton variant='rect' className={classes.skeleton} />
                                </TableCell>
                              </TableRow>
                            ))}

                            <TablePagination
                              rowsPerPageOptions={[20, 50, 200, 500]}
                              labelRowsPerPage='Rader per sida'
                              count={total}
                              labelDisplayedRows={({ from, to, count }) => `${from}-${to} av ${count}`}
                              rowsPerPage={perPage}
                              page={page ? page - 1 : 0}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableBody>
                        </Table>
                      </StepContent>
                    </Step>
                  </Stepper>
                </div>
              </Paper>
            )}
          />
          <Route
            exact
            path={`${match.url}/updates`}
            render={props => <AllUpdates {...props} />}
          />
          <Route
            exact
            path={`${match.url}/updates/:id`}
            render={props => <ViewUpdates {...props} />}
          />
        </Switch>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <LatestUpdates refresh={refresh} match={match} />
      </Grid>
    </Grid >
  )
}
