import React from 'react'
import CountUp from 'react-countup'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { CardContent, CardHeader, Avatar } from '@material-ui/core'
import { primary } from './colors'


export default ({ value }) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      paddingBottom: 24   
    }}
  >
    <Card>
      <CardHeader
        avatar={
          <Avatar
            style={{ backgroundColor: primary}}
          >
            <AttachMoneyIcon />
          </Avatar>
        }
        title={value ? (
          <Typography variant='h5'>
            <CountUp
              end={value}
              separator=' '
              suffix=' kr'
              duration='1'
            />
          </Typography>
        ) : (
          <Skeleton width={115} height={18}/>
        )}
      />
    </Card>
  </div>
)