import React, { useEffect, useState } from 'react'
import { dataService } from './services'

import Table from './components/Table'

const cols = [
  {
    id: 1,
    key: 'article_number',
    label: 'Artikelnummer',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'text'
  },
  {
    id: 2,
    key: 'label',
    label: 'Titel',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'text',
    filter: {
      key: 'label',
      type: 'select',
    },
  },
  {
    id: 3,
    key: 'selectable',
    label: 'Synlig',
    default: true,
    visible: true,
    sortable: false,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'boolean'
  }
]

export default ({
  match,
  history,
  location
}) => {
  const [enhanced, setEnhanced] = useState(cols)

  useEffect(() => {
    dataService.getData({ model: 'articles', params: `?pagination=off&select[0]=id&select[1]=label`})
      .then(({ result }) => {
        const options = result.map(item => ({ value: item.id, label: item.label }))
        enhanced.find(col => col.id === 2).options = options

        setEnhanced(enhanced)
      })
  }, [])

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Artikeltyper'
      singular='Artikeltyp'
      model='articles'
      relatedOrders='id'
    />
  )
}
