import { BehaviorSubject } from 'rxjs'

import { handleResponse } from '../helpers'
import { post, get } from '../utils'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))

const login = values => (
  post('/authenticate', values)
    .then(handleResponse)
    .then(user => {
      if (user) {
        localStorage.setItem('currentUser', JSON.stringify(user))
        currentUserSubject.next(user)
        return user
      }
    })
    .then(user => get(`/inboxes?find[0][key]=role_id&find[0][value]=[${user.role_id}]`)
      .then(handleResponse)
      .then(inboxes => {
        user.inboxes = inboxes && inboxes.result || []
        localStorage.setItem('currentUser', JSON.stringify(user))
        currentUserSubject.next(user)
        return user
      })
    )
    .then(user => get('/me/permissions')
      .then(handleResponse)
      .then(permissions => {
        user.permissions = permissions.result
        localStorage.setItem('currentUser', JSON.stringify(user))
        currentUserSubject.next(user)
        return user
      })
    )
)

const reset = values => (
  post('/auth/reset', values)
    .then(handleResponse)
    .then(data => data)
)

const forgot = values => (
  post('/auth/forgot', values)
    .then(handleResponse)
    .then(data => data)
)

const logout = () => {
  localStorage.removeItem('currentUser')
  currentUserSubject.next(null)
}

const authenticationService = {
  login,
  reset,
  forgot,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value }
}

export default authenticationService
