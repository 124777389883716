import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Link } from 'react-router-dom'
import classNames from 'clsx'
import { CSSTransition } from 'react-transition-group'
import ActionCable from 'actioncable'

import { fade, makeStyles } from '@material-ui/core/styles'

import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'

import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'
import OrderIcon from '@material-ui/icons/LocalShipping'
import DashboardIcon from '@material-ui/icons/Web'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ItemsIcon from '@material-ui/icons/Assignment'
import UsersIcon from '@material-ui/icons/Group'
import RolesIcon from '@material-ui/icons/VerifiedUser'
import PermissionsIcon from '@material-ui/icons/Lock'
import MonitorsIcon from '@material-ui/icons/Tune'
import OrganizationsIcon from '@material-ui/icons/AccountTree'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import SettingsIcon from '@material-ui/icons/Settings'
import { authenticationService, dataService } from './services'

import Order from './Order'
import Dashboard from './Dashboard'
import Inbox from './Inbox'
import Inboxes from './Inboxes'
import Items from './Items'
import Users from './Users'
import Roles from './Roles'
import Permissions from './Permissions'
import Storages from './Storages'
import StorageLocations from './StorageLocations'
import StorageMonitors from './StorageMonitors'
import Organizations from './Organizations'
import Statuses from './Statuses'
import Articles from './Articles'
import Values from './Values'

import Logo from './images/logo'
import { primary, background, error } from './components/colors'
import OrganizationSelect from './components/OrganizationSelect'

import StorageLocationsIcon from './icons/StorageLocationsIcon'
import StoragesIcon from './icons/StoragesIcon'
import StatusesIcon from './icons/StatusesIcon'
import ArticlesIcon from './icons/ArticlesIcon'

import checkPermissions from './helpers/checkPermissions'
import checkSubscription from './helpers/checkSubscription'
import Notifications from './Notifications'
import ItemValues from './ItemValues'

import faviconDot from './images/favicon-dot.ico'
import favicon from './images/favicon.ico'
import BulkIcon from './icons/BulkIcon'
import BulkItems from './BulkItems'
import Footer from './Footer'
import System from './System'
const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24,
    paddingLeft: 20
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 12px',
    paddingLeft: 10
  },
  title: {
    // marginLeft: 12
    marginLeft: 30
  },
  toolbarLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 12px',
    paddingLeft: 0
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    // backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    // color: theme.palette.common.black,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
    borderRight: 'none',
    // backgroundColor: theme.palette.secondary.main,
    // color: theme.palette.secondary.contrastText,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  list: {
    color: theme.palette.secondary.main
    // color: theme.palette.secondary.contrastText
  },
  icon: {
    color: 'inherit'
  },
  fixedHeight: {
    height: 240,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  selected: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)'
  },
  test: {
    transition: 'all .3 cubic-bezier(.29,1.09,.74,1.3)',
    transform: 'scale(1)',
    '&.testss-enter': {
      transform: 'scale(0.1) translateY(10px)'
    },
    '&testss-enter-done': {
      transform: 'scale(1) translateY(0)'
    },
    '&.testss-exit': {
      transform: 'scale(1.1) translateY(-10px)'
    },
    '&testss-exit-done': {
      transform: 'scale(1) translateY(0)'
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.1),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    },
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)'
    }
  },
  iconPaper: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    display: 'flex',
    padding: 3,
    transition: 'all .5s ease-in-out'
  },
  selectedIconPaper: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 0 14px 0 rgba(53,64,82,.17)',
    color: theme.palette.common.white
  },
  selectedIcon: {
    // color: primary,
    animation: 'test 10s infinite'
  },
  '@keyframes test': {
    from: { color: primary },
    to: { color: error }
  },
  textAnimation: {
    transition: 'all .2s cubic-bezier(.29,1.09,.74,1.3)',
    opacity: 0,
    '&.text-enter': {
      transform: 'translateX(-10px)'
    },
    '&.text-enter-done': {
      transform: 'translateX(0)',
      opacity: 1,
    }
  },
  selectedBadge: {
    backgroundColor: theme.palette.error.main
  }
}))

export default ({ match, location, history }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [newNotifications, setNewNotifications] = useState(0)
  const [unhandledValues, setUnhandledValues] = useState(0)
  const [notifications, setNotifications] = useState([])
  const [refresh, setRefresh] = useState(Date.now())
  // useEffect(() => {
  //   const host = process.env.REACT_APP_CABLE
  //   const socket = ActionCable.createConsumer(`${host}/cable`)
  //   socket.subscriptions.create('NotificationChannel', {
  //     received: ticket => {
  //       getNotifications()
  //     }
  //   })
  // })

  // useEffect(() => {
  //   getNotifications()
  // }, [])
  // const checkSubscription = ({ model }) => (
  //   dataService.getData({ model: 'inboxes', params: `?find[0][key]=notification_types&find[0][value]=${model}`})
  //     .then(
  //       data => {
  //         const { result, total } = data
  //         if (total > 0) {
  //           const roles = result.map(inbox => inbox.role_id)
  //           return checkRole(roles)
  //         }
  //         return false
  //       }
  //     )
  // )

  const getItemValues = () => (
    dataService.getData({ model: 'item_values', params: '?filter[0][key]=approved&filter[0][value]=0' })
      .then(data => {
        const { total } = data
        setUnhandledValues(total)
      })
  )

  const getNotifications = () => (
    dataService.getData({ model: 'notifications', params: '?filter[0][key]=status&filter[0][value]=0' })
      .then(data => {
        const { total } = data
        setRefresh(Date.now())
        setNewNotifications(total)
        //const {result} = data
        // const boxes = []
        // setNewNotifications(result.filter(res => res.status === 'new_notification').length)
        // result.forEach(notification => {
        //   const collection = boxes.find(m => m.type === notification.notification_type)
        //   if (!collection) {
        //     const box = {
        //       type: notification.notification_type,
        //       notifications: [notification]
        //     }
        //     boxes.push(box)
        //   } else {
        //     collection.notifications.push(notification)
        //   }
        // })
        // setNotifications(boxes)
      })
  )
  const routes = [
    {
      id: 0,
      path: '',
      icon: <OrderIcon />,
      text: 'Beställning',
      component: Order,
      visible: checkPermissions({ model: 'orders', type: 'write' }) && !checkPermissions({ model: 'items', type: 'read' })
    },
    {
      id: 1,
      path: '',
      icon: <DashboardIcon />,
      text: 'Skrivbord',
      exact: true,
      component: Dashboard,
      visible: checkPermissions({ model: 'items', type: 'read' })
    },
    // {
    //   id: 2,
    //   path: '/import',
    //   icon: <ImportIcon />,
    //   text: 'Inleverans',
    //   component: Import
    // },
    {
      id: 2,
      path: '/inbox',
      icon: <NotificationsIcon />,
      text: 'Meddelanden',
      properties: true,
      component: Inbox,
      visible: checkSubscription({ model: 'Ordrar' })
    },
    {
      id: 3,
      path: '/items',
      icon: <ItemsIcon />,
      text: 'Individer',
      component: Items,
      visible: checkPermissions({ model: 'items', type: 'write' })
    },
    {
      id: 4,
      path: '/bulk_items',
      icon: <BulkIcon />,
      text: 'Plockvaror',
      component: BulkItems,
      visible: checkPermissions({ model: 'bulk_items', type: 'write' })
    },
    {
      id: 5,
      path: '/articles',
      icon: <ArticlesIcon />,
      text: 'Artikeltyper',
      component: Articles,
      visible: checkPermissions({ model: 'articles', type: 'write' })
    },
    {
      id: 6,
      path: '/values',
      icon: <AttachMoneyIcon />,
      testing: true,
      text: 'Lagervärde',
      component: Values,
      visible: checkPermissions({ model: 'article_values', type: 'write' }) || checkPermissions({ model: 'item_values', type: 'write' })
    },
    {
      id: 7,
      path: '/storages',
      icon: <StoragesIcon />,
      text: 'Lager',
      component: Storages,
      visible: checkPermissions({ model: 'storages', type: 'write' })
    },
    {
      id: 8,
      path: '/storage-locations',
      icon: <StorageLocationsIcon />,
      text: 'Lagerplatser',
      component: StorageLocations,
      visible: checkPermissions({ model: 'storage_locations', type: 'write' })
    },
    {
      id: 9,
      path: '/statuses',
      icon: <StatusesIcon />,
      text: 'Statusar',
      component: Statuses,
      visible: checkPermissions({ model: 'statuses', type: 'write' })
    },
    {
      id: 10,
      path: '/storage_monitors',
      icon: <MonitorsIcon />,
      text: 'Gränsvärden',
      component: StorageMonitors,
      visible: checkPermissions({ model: 'storage_monitors', type: 'write' })
    },
    {
      id: 11,
      path: '/users',
      icon: <UsersIcon />,
      text: 'Användare',
      component: Users,
      visible: checkPermissions({ model: 'users', type: 'write' })
    },
    {
      id: 12,
      path: '/roles',
      icon: <RolesIcon />,
      text: 'Roller',
      component: Roles,
      visible: checkPermissions({ model: 'roles', type: 'write' })
    },
    {
      id: 13,
      path: '/permissions',
      icon: <PermissionsIcon />,
      text: 'Behörigheter',
      component: Permissions,
      visible: checkPermissions({ model: 'permissions', type: 'write' })
    },
    {
      id: 14,
      path: '/organisations',
      icon: <OrganizationsIcon />,
      text: 'Organisationer',
      component: Organizations,
      visible: checkPermissions({ model: 'organizations', type: 'write' })
    },
    {
      id: 15,
      path: '/inboxes',
      icon: <MarkunreadMailboxIcon />,
      text: 'Brevlådor',
      component: Inboxes,
      visible: checkPermissions({ model: 'inboxes', type: 'write' })
    },
    {
      id: 16,
      path: '/system',
      icon: <SettingsIcon />,
      text: 'System',
      component: System,
      visible: checkPermissions({ model: 'scanner_filters', type: 'write' }) || checkPermissions({ model: 'wom_logs', type: 'write' })
    }
  ]

  const handleDrawerOpen = () => {
    // console.log(routes)
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const logout = () => {
    authenticationService.logout()
    history.push('/')
  }
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{Boolean(newNotifications) ? `(${newNotifications}) OneAsset` : 'OneAsset'}</title>
        <link rel="icon" href={newNotifications ? faviconDot : favicon} />
      </Helmet>
      <AppBar position='absolute' elevation={0} className={classNames(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          {routes.filter(route => route.visible).length > 1 ? (
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open-drawer'
              onClick={handleDrawerOpen}
              className={classNames(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div className={classes.toolbarLogo}>
              <Logo
                type='plain'
                fill={primary}
                stroke={background}
                strokeWidth={1}
                size={26}
              />
              <Typography variant='h6' className={classes.title}>
                OneAsset
              </Typography>
            </div>
          )}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Divider orientation='vertical' />
            <OrganizationSelect
              match={match}
              history={history}
              location={location}
            />
            <Divider orientation='vertical' />
            <Tooltip title='Logga ut' aria-label='logout'>
              <IconButton aria-label='logout' color='inherit' onClick={logout}>
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      {routes.filter(route => route.visible).length > 1 && (
        <Drawer
          variant='permanent'
          classes={{
            paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <div className={classes.logo}>
              <Logo
                type='plain'
                fill={primary}
                stroke={background}
                strokeWidth={1}
                size={26}
              />
              <Typography variant='h6' className={classes.title}>
                OneAsset
              </Typography>
            </div>
            <IconButton classes={{ root: classes.icon }} onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          {/* <Divider />*/}
          <List classes={{
            root: classes.list
          }}>
            {routes.map(route => route.visible && (
              <Link
                key={route.id}
                to={`${match.url}${route.path}`}
                className={classes.link}
              >
                <ListItem
                  button
                  disableRipple
                  className={classNames(
                    classes.button,
                    `${match.url}${route.path}` === location.pathname && classes.selected,
                    `/${location.pathname.split('/admin/').pop().split('/').shift()}` === route.path && classes.selected
                  )}
                >
                  <ListItemIcon classes={{ root: classes.icon }}>
                    <CSSTransition
                      in={`${match.url}${route.path}` === location.pathname || `/${location.pathname.split('/admin/').pop().split('/').shift()}` === route.path}
                      className={classNames(
                        classes.test,
                        classes.iconPaper,
                        `${match.url}${route.path}` === location.pathname && classes.selectedIconPaper,
                        `/${location.pathname.split('/admin/').pop().split('/').shift()}` === route.path && classes.selectedIconPaper
                      )}
                      timeout={{ enter: 300, exit: 300 }}
                      classNames='testss'
                    >
                      <Paper className='testss'>
                        {Boolean(route.properties) ? (
                          <Notifications
                            getNotifications={getNotifications}
                            route={route}
                            location={location}
                            withBadge={newNotifications > 0}
                          />
                        ) : (
                          Boolean(route.testing) ? (
                            <ItemValues
                              getItemValues={getItemValues}
                              route={route}
                              location={location}
                              withBadge={unhandledValues > 0}
                            />
                          ) : (
                            route.icon
                          )
                        )
                        }
                      </Paper>
                    </CSSTransition>
                  </ListItemIcon>
                  <CSSTransition
                    in={open}
                    className={classes.textAnimation}
                    timeout={{ enter: 200, exit: 200 }}
                    classNames='text'
                  >
                    <ListItemText
                      primary={Boolean(route.properties) ? <span style={{ display: 'flex' }}>{route.text} <Chip style={{ marginLeft: 'auto', opacity: newNotifications > 0 ? 1 : 0, transition: 'opacity .5s ease-in-out' }} size="small" label={newNotifications} /></span> : (Boolean(route.testing) ? <span style={{ display: 'flex' }}>{route.text} <Chip style={{ marginLeft: 'auto', opacity: unhandledValues > 0 ? 1 : 0, transition: 'opacity .5s ease-in-out' }} size="small" label={unhandledValues} /></span> : route.text)}
                      className='text'
                      style={{
                        transitionDelay: `${(route.id - 1) * 30}ms`
                      }}
                    />
                  </CSSTransition>
                </ListItem>
              </Link>
            ))}
          </List>
          {open && (
            <Footer />
          )}
        </Drawer>
      )}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={2}>
            {routes.map(route => route.visible && (
              <React.Fragment>
                {Boolean(route.properties) ? (
                  <Route key={route.id} exact={route.exact} path={`${match.url}${route.path}`} render={props => <route.component {...props} refresh={refresh} newNotifications={newNotifications} boxes={notifications} />} />
                ) : (
                  Boolean(route.testing) ? (
                    <Route key={route.id} exact={route.exact} path={`${match.url}${route.path}`} render={props => <route.component {...props} unhandledValues={unhandledValues} />} />
                  ) : (
                    <Route key={route.id} exact={route.exact} path={`${match.url}${route.path}`} component={route.component} />
                  )
                )
                }
              </React.Fragment>
            ))}
            {/* <Route component={NoMatch} /> */}
          </Grid>
        </Container>
      </main>
    </div>
  )
}
