import React, {useEffect} from 'react'
import ActionCable from 'actioncable'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'

const useStyles = makeStyles(theme => ({
  selectedBadge: {
    backgroundColor: theme.palette.error.main
  }
}))

export default ({
  getItemValues,
  location,
  route,
  withBadge
}) => {
  const classes = useStyles()
  useEffect(() => {
    const host = process.env.REACT_APP_CABLE
    const socket = ActionCable.createConsumer(`${host}/cable`)
    socket.subscriptions.create('ItemValuesChannel', {
      received: ticket => {
        getItemValues()
      }
    })
  }, [])
  
  useEffect(() => {
    getItemValues()
  }, [])

  return (
    <React.Fragment>
      {withBadge ? (
        <Badge color='primary' variant='dot' classes={{
          colorPrimary: classNames(`/${location.pathname.split('/admin/').pop().split('/').shift()}` === route.path && classes.selectedBadge)
        }}>
          {route.icon}
        </Badge>
      ) : (
        route.icon
      )}
    </React.Fragment>
  )
}