import React, { useState, useEffect } from 'react';
import { Form, Formik, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';

import { Route, Link, Switch } from 'react-router-dom';
import { format, formatDistanceToNow } from 'date-fns';
import { sv } from 'date-fns/locale';
import classNames from 'clsx';

import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/PlaylistAdd';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import TablePagination from '@material-ui/core/TablePagination';
import Chip from '@material-ui/core/Chip';
import ListSubheader from '@material-ui/core/ListSubheader';

import InboxIcon from '@material-ui/icons/Inbox';
import SearchIcon from '@material-ui/icons/Search';

import { dataService } from './services';
import TextField from './components/fields/TextField';
import ReactSelect from './components/fields/ReactSelect';
import Button from './components/Button';

import ArchiveIcon from './icons/ArchiveIcon';
import CheckboxField from './components/fields/CheckboxField';
import { primary, secondary } from './components/colors';

const drawerWidth = 240;

const ArchivedOrder = ({ match, history }) => {
  const classes = useStyles();
  const [current, setCurrent] = useState({});
  useEffect(() => {
    dataService
      .getData({ model: `orders/${match.params.id}`, params: '' })
      .then((order) => {
        setCurrent(order);
      });
  }, [match.params.id]);

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Link onClick={() => history.goBack()}>
          <IconButton>
            <ChevronLeftIcon />
          </IconButton>
        </Link>
      </Toolbar>
      <Divider />
      {current.id && (
        <React.Fragment>
          <List>
            <ListItem className={classes.root}>
              <ListItemText
                primary={current.order_number}
                secondary={
                  <React.Fragment>
                    <Typography variant="body2">
                      {current.status}
                    </Typography>
                    {current.updated_at &&
                      format(
                        new Date(current.updated_at),
                        'd LLLL yyyy, HH:mm:ss',
                        { locale: sv }
                      )}
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
          </List>
          <Divider />
          <Formik initialValues={current} enableReinitialize>
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form className={classes.form}>
                <Field
                  variant="filled"
                  margin="normal"
                  type="text"
                  name="order_number"
                  disabled
                  fullWidth
                  component={TextField}
                  label="Ordernummer"
                />
                <FieldArray
                  name="order_rows"
                  render={(arrayHelpers) => (
                    <React.Fragment>
                      {values.order_rows &&
                        values.order_rows.map((row, index) => (
                          <div className={classes.orderRow}>
                            <Field
                              style={{ flex: 2, paddingRight: 24 }}
                              name={`order_rows[${index}][article]`}
                              label={'Artikel'}
                              disabled
                              variant="filled"
                              margin="normal"
                              type="text"
                              fullWidth
                              component={TextField}
                            />
                            <Field
                              style={{ flex: 1 }}
                              variant="filled"
                              margin="normal"
                              fullWidth
                              type="number"
                              label="Antal"
                              name={`order_rows[${index}][qty]`}
                              disabled
                              component={TextField}
                            />
                          </div>
                        ))}
                    </React.Fragment>
                  )}
                ></FieldArray>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const HandledOrder = ({ match, history }) => {
  const classes = useStyles();
  const [current, setCurrent] = useState({});
  useEffect(() => {
    dataService
      .getData({ model: `orders/${match.params.id}`, params: '' })
      .then((order) => {
        setCurrent(order);
      });
  }, [match.params.id]);

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Link onClick={() => history.goBack()}>
          <IconButton>
            <ChevronLeftIcon />
          </IconButton>
        </Link>
      </Toolbar>
      <Divider />
      {current.id && (
        <React.Fragment>
          <List>
            <ListItem className={classes.root}>
              <ListItemText
                primary={current.order_number}
                secondary={
                  <React.Fragment>
                    <Typography variant="body2">
                      {current.status}
                    </Typography>
                    {current.updated_at &&
                      format(
                        new Date(current.updated_at),
                        'd LLLL yyyy, HH:mm:ss',
                        { locale: sv }
                      )}
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
          </List>
          <Divider />
          <Formik initialValues={current} enableReinitialize>
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form className={classes.form}>
                <Field
                  variant="filled"
                  margin="normal"
                  type="text"
                  name="order_number"
                  disabled
                  fullWidth
                  component={TextField}
                  label="Ordernummer"
                />
                <FieldArray
                  name="order_rows"
                  render={(arrayHelpers) => (
                    <React.Fragment>
                      {values.order_rows &&
                        values.order_rows.map((row, index) => (
                          <div className={classes.orderRow}>
                            {row.bulk_item ? (
                              <Field
                                style={{ flex: 2, paddingRight: 24 }}
                                name={`order_rows[${index}][bulk_item]`}
                                label={'Plockvara'}
                                disabled
                                variant="filled"
                                margin="normal"
                                type="text"
                                fullWidth
                                component={TextField}
                              />
                            ) : (
                              <Field
                                style={{ flex: 2, paddingRight: 24 }}
                                name={`order_rows[${index}][article]`}
                                label={'Artikel'}
                                disabled
                                variant="filled"
                                margin="normal"
                                type="text"
                                fullWidth
                                component={TextField}
                              />
                            )}
                            <Field
                              style={{ flex: 1 }}
                              variant="filled"
                              margin="normal"
                              fullWidth
                              type="number"
                              label="Antal"
                              name={`order_rows[${index}][qty]`}
                              disabled
                              component={TextField}
                            />
                          </div>
                        ))}
                    </React.Fragment>
                  )}
                ></FieldArray>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Order = ({ match, history }) => {
  const classes = useStyles();
  const [current, setCurrent] = useState({});
  const [options, setOptions] = useState([]);
  const [bulkItems, setBulkItems] = useState([]);
  const [declined, setDeclined] = useState(false);

  useEffect(() => {
    dataService
      .getData({ model: `orders/${match.params.id}`, params: '' })
      .then((order) => {
        setCurrent(order);
        dataService
          .getData({
            model: 'articles',
            params:
              '?filter[0][key]=selectable&filter[0][value]=1&pagination=off',
          })
          .then((data) => {
            const { result } = data;
            const articles = [];
            result.forEach((article) => {
              articles.push({
                value: article.id,
                label: article.label,
              });
            });
            setOptions(articles);
          });
        dataService
          .getData({ model: 'bulk_items', params: '?pagination=off' })
          .then((data) => {
            const { result } = data;
            const items = result.map((item) => ({
              value: item.id,
              label: item.label,
            }));
            setBulkItems(items);
          });
      });
  }, [match.params.id]);

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Link onClick={() => history.goBack()}>
          <IconButton>
            <ChevronLeftIcon />
          </IconButton>
        </Link>
      </Toolbar>
      <Divider />
      {current.id && (
        <React.Fragment>
          {current.external_order ? (
            <Formik
              initialValues={current}
              enableReinitialize
              validationSchema={
                !declined &&
                Yup.object().shape({
                  order_number: Yup.string()
                    .nullable()
                    .required('Du måste ange ett ordernummer'),
                })
              }
              onSubmit={(values, { setSubmitting }) => {
                values.status = declined
                  ? 'order_declined'
                  : 'order_confirmed';
                dataService
                  .editData({ values, model: `orders/${current.id}` })
                  .then(
                    (data) => {
                      setSubmitting(false);
                      setDeclined(false);
                      history.push(
                        `/admin/inbox/handled/Ordrar/${current.id}`
                      );
                    },
                    (error) => {
                      setSubmitting(false);
                      setDeclined(false);
                    }
                  );
              }}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form className={classes.form}>
                  <Field
                    variant="outlined"
                    margin="normal"
                    type="text"
                    fullWidth
                    component={TextField}
                    label="Ordernummer"
                    name="order_number"
                  />
                  <FieldArray
                    name="order_rows"
                    render={(arrayHelpers) => (
                      <React.Fragment>
                        {values.order_rows &&
                          values.order_rows.map((row, index) => (
                            <div className={classes.orderRow}>
                              <div
                                style={{ flex: 2, paddingRight: 24 }}
                              >
                                {row.bulk_item_id ? (
                                  <ReactSelect
                                    style={{ flex: 2 }}
                                    name={`order_rows[${index}][bulk_item_id]`}
                                    options={bulkItems}
                                    placeholder={`Välj plockvara`}
                                    label={'Plockvara'}
                                    onChange={(option) =>
                                      setFieldValue(
                                        `order_rows[${index}][bulk_item_id]`,
                                        option.value
                                      )
                                    }
                                    onBlur={() =>
                                      setFieldTouched(
                                        `order_rows[${index}][bulk_item_id]`,
                                        true
                                      )
                                    }
                                    value={bulkItems.find(
                                      (option) =>
                                        option.value ===
                                        values['order_rows'][index][
                                          'bulk_item_id'
                                        ]
                                    )}
                                    errors={errors}
                                    touched={touched}
                                  />
                                ) : (
                                  <ReactSelect
                                    style={{ flex: 2 }}
                                    name={`order_rows[${index}][article_id]`}
                                    options={options}
                                    placeholder={`Välj artikel`}
                                    label={'Artikel'}
                                    onChange={(option) =>
                                      setFieldValue(
                                        `order_rows[${index}][article_id]`,
                                        option.value
                                      )
                                    }
                                    onBlur={() =>
                                      setFieldTouched(
                                        `order_rows[${index}][article_id]`,
                                        true
                                      )
                                    }
                                    value={options.find(
                                      (option) =>
                                        option.value ===
                                        values['order_rows'][index][
                                          'article_id'
                                        ]
                                    )}
                                    errors={errors}
                                    touched={touched}
                                  />
                                )}
                              </div>
                              <Field
                                style={{ flex: 1, paddingRight: 24 }}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                type="number"
                                label="Antal"
                                name={`order_rows[${index}][qty]`}
                                component={TextField}
                              />
                              <IconButton
                                onClick={() =>
                                  arrayHelpers.remove(index)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ))}
                        <div className={classes.orderRow}>
                          <div
                            style={{ flex: 2, paddingRight: 24 }}
                          />
                          <div
                            style={{ flex: 1, paddingRight: 24 }}
                          />
                          <IconButton
                            aria-label="add"
                            onClick={() =>
                              arrayHelpers.push({
                                article_id: '',
                                qty: '',
                              })
                            }
                            color="primary"
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      </React.Fragment>
                    )}
                  ></FieldArray>
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: 24, flex: 1 }}>
                      <Button
                        type="submit"
                        onClick={() => setDeclined(true)}
                        size="large"
                        color="secondary"
                        fullWidth
                        variant="outlined"
                        disabled={isSubmitting}
                        style={{ paddingRight: 10 }}
                      >
                        Neka order
                      </Button>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Button
                        type="submit"
                        size="large"
                        fullWidth
                        variant="contained"
                        disabled={isSubmitting}
                        color="primary"
                      >
                        Godkänn order
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={current}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                values.status = values.archive
                  ? 'order_completed'
                  : 'order_suggested';
                dataService
                  .editData({ values, model: `orders/${current.id}` })
                  .then(
                    (data) => {
                      setSubmitting(false);
                      setDeclined(false);
                      history.push(
                        `/admin/inbox/archived/Ordrar/${current.id}`
                      );
                    },
                    (error) => {
                      setSubmitting(false);
                      setDeclined(false);
                    }
                  );
              }}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form className={classes.form}>
                  <FieldArray
                    name="order_rows"
                    render={(arrayHelpers) => (
                      <React.Fragment>
                        {values.order_rows &&
                          values.order_rows.map((row, index) => (
                            <div className={classes.orderRow}>
                              <div
                                style={{ flex: 2, paddingRight: 24 }}
                              >
                                {row.bulk_item_id ? (
                                  <ReactSelect
                                    style={{ flex: 2 }}
                                    name={`order_rows[${index}][bulk_item_id]`}
                                    options={bulkItems}
                                    isDisabled
                                    placeholder={'Välj plockvara'}
                                    label={'Plockvara'}
                                    value={bulkItems.find(
                                      (option) =>
                                        option.value ===
                                        values['order_rows'][index][
                                          'bulk_item_id'
                                        ]
                                    )}
                                    errors={errors}
                                    touched={touched}
                                  />
                                ) : (
                                  <Field
                                    style={{ flex: 2 }}
                                    name={`order_rows[${index}][article]`}
                                    label={'Artikel'}
                                    disabled
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    fullWidth
                                    component={TextField}
                                  />
                                )}
                              </div>
                              <Field
                                style={{ flex: 1 }}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                type="number"
                                label="Antal"
                                name={`order_rows[${index}][qty]`}
                                disabled
                                component={TextField}
                              />
                            </div>
                          ))}
                      </React.Fragment>
                    )}
                  ></FieldArray>
                  <Field
                    name={'archive'}
                    type="checkbox"
                    value={values['archive']}
                    checked={values['archive']}
                    handleToggle={(event) => {
                      setFieldValue('archive', event.target.checked);
                    }}
                    label="Arkivera order"
                    component={CheckboxField}
                    color="primary"
                  />
                  <Button
                    type="submit"
                    size="large"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting || !values['archive']}
                    color="primary"
                  >
                    Arkivera order
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Notifications = ({
  match,
  boxes,
  refresh,
  location,
  history,
}) => {
  const classes = useStyles();

  const params = queryString.parse(location.search);
  const { status } = match.params;

  const [notifications, setNotifications] = useState([]);
  const [total, setTotal] = useState([]);
  const [box, setBox] = useState({});
  const [query, setQuery] = useState(params);

  useEffect(() => {
    const box =
      boxes.find((box) => box.type === match.params.box) || boxes[0];
    setBox(box);
  }, [boxes, match.params.box]);

  useEffect(() => {
    let value = 0;

    switch (status) {
      case 'handled':
        value = 2;
        break;
      case 'archived':
        value = 3;
        break;
      default:
        value = 0;
    }
    dataService
      .getData({
        model: 'notifications',
        params: `?filter[0][key]=status&filter[0][value]=${value}&order_by=created_at&${queryString.stringify(
          params
        )}`,
      })
      .then((data) => {
        const { total, result } = data;
        setTotal(total);
        setNotifications(result);
        setQuery(params);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, location.search, status]);

  const handleChangePage = (event, newPage) => {
    const search = `?${queryString.stringify(
      { ...query, page: newPage + 1 },
      { encode: false }
    )}`;
    return history.push({
      pathname: match.url,
      search,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const search = `?${queryString.stringify(
      { ...query, per_page: event.target.value },
      { encode: false }
    )}`;
    return history.push({
      pathname: match.url,
      search,
    });
  };

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <TablePagination
          className={classes.pagination}
          labelRowsPerPage="Rader per sida"
          component="div"
          page={query.page ? query.page - 1 : 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} av ${count}`
          }
          // rowsPerPageOptions={[20, 50, { value: total, label: 'Visa alla'}]}
          rowsPerPageOptions={[20, 50, 200, 500]}
          rowsPerPage={query.per_page ? query.per_page : 20}
          count={total}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Toolbar>
      <Divider />
      <List className={classes.list}>
        {notifications.map((notification) => (
          <React.Fragment>
            <ListItem
              className={classes.item}
              key={notification.id}
              button
            >
              <Link
                to={
                  match.params.box
                    ? `${match.url}/${notification.model_reference}`
                    : `${match.url}/${box.type}/${notification.model_reference}`
                }
                className={classes.text}
              >
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.from,
                    notification.status === 'available' &&
                      classes.bold
                  )}
                >{`Orderbeställning ${
                  notification.order && notification.order.storage
                    ? `till ${
                        notification.order &&
                        notification.order.storage
                      }`
                    : `av ${
                        notification.order && notification.order.user
                      }`
                }`}</Typography>
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.subject,
                    notification.status === 'available' &&
                      classes.bold
                  )}
                >
                  {notification.subject}
                </Typography>
                <span className={classes.spacer} />
                <Chip
                  style={{
                    backgroundColor:
                      notification.order &&
                      notification.order.external_order
                        ? primary
                        : secondary,
                    color: '#ffffff',
                    marginLeft: 'auto',
                    transition: 'all .5s ease-in-out',
                  }}
                  size="small"
                  label={
                    notification.order &&
                    notification.order.external_order
                      ? 'Extern'
                      : 'Intern'
                  }
                />
                <span className={classes.spacer} />
                <Typography
                  variant="body2"
                  className={classNames(
                    classes.time,
                    notification.status === 'available' &&
                      classes.bold
                  )}
                >
                  {formatDistanceToNow(
                    new Date(notification.created_at),
                    { locale: sv }
                  )}
                </Typography>
              </Link>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
        {/* {box && box.notifications.map(notification => (notification.status === 'new_notification' || notification.status === 'read') && (
        ))} */}
      </List>
      <Toolbar className={classes.toolbar}>
        <TablePagination
          className={classes.pagination}
          labelRowsPerPage="Rader per sida"
          component="div"
          page={query.page ? query.page - 1 : 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} av ${count}`
          }
          // rowsPerPageOptions={[20, 50, { value: total, label: 'Visa alla'}]}
          rowsPerPageOptions={[20, 50, 200, 500]}
          rowsPerPage={query.per_page ? query.per_page : 20}
          count={total}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Toolbar>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: 70,
    position: 'relative',
    // paddingLeft: drawerWidth,
    // minHeigh: '100vh',
    display: 'flex',
  },
  form: {
    padding: 24,
  },
  orderRow: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    display: 'flex',
  },
  bottomToolbar: {
    marginTop: 'auto',
  },
  noPadding: {
    padding: 0,
  },
  sidebar: {
    width: drawerWidth,
    borderRight: `1px solid #e4e4e4`,
  },
  search: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  pagination: {
    marginLeft: 'auto',
  },
  list: {
    width: '100%',
    padding: 0,
    flex: 2,
  },
  text: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    minWidth: 1,
    textDecoration: 'none',
    color: 'inherit',
    padding: 9,
  },
  from: {
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    minWidth: '250px',
    // flexBasis: '250px',
    // flexGrow: 1,
    // textOverflow: 'ellipsis'
  },
  subject: {
    overflow: 'hidden',
    flexGrow: 1,
    maxWidth: '400px',
    marginRight: 'auto',
    whiteSpace: 'nowrap',
    marginLeft: 10,
    textOverflow: 'ellipsis',
  },
  short: {
    overflow: 'hidden',
    flexGrow: 1,
    maxWidth: '800px',
    whiteSpace: 'nowrap',
    marginRight: 'auto',
    textOverflow: 'ellipsis',
  },
  spacer: {
    marginLeft: 16,
    marginRight: 16,
  },
  item: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  time: {
    whiteSpace: 'nowrap',
  },
  bold: {
    fontWeight: 700,
  },
  old: {
    color: theme.palette.error.main,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'transparent',
    // position: 'absolute'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  selected: {
    backgroundColor: 'transparent!important',
    color: theme.palette.primary.main,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    transform: 'color .5s ease-in-out',
  },
}));

const Inbox = ({
  match,
  history,
  location,
  notifications = [],
  newNotifications,
  refresh,
}) => {
  const classes = useStyles();
  const [archived, setArchived] = useState(0);
  const [handled, setHandled] = useState(0);

  const boxes = [
    {
      type: 'Ordrar',
    },
  ];

  useEffect(() => {
    dataService
      .getData({
        model: 'notifications',
        params: '?filter[0][key]=status&filter[0][value]=2',
      })
      .then((data) => {
        const { total } = data;
        setHandled(total);
      });
    dataService
      .getData({
        model: 'notifications',
        params: '?filter[0][key]=status&filter[0][value]=3',
      })
      .then((data) => {
        const { total } = data;
        setArchived(total);
      });
  }, [refresh]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.sidebar}>
        <Toolbar
          className={classNames(classes.toolbar, classes.noPadding)}
        >
          <Formik
            onSubmit={(values, { setSubmitting, setStatus }) => {
              const { q } = values;
            }}
          >
            {({
              values,
              errors,
              touched,
              status,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <Field
                  placeholder="Sök"
                  name="q"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  component={({
                    field: { ...fields },
                    form: { touched, errors, ...rest },
                    ...props
                  }) => (
                    <InputBase
                      {...props}
                      {...fields}
                      error={Boolean(
                        touched[fields.name] && errors[fields.name]
                      )}
                    />
                  )}
                />
              </Form>
            )}
          </Formik>
        </Toolbar>
        <Divider />
        <List
          classes={{
            root: classes.list,
          }}
        >
          <ListSubheader>Inkorg</ListSubheader>
          {boxes.map((box) => (
            <Link
              key={box.type}
              to={`${match.url}/${box.type}`}
              className={classes.link}
            >
              <ListItem
                button
                selected={
                  location.pathname
                    .split('/admin/inbox/')
                    .pop()
                    .split('/')[0] === '' ||
                  location.pathname
                    .split('/admin/inbox/')
                    .pop()
                    .split('/')[0] === box.type
                }
                classes={{
                  selected: classes.selected,
                }}
              >
                <ListItemIcon
                  classes={{
                    root: classes.icon,
                  }}
                >
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span style={{ display: 'flex' }}>
                      {box.type}
                      <Chip
                        color={'default'}
                        style={{
                          backgroundColor:
                            (location.pathname
                              .split('/admin/inbox/')
                              .pop()
                              .split('/')[0] === '' ||
                              location.pathname
                                .split('/admin/inbox/')
                                .pop()
                                .split('/')[0] === box.type) &&
                            primary,
                          color:
                            (location.pathname
                              .split('/admin/inbox/')
                              .pop()
                              .split('/')[0] === '' ||
                              location.pathname
                                .split('/admin/inbox/')
                                .pop()
                                .split('/')[0] === box.type) &&
                            '#ffffff',
                          marginLeft: 'auto',
                          opacity: Boolean(newNotifications) ? 1 : 0,
                          transition: 'all .5s ease-in-out',
                        }}
                        size="small"
                        label={newNotifications}
                      />
                    </span>
                  }
                />
              </ListItem>
            </Link>
          ))}
          <ListSubheader>Hanterade</ListSubheader>
          {boxes.map((box) => (
            <Link
              key={box.type}
              to={`${match.url}/handled/${box.type}`}
              className={classes.link}
            >
              <ListItem
                button
                selected={
                  location.pathname
                    .split('/admin/inbox/')
                    .pop()
                    .split('/')[0] === 'handled'
                }
                classes={{
                  selected: classes.selected,
                }}
              >
                <ListItemIcon
                  classes={{
                    root: classes.icon,
                  }}
                >
                  <CheckBoxIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span style={{ display: 'flex' }}>
                      {box.type}
                      <Chip
                        color={'default'}
                        style={{
                          backgroundColor:
                            location.pathname
                              .split('/admin/inbox/')
                              .pop()
                              .split('/')[0] === 'handled' && primary,
                          color:
                            location.pathname
                              .split('/admin/inbox/')
                              .pop()
                              .split('/')[0] === 'handled' &&
                            '#ffffff',
                          marginLeft: 'auto',
                          opacity: Boolean(handled) ? 1 : 0,
                          transition: 'all .5s ease-in-out',
                        }}
                        size="small"
                        label={handled}
                      />
                    </span>
                  }
                />
              </ListItem>
            </Link>
          ))}
          <ListSubheader>Arkiverade</ListSubheader>
          {boxes.map((box) => (
            <Link
              key={box.type}
              to={`${match.url}/archived/${box.type}`}
              className={classes.link}
            >
              <ListItem
                button
                selected={
                  location.pathname
                    .split('/admin/inbox/')
                    .pop()
                    .split('/')[0] === 'archived'
                }
                classes={{
                  selected: classes.selected,
                }}
              >
                <ListItemIcon
                  classes={{
                    root: classes.icon,
                  }}
                >
                  <ArchiveIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span style={{ display: 'flex' }}>
                      {box.type}
                      <Chip
                        color={'default'}
                        style={{
                          backgroundColor:
                            location.pathname
                              .split('/admin/inbox/')
                              .pop()
                              .split('/')[0] === 'archived' &&
                            primary,
                          color:
                            location.pathname
                              .split('/admin/inbox/')
                              .pop()
                              .split('/')[0] === 'archived' &&
                            '#ffffff',
                          marginLeft: 'auto',
                          opacity: Boolean(archived) ? 1 : 0,
                          transition: 'all .5s ease-in-out',
                        }}
                        size="small"
                        label={archived}
                      />
                    </span>
                  }
                />
              </ListItem>
            </Link>
          ))}
        </List>
        <Toolbar className={classes.toolbar} />
      </div>
      <div className={classes.content}>
        <Switch>
          <Route
            exact
            path={`${match.url}`}
            render={(props) => (
              <Notifications
                {...props}
                boxes={boxes}
                refresh={refresh}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/:box`}
            render={(props) => (
              <Notifications
                {...props}
                boxes={boxes}
                refresh={refresh}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/Ordrar/:id`}
            render={(props) => <Order {...props} />}
          />
          <Route
            exact
            path={`${match.url}/:status/:box`}
            render={(props) => (
              <Notifications
                {...props}
                boxes={boxes}
                refresh={refresh}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/handled/Ordrar/:id`}
            render={(props) => <HandledOrder {...props} />}
          />
          <Route
            exact
            path={`${match.url}/archived/Ordrar/:id`}
            render={(props) => <ArchivedOrder {...props} />}
          />
        </Switch>
      </div>
    </Paper>
  );
};

export default Inbox;
