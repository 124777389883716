import React from 'react'
import { Route, Link, Switch } from 'react-router-dom'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import MoveIcon from '@material-ui/icons/AssignmentReturn'
import BarChartIcon from '@material-ui/icons/BarChart'
import ImportIcon from '@material-ui/icons/AssignmentReturned'

import BulkIcon from './icons/BulkIcon'
import Table from './components/BulkItems/Table'
import Move from './components/BulkItems/Move'
import Storage from './components/BulkItems/Storage'
import Import from './components/BulkItems/Import'

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    padding: theme.spacing(1),
    marginBottom: 24,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  },
}));

export default ({
  match,
  location
}) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <div className={classes.demo2}>
        <StyledTabs value= {`/${location.pathname.split(`${match.url}/`).pop().split('/').shift()}`}>
          <StyledTab label='Plockvaror' icon={<BulkIcon />} value={'/'} component={Link} to={match.url} />
          <StyledTab label='Påfyllning' icon={<ImportIcon />} value={'/import'} component={Link} to={`${match.url}/import`}/> */}
          <StyledTab label='Lagerstatus' icon={<BarChartIcon />} value={'/storage'} component={Link} to={`${match.url}/storage`} />
          <StyledTab label='Lagerflytt' icon={<MoveIcon />} value={'/move'} component={Link} to={`${match.url}/move`} />

            
        </StyledTabs>
      </div>
      <Switch>
        <Route path={`${match.url}/import`} render={props => <Import {...props} />} />
        <Route path={`${match.url}/storage`} render={props => <Storage {...props} />} />
        <Route path={`${match.url}/move`} render={props => <Move {...props} />} /> 
        <Route path={match.url} render={props => <Table {...props} />} />
      </Switch>
    </React.Fragment>
  )
}
