import React from 'react'

import Button from '@material-ui/core/Button'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { primary, error, contrastText, background} from './colors'
const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative'
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  loading: {
    color: primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -12
  }
}))

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primary
    },
    secondary: {
      main: error,
      contrastText: contrastText
    },
    error: {
      main: error
    },
    background: {
      default: background
    }
  }
})

export default ({ children, disabled, noMargin, loading, ...props }) => {
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.wrapper}>
        <Button
          disabled={disabled}
          className={!noMargin && classes.submit}
          {...props}
        >
          {children}
        </Button>
        {loading && <CircularProgress size={24} className={classes.loading} />}
      </div>
    </ThemeProvider>
  )
}