import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import MuiLink from '@material-ui/core/Link'

import { Form, Formik, Field } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'

import { authenticationService } from './services'

import TextField from './components/fields/TextField'
import PasswordField from './components/fields/PasswordField'
import Button from './components/Button'
import Logo from './images/logo'
import { Grid } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  }
}))

export default ({ history, location, match }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  
  return (
    <Container component='main' maxWidth='xs'>
      <Paper className={classes.paper}>
        <Logo size={70} />
        <Typography component='h1' variant='h4' style={{marginTop: 10}}>
            OneAsset
        </Typography>
        <Formik
          initialValues={{}}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Felaktig e-postadress')
              .required('Ange e-postadress'),
            password: Yup.string()
              .required('Du måste ange ett lösenord')
          })}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            authenticationService.login(values)
              .then(
                user => {
                  const { from } = location.state || { from: { pathname: '/admin' } }
                  history.push(from)
                },
                error => {
                  setSubmitting(false)
                  if (Array.isArray(error)) {
                    error.forEach(err => {
                      enqueueSnackbar(err.message || err.type, { variant: 'error' })
                    })
                  } else {
                    enqueueSnackbar(error, { variant: 'error' })
                  }
                }
              )
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form className={classes.form}>
              <Field
                variant='outlined'
                margin='normal'
                type='email'
                name='email'
                fullWidth
                autoFocus
                component={TextField}
                label='Användarnamn'
              />
              <Field
                variant='outlined'
                margin='normal'
                type='password'
                name='password'
                fullWidth
                component={PasswordField}
                label='Lösenord'
              />
              <Button
                type='submit'
                size='large'
                fullWidth
                variant='contained'
                disabled={isSubmitting}
                color='primary'
              >
                Logga in
              </Button>
            </Form>
          )}
        </Formik>
        <Grid container>
          <Grid item xs>
            <Link
              to={`/forgot`}
            >
              <MuiLink>
                Glömt lösenord?
              </MuiLink>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}
