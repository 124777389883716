import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 24
  },
  chip: {
    margin: theme.spacing(1)
  }
}))

export default ({
  onDelete,
  filters,
  cols
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {cols && Array.isArray(cols) && Object.keys(filters).map(filter => {
        const {
          label,
          human_key,
          type,
          array,
          nested,
          options = [],
          filter: filterOption
        } = cols.find(col => col.nested ? col.nested.filterKey === filter : col.key === filter)
        const multiple = typeof filters[filter] === 'string' && filters[filter].includes('|')
        const multivalue = ['text', 'datetime'].includes(type) && !(filterOption && filterOption.type !== 'list')

        const chipLabel = () => (human_key || nested || options.some(o => o.alias)) && options.length > 0 ? (
          <React.Fragment>
            <Typography variant='subtitle2' color=''>
              {`${label} | `}
            </Typography>
            {array ? (
              <Typography variant='subtitle2'>
                {`${JSON.parse(filters[filter]).map(id => multivalue
                  ? `${id}`
                  : `${options.find(option => option.value === id).label}`)
                  }`
                }
              </Typography>
            ) : (
              <Typography variant='subtitle2'>
                &nbsp;{`${Boolean(options.find(option =>
                  option.value === parseInt(filters[filter], 10))) && options.find(option =>
                    option.value === parseInt(filters[filter], 10)
                  ).label
                  }`}
              </Typography>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography variant='subtitle2'>
              {`${label} | `}
            </Typography>
            {!array && (
              <Typography variant='subtitle2'>
                &nbsp;{`${filters[filter]}`}
              </Typography>
            )}
          </React.Fragment>
        )

        return (
          multiple ? (
            filters[filter].split('|').map((f, i) => (
              <Chip
                color='primary'
                className={classes.chip}
                key={`${f}-${i}`}
                label={
                  <React.Fragment>
                    <Typography variant='subtitle2'>
                      {`${label} | `}
                    </Typography>
                    {!array && (
                      <Typography variant='subtitle2'>
                        &nbsp;{multivalue
                          ? `${f}`
                          : options && options.length && options.find(option => option.value === parseInt(f, 10)).label
                        }
                      </Typography>
                    )}
                  </React.Fragment>
                }
                // todo check if multiple here
                onDelete={
                  () => onDelete(f, true, Object.keys(filters).find(key => filters[key] === filters[filter]))
                }
              />
            ))
          ) : (

            <Chip
              color='primary'
              className={classes.chip}
              key={filter}
              label={chipLabel()}
              onDelete={
                () => onDelete(filter, false, null)
              }
            />
          )

        )
      })}
    </div>
  )
}
