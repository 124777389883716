import React, { useState, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';
import { makeStyles, withStyles } from '@material-ui/styles';
import classNames from 'clsx';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Modal from '@material-ui/core/Modal';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Csv from '../../images/csv';
import Button from '../Button';
import xlsx from 'xlsx';
import { dataService } from '../../services';

import ReactSelect from '../fields/ReactSelect';
import TextField from '../fields/TextField';
import { Tab, Tabs, Toolbar } from '@material-ui/core';
import CheckboxField from '../fields/CheckboxField';

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />
))

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxWidth: '100%',
    height: 'calc(100vh - 200px)',
    overflow: 'scroll',
    transition:
      'transform .2s cubic-bezier(.29,1.09,.74,1.3), opacity .2s ease-in-out',
    // height: '100%',
    '&:focus': {
      outline: 0,
    },
  },
  active: {
    transform: 'scale(0.95)',
    opacity: 0.7,
  },
  greenIcon: {
    color: 'green',
  },
  redIcon: {
    color: theme.palette.error.main,
  },
  fabIcon: {
    marginRight: 10,
  },
  form: {
    padding: '12px',
    border: '1px solid #e4e4e4',
    borderRadius: '5px',
  },
}));

const MassEdit = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('edit')
  const [importedData, setImportedData] = useState([]);
  const [parsedSerialNumbers, setParsedSerialNumbers] = useState({
    accepted: 0,
    rejected: 0,
    missingItems: [],
  });

  const [submitted, setSubmitted] = useState(false);
  const [missingItemsModalOpen, setMissingItemsModalOpen] =
    useState(false);

  const [storageLocations, setStorageLocations] = useState([]);
  const [storages, setStorages] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [articles, setArticles] = useState([]);

  const code = false;

  useEffect(() => {
    dataService
      .getData({ model: 'storages', params: '?pagination=off' })
      .then((data) => {
        const { result } = data;
        const options = [];
        result.forEach((option) => {
          options.push({
            value: option.id,
            label: option.label,
            check_in: option.check_in,
          });
        });
        setStorages(options);
      });
    dataService
      .getData({ model: 'statuses', params: '?pagination=off' })
      .then((data) => {
        const { result } = data;
        const options = [];
        result.forEach((option) => {
          options.push({ value: option.id, label: option.label });
        });
        setStatuses(options);
      });

    dataService
      .getData({
        model: 'articles',
        params:
          '?filter[0][key]=selectable&filter[0][value]=1&pagination=off',
      })
      .then((data) => {
        const { result } = data;
        const options = [];
        result.forEach((option) => {
          options.push({ value: option.id, label: option.label });
        });
        setArticles(options);
      });
  }, []);

  const onDropAccepted = (acceptedFiles) => {
    const [file] = acceptedFiles;
    const reader = new FileReader();

    const rABS = !!reader.readAsBinaryString;

    reader.onerror = () => {
      // debugger;
    };
    reader.onabort = () => {
      // debugger;
    };
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = xlsx.read(bstr, {
        type: rABS ? 'binary' : 'array',
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

      const formatted = data
        .map((inner) => inner[0] || null)
        .filter((item) => item);

      setImportedData(formatted);
      validateSerialNumbers(formatted);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const validateSerialNumbers = async (data) => {
    // const res = await dataService.validateData()
    setLoading(true);

    const key = enqueueSnackbar('Validerar serienummer..', {
      variant: 'info',
    });

    const res = await dataService.validateData({
      model: '/items/verify_batch',
      values: {
        items: data,
      },
    });

    closeSnackbar(key);

    if (res) {
      enqueueSnackbar('Validering klar', { variant: 'info' });
      setParsedSerialNumbers({
        accepted: res.found,
        rejected: res.not_found,
        missingItems: res.missing_items,
      });
    } else {
      enqueueSnackbar('Kunde inte validera', { variant: 'error' });
    }
    setLoading(false);
  };

  const getStorageLocations = (storage_id) => {
    setStorageLocations([]);
    dataService
      .getData({
        model: 'storage_locations',
        params: `?filter[0][key]=storage_id&filter[0][value]=${storage_id}&pagination=off`,
      })
      .then((data) => {
        const { result } = data;
        const options = [];
        result.forEach((option) => {
          options.push({ value: option.id, label: option.label });
        });
        setStorageLocations(options);
      });
  };

  const onDropRejected = () => {
    enqueueSnackbar('Vald fild kan inte användas', {
      variant: 'error',
    });
  };

  const { getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      onDropAccepted,
      onDropRejected,
      noClick: true,
      maxFiles: 1,
      accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

  return (
    <React.Fragment>
      <Modal
        onBackdropClick={() => setMissingItemsModalOpen(false)}
        onEscapeKeyDown={() => setMissingItemsModalOpen(false)}
        open={missingItemsModalOpen}
      >
        <div
          style={{
            width: '100vw',
            height: '85vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setMissingItemsModalOpen(false)}
          >
            Stäng
          </Button>
          <Paper
            style={{ overflow: 'scroll', height: '90%', padding: 8 }}
          >
            <List dense>
              {parsedSerialNumbers.missingItems &&
                parsedSerialNumbers.missingItems.map((missing) => (
                  <ListItem>
                    <ListItemText primary={missing} />
                  </ListItem>
                ))}
            </List>
          </Paper>
        </div>
      </Modal>
      <Paper
        className={classNames(
          classes.paper,
          isDragActive && classes.active
        )}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {importedData.length > 0 && !loading ? (
            <div
              style={{
                width: '100%',
                paddingTop: '8px',
                paddingBottom: 20,
                height: '100%',
                paddingRight: '12px',
                paddingLeft: '12px',
              }}
            >
              <Toolbar>
                <StyledTabs
                  value={mode}
                  onChange={(_, value) => setMode(value)}
                >
                  <StyledTab
                    label="Redigera"
                    value={'edit'}
                  />
                  <StyledTab
                    label="Radera"
                    value={'delete'}
                    style={{ color: 'red' }}
                  />
                </StyledTabs>
              </Toolbar>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5">
                  XLSX-fil innehåller {importedData.length} rader
                </Typography>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setImportedData([]);
                      setParsedSerialNumbers({
                        accepted: 0,
                        rejected: 0,
                        missingItems: [],
                      });
                    }}
                  >
                    Rensa import
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => setMissingItemsModalOpen(true)}
                    disabled={
                      !(parsedSerialNumbers.missingItems.length > 0)
                    }
                  >
                    Se saknade serienummer
                  </Button>
                </div>
              </div>

              <div
                style={{ padding: 16, display: 'flex', gap: '15px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Icon className={classes.greenIcon}>
                    <CheckCircleIcon />
                  </Icon>
                  <Typography>
                    {parsedSerialNumbers.accepted} hittades
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Icon className={classes.redIcon}>
                    <HighlightOffIcon />
                  </Icon>
                  <Typography>
                    {parsedSerialNumbers.rejected} hittades inte
                  </Typography>
                </div>
              </div>

              {parsedSerialNumbers.accepted > 0 && (
                <>
                  {(mode === 'delete') ? (
                    <Formik
                      initialValues={{
                        confirm: false,
                      }}
                      enableReinitialize={true}
                      onSubmit={async (values, { resetForm }) => {
                        let success = false;
                        setLoading(true);
                        const saveToast = enqueueSnackbar('Raderar...', {
                          variant: 'info',
                        });
                        const payload = {
                          ...values,
                          items: importedData.flat(1),
                        };
                        try {
                          await dataService.createData({
                            model: 'items/delete_batch',
                            values: payload,
                          });
                          success = true;
                        } catch (error) {
                          error.forEach((error) =>
                            enqueueSnackbar(error, { variant: 'error' })
                          );
                        }
                        closeSnackbar(saveToast);
                        setLoading(false);
                        if (success) {
                          enqueueSnackbar(
                            'De valda individerna är raderade',
                            { variant: 'success' }
                          );
                          resetForm();
                          setSubmitted(true);
                          setImportedData([]);
                          setParsedSerialNumbers({
                            accepted: 0,
                            rejected: 0,
                            missingItems: [],
                          });
                        }
                      }}
                    >
                      {({
                        isSubmitting,
                        setFieldValue,
                        values,
                      }) => (
                        <Form className={classes.form}>
                          <Typography
                            variant="body1"
                            style={{ marginBottom: '8px' }}
                          >
                            Bekräfta radering av valda individer.
                          </Typography>
                          <Field
                            name='confirm'
                            type='checkbox'
                            color='primary'
                            label={'Bekräfta'}
                            checked={values.confirm}
                            handleToggle={event => {setFieldValue('confirm', event.target.checked)} }
                            component={CheckboxField}
                          />
                          <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            disabled={isSubmitting || !values.confirm}
                            loading={isSubmitting}
                            color="primary"
                          >
                            {isSubmitting ? 'Raderar...' : 'Radera'}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <Formik
                      initialValues={{
                        article_id: '',
                        storage_id: '',
                        storage_location_id: '',
                        status_id: '',
                        order_number: '',
                      }}
                      enableReinitialize={true}
                      onSubmit={async (values, { resetForm }) => {
                        let success = false;
                        setLoading(true);
                        const saveToast = enqueueSnackbar('Sparar...', {
                          variant: 'info',
                        });
                        const payload = {
                          ...values,
                          items: importedData.flat(1),
                        };
                        try {
                          await dataService.createData({
                            model: 'items/update_batch',
                            values: payload,
                          });
                          success = true;
                        } catch (error) {
                          error.forEach((error) =>
                            enqueueSnackbar(error, { variant: 'error' })
                          );
                        }
                        closeSnackbar(saveToast);
                        setLoading(false);
                        if (success) {
                          enqueueSnackbar(
                            'De valda individerna är uppdaterade!',
                            { variant: 'success' }
                          );
                          resetForm();
                          setSubmitted(true);
                          setImportedData([]);
                          setParsedSerialNumbers({
                            accepted: 0,
                            rejected: 0,
                            missingItems: [],
                          });
                        }
                      }}
                    >
                      {({
                        isSubmitting,
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        setFieldTouched,
                      }) => (
                        <Form className={classes.form}>
                          <Typography
                            variant="body1"
                            style={{ marginBottom: '8px' }}
                          >
                            Ange värden att ändra för valda individer.
                          </Typography>
                          <ReactSelect
                            name={`article_id`}
                            // dense
                            options={articles}
                            placeholder="Välj artikel"
                            label="Artikel"
                            fullWidth
                            isDisabled={isSubmitting}
                            onChange={(option) =>
                              setFieldValue(
                                `article_id`,
                                option ? option.value : null
                              )
                            }
                            onBlur={() =>
                              setFieldTouched(`article_id`, true)
                            }
                            value={articles.find(
                              (option) => option === values.article_id
                            )}
                            errors={errors}
                            touched={touched}
                          />
                          <ReactSelect
                            name="storage_id"
                            placeholder="Välj lager"
                            label="Lager"
                            options={storages}
                            isDisabled={isSubmitting || code}
                            onChange={(option) => {
                              setStorageLocations([]);
                              getStorageLocations(
                                option ? option.value : null
                              );
                              // setHook(option ? option.value : null)
                              setFieldValue('storage_location_id', '');
                              return setFieldValue(
                                'storage_id',
                                option ? option.value : null
                              );
                            }}
                            onBlur={() =>
                              setFieldTouched('storage_id', true)
                            }
                            errors={errors}
                            touched={touched}
                            value={storages.find(
                              (option) =>
                                option.value === values['storage_id']
                            )}
                          />
                          <ReactSelect
                            name="storage_location_id"
                            placeholder="Välj lagerplats"
                            label="Lagerplats"
                            options={storageLocations}
                            onChange={(option) =>
                              setFieldValue(
                                'storage_location_id',
                                option ? option.value : null
                              )
                            }
                            onBlur={() =>
                              setFieldTouched('storage_location_id', true)
                            }
                            errors={errors}
                            touched={touched}
                            isDisabled={
                              !values['storage_id'] ||
                              !storageLocations.length ||
                              isSubmitting ||
                              code
                            }
                            value={
                              storageLocations.find(
                                (option) =>
                                  option.value ===
                                  values['storage_location_id']
                              ) || null
                            }
                          />
                          <ReactSelect
                            name="status_id"
                            placeholder="Välj status"
                            label="Status"
                            options={statuses}
                            isDisabled={isSubmitting}
                            onChange={(option) =>
                              setFieldValue(
                                'status_id',
                                option ? option.value : null
                              )
                            }
                            onBlur={() =>
                              setFieldTouched('status_id', true)
                            }
                            errors={errors}
                            touched={touched}
                          />
                          <Field
                            name={'order_number'}
                            variant="outlined"
                            margin="normal"
                            type={'text'}
                            fullWidth
                            component={TextField}
                            label={'Ordernummer'}
                            disabled={isSubmitting}
                          />
                          <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            disabled={
                              isSubmitting ||
                              Object.keys(values).every(
                                (key) => !Boolean(values[key])
                              )
                            }
                            loading={isSubmitting}
                            color="primary"
                          >
                            {isSubmitting ? 'Skickar...' : 'Spara'}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  )}
                </>
              )}
            </div>
          ) : importedData.length === 0 && !loading ? (
            <div
              style={{
                width: '100%',
                paddingTop: '40px',
                paddingBottom: 50,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Csv
                style={{ width: 200, marginBottom: 40 }}
                color="#bebebe"
              />
              <Typography component="h2" variant="h5" align="center">
                Ladda upp Mätarfil (*.xlsx)
              </Typography>
              <Typography
                component="h3"
                variant="subtitle1"
                align="center"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Drag och släpp fil från dator eller{' '}
                <Button
                  onClick={open}
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ margin: 10 }}
                >
                  Klicka här
                </Button>
              </Typography>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                paddingTop: '40px',
                paddingBottom: 50,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default MassEdit;
