import React from 'react';
import { Route, Link, Switch } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImportIcon from '@material-ui/icons/AssignmentReturned';
import ItemsIcon from '@material-ui/icons/Assignment';
import MoveIcon from '@material-ui/icons/AssignmentReturn';
import StockTakingIcon from '@material-ui/icons/AssignmentTurnedIn';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import Table from './components/Items/Table';
import Import from './components/Items/Import';
import Move from './components/Items/Move';
import StockTaking from './components/Items/StockTaking';
import MassEdit from './components/Items/MassEdit';
import { ListAlt } from '@material-ui/icons';
import checkPermissions from './helpers/checkPermissions';

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />
));

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    padding: theme.spacing(1),
    marginBottom: 24,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
}));

export default ({ match, location }) => {
  const classes = useStyles();

  const hasSpecialAccess = checkPermissions({
    model: 'items',
    type: 'special_access',
  });

  return (
    <React.Fragment>
      <div className={classes.demo2}>
        <StyledTabs
          value={`/${location.pathname
            .split(`${match.url}/`)
            .pop()
            .split('/')
            .shift()}`}
        >
          <StyledTab
            label="Individer"
            icon={<ItemsIcon />}
            value={'/'}
            component={Link}
            to={match.url}
          />
          <StyledTab
            label="Import"
            icon={<ImportIcon />}
            value={'/import'}
            component={Link}
            to={`${match.url}/import`}
          />
          <StyledTab
            label="Lagerflytt"
            icon={<MoveIcon />}
            value={'/move'}
            component={Link}
            to={`${match.url}/move`}
          />

          <StyledTab
            label="Inventering"
            icon={<StockTakingIcon />}
            value={'/stock-taking'}
            component={Link}
            to={`${match.url}/stock-taking`}
          />
          {hasSpecialAccess && (
            <StyledTab
              label="Massredigering"
              icon={<ListAlt />}
              value={'/mass-edit'}
              component={Link}
              to={`${match.url}/mass-edit`}
            />
          )}
        </StyledTabs>
      </div>
      <Switch>
        <Route
          path={`${match.url}/stock-taking`}
          render={(props) => <StockTaking {...props} />}
        />
        <Route
          path={`${match.url}/import`}
          render={(props) => <Import {...props} />}
        />
        <Route
          path={`${match.url}/move`}
          render={(props) => <Move {...props} />}
        />
        {hasSpecialAccess && (
          <Route
            path={`${match.url}/mass-edit`}
            render={(props) => <MassEdit {...props} />}
          />
        )}
        <Route
          path={match.url}
          render={(props) => <Table {...props} />}
        />
      </Switch>
    </React.Fragment>
  );
};
