import React, {useState, useEffect } from 'react'

import Table from './components/Table'
import { dataService } from './services'

const cols = [
  {
    id: 1,
    key: 'name',
    label: 'Namn',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'text'
  },
  {
    id: 2,
    key: 'email',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Email',
    type: 'email'
  },
  {
    id: 3,
    key: 'role_id',
    human_key: 'role',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Behörighet',
    type: 'select'
  }
]

export default ({
  match,
  history,
  location
}) => {
  useEffect(() => {
    dataService.getData({model: 'roles', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        cols.find(col => col.id === 3).options = options
        setEnhanced(cols)
      })
  }, [])

  const [enhanced, setEnhanced] = useState(cols)

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Användare'
      singular='Användare'
      model='users'
    />
  )
}