import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Skeleton from '@material-ui/lab/Skeleton'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'

import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'



import { dataService } from '../../services'

import { background, error, success } from '../colors'
import { Table, TableHead, TableRow, TableCell, TableBody, ListItemAvatar, Avatar, IconButton, Tooltip, CircularProgress } from '@material-ui/core'
import ExportFileIcon from '../../icons/ExportFileIcon'
import { selectQuery } from '../../utils'

const useStatusStyles = makeStyles(() => ({
  success: {
    backgroundColor: success
  },
  error: {
    backgroundColor: error
  }
}))

const StatusAvatar = ({
  status
}) => {
  const classes = useStatusStyles()

  switch (status) {
    case 'not_ok':
      return (
        <Avatar className={classes.error}>
          <ThumbDownAltIcon />
        </Avatar>
      )
    default:
      return (
        <Avatar className={classes.success}>
          <ThumbUpAltIcon />
        </Avatar>
      )
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: 70,
    overflow: 'scroll'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  },
  wrapper: {
    width: '100%',
    padding: 24
  },
  list: {
    borderTop: `1px solid ${background}`,
    borderBottom: `1px solid ${background}`,
    padding: 8
  },
  not_ok: {
    color: error
  },
  ok: {
    color: success
  },
  actions: {
    padding: '8px 12px'
  },
  button: {
    margin: 12
  },
  icon: {
    marginRight: 10
  },
  image: {
    width: '100%',
    maxWidth: 300
  },
  to: {
    display: 'flex',
    alignItems: 'center'
  },
  skeleton: {
    margin: '2px 0 1px'
  },
}))

const fields = ['id', 'article_id', 'serial_number']

export default ({
  match,
  setRefresh
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [exporting, setExporting] = useState(false)
  const [stockImport, setStockImport] = useState({})
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  useEffect(() => {
    setLoading(true)
    dataService.getData({ model: `stock_imports/${match.params.id}`, params: '' })
      .then(data => {
        setStockImport(data)
        dataService.getData({ model: `stock_imports/${match.params.id}/items?${selectQuery(fields)}&pagination=off`, params: '' })
          .then(data => {
            setItems(data.result)
            setLoading(false)
          })
      }
      )
      .catch(error => {
        setLoading(false)
      })
  }, [match])

  const handleExport = () => {
    setExporting(true)
    dataService.getXlsx({ model: `stock_imports/${match.params.id}/items.xlsx`, params: `?pagination=off&select[0]=article_id&select[1]=serial_number` })
      .then(
        data => {
          const filename = `Import_${format(new Date(stockImport.created_at), 'yyyyMMddHHmmss')}.xlsx`
          setExporting(false)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof (error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Typography variant='h6'>
          Import
        </Typography>
      </Toolbar>
      <List className={classes.list}>
        <ListItem alignItems='flex-start'>
          <ListItemText
            primary={
              <React.Fragment>
                {stockImport.storage && (
                  <div className={classes.to}>
                    <ArrowForwardIcon />
                    <Typography variant='subtitle'>
                      {stockImport.storage}
                    </Typography>
                  </div>
                )}
                {stockImport.storage_location && (
                  <div className={classes.to}>
                    <ArrowForwardIcon />
                    <Typography variant='subtitle'>
                      {stockImport.storage_location}
                    </Typography>
                  </div>
                )}
                {stockImport.status && (
                  <div className={classes.to}>
                    <ArrowForwardIcon />
                    <Typography variant='subtitle'>
                      {stockImport.status}
                    </Typography>
                  </div>
                )}
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography variant='body2'>
                  {stockImport.created_at && (
                    `Skapades av ${stockImport.user && stockImport.user.name} ${format(new Date(stockImport.created_at), 'd LLLL yyyy, HH:mm:ss', { locale: sv })}`
                  )}
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemAvatar>
            <StatusAvatar status={stockImport.update_status} />
          </ListItemAvatar>
        </ListItem>
        {stockImport.description && (
          <ListItem>
            <ListItemText
              primary={'Felbeskrivning'}
              secondary={stockImport.description}
            />
          </ListItem>
        )}
        {stockImport.image_url && (
          <ListItem>
            <ListItemText
              primary={'Bild'}
              secondary={
                <img className={classes.image} src={stockImport.image_url} alt='Bild' />
              }
            />
          </ListItem>
        )}
      </List>
      <div className={classes.wrapper}>
        <Paper>
          <Toolbar className={classes.toolbar}>
            <Typography
              variant='h6'
            >
              {stockImport && stockImport.total_items}{' '}Individer
            </Typography>
            <Tooltip
              title='Exportera'
            >
              <IconButton disabled={exporting} onClick={handleExport}>
                {exporting ? (
                  <CircularProgress size={24} />
                ) : (
                  <ExportFileIcon />
                )}
              </IconButton>
            </Tooltip>
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Artikel
                </TableCell>
                <TableCell>
                  Serienummer
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? (
                items.length > 0 && items.map(item => (
                  <TableRow
                    key={item.id}
                  >
                    <TableCell>
                      {item.article}
                    </TableCell>
                    <TableCell>
                      {item.serial_number}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                [...Array(20)].map((e, i) => (
                  <TableRow key={i.toString()}>
                    {[...Array(2)].map((c, j) => (
                      <TableCell key={j.toString()}>
                        <Skeleton variant='rect' className={classes.skeleton} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Paper>
  )
}