import React, {useState} from 'react'
import ReactSelect from './ReactSelect'
import { dataService } from '../../services'

const debounce = (f, ms) => {
  let timer = null

  return (...args) => {
    const onComplete = () => {
      f.apply(this, args)
      timer = null
    }

    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(onComplete, ms)
  }
}

export default ({
  settings: {
    value,
    label,
    model
  },
  ...props
}) => {
 
  const [options, setOptions] = useState([])
  const getOptions = e => e.length > 3 ? (debounce(e => {
    dataService.getData({ model, params: `?pagination=off&q=${e}` })
      .then(
        data => {
          const { result } = data
          const options = result.map(option => ({
            value: option[value],
            label: option[label]
          }))
          setOptions(options)
        }
      )
  }, 1000))(e) : undefined

  return (
    <ReactSelect
      {...props}
      options={options}
      onInputChange={e => getOptions(e)}
    />
  )
}