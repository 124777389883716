import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'

// This file is being used in Drawer.js as "CheckboxBoolean". 

export default props => {
  const div_style = {
    margin: '10px 12px'
  }

  return (
    <div style={div_style}>
      <FormLabel component="legend">{props.label}</FormLabel>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox
            checked={props.checked === "1"}
            onClick={() => props.onChangeValue("1")}
            color={props.color}
          />}
          label={props.filter ? props.filter.labelTrue : 'Ja'}
        />

        <FormControlLabel
          control={<Checkbox
            checked={props.checked === "0"}
            onClick={() => props.onChangeValue("0")}
            color={props.color}
          />}
          label={props.filter ? props.filter.labelFalse : 'Nej'}
        />
      </FormGroup>
    </div>
  )
}
