import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'

import { dataService } from '../../services'

import Table from '../Table'
import StockStatus from './StockStatus'

const cols = [
  {
    id: 13,
    key: 'article_number',
    visible: true,
    edit: true,
    create: true,
    read: true,
    label: 'Artikelnummer',
    type: 'text',
  },
  {
    id: 1,
    key: 'article',
    visible: true,
    sortable: true,
    filterable: true,
    read: true,
    human_key: 'article',
    label: 'Artikel',
    type: 'select'
  },
  {
    id: 2,
    key: 'serial_number',
    visible: true,
    sortable: true,
    filterable: true,
    read: true,
    label: 'Serienummer',
    type: 'text'
  },
  {
    id: 3,
    key: 'production_date',
    visible: true,
    sortable: true,
    filterable: true,
    read: true,
    label: 'Produktionsdatum',
    type: 'date'
  },
  {
    id: 4,
    key: 'storage',
    human_key: 'storage',
    visible: true,
    sortable: true,
    filterable: true,
    read: true,
    label: 'Lager',
    type: 'select'
  },
  {
    id: 5,
    key: 'storage_location',
    human_key: 'storage_location',
    visible: true,
    sortable: true,
    filterable: true,
    read: true,
    label: 'Lagerplats',
    type: 'select'
  },
  {
    id: 6,
    key: 'status',
    human_key: 'status',
    visible: true,
    sortable: true,
    filterable: true,
    read: true,
    label: 'Status',
    type: 'select'
  },
  {
    id: 7,
    key: 'pallet',
    visible: true,
    sortable: true,
    filterable: true,
    read: true,
    label: 'Pall',
    type: 'text'
  },
  {
    id: 8,
    key: 'box',
    visible: true,
    sortable: true,
    filterable: true,
    read: true,
    label: 'Låda',
    type: 'text'
  },
]

export default ({
  match,
  history,
  location
}) => {
  useEffect(() => {
    dataService.getData({ model: 'articles', params: '?filter[0][key]=selectable&filter[0][value]=1&select[0]=id&select[1]=label&select[2]=article_number&pagination=off' })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.article_number + ': ' + option.label })
        })
        setArticles(options)
        cols.find(col => col.id === 1).options = options
        dataService.getData({ model: 'storages', params: '?pagination=off' })
          .then(data => {
            const { result } = data
            const options = []
            result.forEach(option => {
              options.push({ value: option.id, label: option.label })
            })
            setStorages(options)
            cols.find(col => col.id === 4).options = options
            dataService.getData({ model: 'statuses', params: '?pagination=off' })
              .then(data => {
                const { result } = data
                const options = []
                result.forEach(option => {
                  options.push({ value: option.id, label: option.label })
                })
                cols.find(col => col.id === 6).options = options
                dataService.getData({ model: 'storage_locations', params: '?pagination=off' })
                  .then(data => {
                    const { result } = data
                    const options = []
                    result.forEach(option => {
                      options.push({ value: option.id, label: option.label })
                    })
                    cols.find(col => col.id === 5).options = options
                    setEnhanced(cols)
                  })
              })
          })
      })
  }, [])

  const [enhanced, setEnhanced] = useState(cols)
  const [articles, setArticles] = useState([])
  const [storages, setStorages] = useState([])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <StockStatus
          match={match}
          location={location}
          history={history}
          articles={articles}
          storages={storages}
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          match={match}
          history={history}
          location={location}
          cols={enhanced}
          title='Individer'
          singular='Individ'
          model='items'
          activities={'item_activities'}
        />
      </Grid>
    </Grid>
  )
}
// const useStyles = makeStyles(theme => ({
//   toolbar: {
//     display: 'flex',
//     justifyContent: 'space-between'
//   },
//   circle: {
//     display: 'inline-flex'
//   }
// }))

// export default ({
//   query,
//   location,
//   reRoute
// }) => {
//   const classes = useStyles()
//   const [items, setItems] = useState([]) 
//   const [total, setTotal] = useState(0)
//   const [loading, setLoading] = useState(false)
//   useEffect(() => {
//     setLoading(true)
//     const queryParams = queryString.parse(location.search)
//     let params = ''
//     if (Boolean(Object.keys(queryParams).length)) {
//       Object.keys(queryParams).forEach((key, i) => {
//         params += i === 0 ? '?' : '&'
//         params += key === 'page' || key === 'per_page' ? `${key}=${queryParams[key]}` : `filter[${i}][key]=${key}&filter[${i}][value]=${queryParams[key]}`
//       })
//     }
//     dataService.getData({ model: 'items', params})
//       .then(
//         data => {
//           const { total, result } = data
//           setTotal(total)
//           setItems(result)
//           setLoading(false)
//         }
//       )
//   }, [location.search])

//   const handleChangePage = (e, newPage) => {
//     const sortQuery = {...query, page: newPage + 1}
//     reRoute(sortQuery)
//   }

//   const handleChangeRowsPerPage = e => {
//     const sortQuery = { ...query, per_page: e.target.value }
//     reRoute(sortQuery)
//   }

//   const handleExport() e => {}

//   return (
//     <React.Fragment>
//       <Toolbar className={classes.toolbar}>
//         <Typography variant='h6'>
//           {Boolean(total) ? `${total} individer` : <Skeleton height={20} width={150} variant='rect' />}
//         </Typography>
//         {!loading ? (
//           <Tooltip title='Exportera'>
//             <IconButton
//               onClick={() => handleExport()}
//             >
//               <ExportFileIcon />
//             </IconButton>
//           </Tooltip>
//         ) : (
//           <Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} />
//         )}
//       </Toolbar>
//       <Table>
//         <TableHead>
//           <TableRow>
//             {cols.map(col => (
//               <TableCell key={col.id}>
//                 {col.label}
//               </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {Boolean(items.length > 0) ? (
//             items.map(item => (
//               <TableRow key={item.id}>
//                 {cols.map(col => (
//                   <TableCell key={col.id}>
//                     {!loading ? (
//                       item[col.human_key || col.key]
//                     ) : (
//                       <Skeleton variant='rect' />
//                     )}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))
//           ) : (
//             [...Array(query.per_page || 20)].map((e, i) => (
//               <TableRow key={i}>
//                 {cols.map(col => (
//                   <TableCell key={col.id}>
//                     <Skeleton variant='rect' />
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))
//           )}
//         </TableBody>
//       </Table>
//       <TablePagination
//         rowsPerPageOptions={[20, 50, 100]}
//         labelRowsPerPage='Rader per sida'
//         component='div'
//         count={total}
//         labelDisplayedRows={({from , to, count}) => `${from}-${to} av ${count}`}
//         rowsPerPage={query.per_page ? query.per_page : 20}
//         page={query.page ? query.page - 1 : 0 }
//         backIconButtonProps={{
//           'aria-label': 'previous page',
//         }}
//         nextIconButtonProps={{
//           'aria-label': 'next page',
//         }}
//         onChangePage={handleChangePage}
//         onChangeRowsPerPage={handleChangeRowsPerPage}
//       />
//     </React.Fragment>
//   )
// }
