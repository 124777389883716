import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'

import { makeStyles, lighten, withStyles } from '@material-ui/core/styles'
import { Paper, Toolbar, Typography, Fab, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableBody, TableCell, Avatar, IconButton, CircularProgress, Tooltip, Drawer, TablePagination, TableSortLabel, FormControl, FormLabel, FormGroup, Checkbox, FormControlLabel, Badge, Snackbar } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import LinearProgress from '@material-ui/core/LinearProgress'
import ViewIcon from '@material-ui/icons/RemoveRedEye'
import FilterIcon from '@material-ui/icons/FilterList'
import ClearIcon from '@material-ui/icons/Clear'

import { dataService } from '../../../services'
import checkPermissions from '../../../helpers/checkPermissions'
import { Formik, Form, Field } from 'formik'
import CheckboxField from '../../fields/CheckboxField'
import ExportFileIcon from '../../../icons/ExportFileIcon'
import { primary, success } from '../../colors'
import TextField from '../../fields/TextField'
import SelectedFilters from '../../SelectedFilters'
import Skeleton from '@material-ui/lab/Skeleton'

const BorderLinearProgress = withStyles({
  root: {
    height: 15,
    width: '100%',
    backgroundColor: lighten(primary, 0.95),
    borderRadius: 5
  },
  bar: {
    // borderRadius: 20,
    backgroundColor: primary,
  },
})(LinearProgress);

const BorderLinearSucceed = withStyles({
  root: {
    height: 15,
    width: '100%',
    backgroundColor: success,
    borderRadius: 5,
    border: `1px solid ${lighten(success, 0.8)}`
  },
  bar: {
    // borderRadius: 20,
    backgroundColor: success,
  },
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: 70
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    textDecoration: 'none'
  },
  fabIcon: {
    marginRight: 10
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  },
  tableRow: {
    textDecoration: 'none'
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  drawer: {
    width: 500,
    maxWidth: '80%',
    borderRadius: theme.shape.borderRadius,
    height: 'calc(100% - 40px)',
    margin: 20
  },
  form: {
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  wrapper: {
    width: '100%',
    padding: 24,
    height: '100%'
  },
  action: {
    marginTop: 'auto'
  },
  submit: {
    marginTop: 15
  },
  // form: {
  //   overflowY: 'auto',
  //   display: 'flex',
  //   flexDirection: 'column'
  // },
  circle: {
    display: 'inline-flex'
  },
  skeleton: {
    margin: '2px 0 1px'
  }
}))

export default ({
  match,
  history,
  location
}) => {

  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()
  const cols = [{
    key: 'created_at',
    label: 'Datum'
  }]
  const params = queryString.parse(location.search)
  const usedFilters = {}
  const selectedFilterValues = {}

  Object.keys(params).forEach(q => {
    if (q.includes('[key') && !q.includes('[1000]')) {
      const key = q.split('between[').pop().split('][key]')[0]
      const value = []
      let string = ''
      JSON.parse(params[`between[${key}][value]`]).forEach((date, index) => {
        if (index > 0) {
          string = `${string} - `
        }
        if (date === '0000-00-00' || date === '3000-01-01') {
          value.push(null)
          string = `${string}...`
        } else {
          value.push(date)
          string = `${string}${date}`
        }
      })
      selectedFilterValues[params[q]] = string
      usedFilters[params[q]] = value
    }
  })

  const [exporting, setExporting] = useState(false)
  const [createActive, setCreateActive] = useState(false)
  const [storages, setStorages] = useState([])
  const [initialValues, setInitialValues] = useState({})
  const [groups, setGroups] = useState({ result: [], total: 0 })
  const [filterOpen, setFilterOpen] = useState(false)
  const [query, setQuery] = useState(params)
  const [filters, setFilters] = useState(usedFilters)
  const [loading, setLoading] = useState(false)
  const [snackbarItem, setSnackbarItem] = useState({})

  useEffect(() => {
    dataService.getData({ model: 'storages', params: '?pagination=off' })
      .then(
        data => {
          const { result } = data
          setStorages(result)
          const ibjext = {}
          result.forEach(storage => {
            ibjext[storage.id] = false
          })
          setInitialValues(ibjext)
        }
      )
  }, [])

  useEffect(() => {
    setLoading(true)
    dataService.getData({ model: 'stocktaking_groups', params: `?${queryString.stringify(params)}` })
      .then(data => {
        setGroups(data)
        setQuery(params)
        setFilters(usedFilters)
        setLoading(false)
      })
  }, [location.search])

  const handleExport = (id, created_at) => {
    setExporting(id)
    dataService.getXlsx({ model: 'stocktakings_summary.xlsx', params: `?pagination=off&group_id=${id}` })
      .then(
        data => {
          const filename = `Gruppinventering_${format(new Date(created_at), 'yyyyMMddHHmmss')}.xlsx`
          setExporting(false)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof (error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }

  const handleFilter = values => {
    setFilterOpen(false)
    let search = ''
    if (values.created_at_after || values.created_at_before) {
      search = `between[0][key]=created_at&between[0][value]=["${values.created_at_after || '0000-00-00'}", "${values.created_at_before || '3000-01-01'}"]`
    }
    return history.push({
      pathname: match.url,
      search
    })
  }

  const handleRemoveFilter = filter => {
    const keyValue = Object.keys(query).find(key => query[key] === filter)
    const valueKey = keyValue.replace('key', 'value')
    const filterQuery = { ...query }
    delete filterQuery[keyValue]
    delete filterQuery[valueKey]
    reRoute(filterQuery)
  }

  const handleClearFilter = () => {
    setFilterOpen(false)
    const allFilters = Object.keys(query).filter(key => key.includes('[key]') || key.includes('[value]'))
    const filterQuery = { ...query }
    allFilters.forEach(filter => {
      delete filterQuery[filter]
    })
    reRoute(filterQuery)
  }

  const handleSort = property => {
    const direction = query['order_by'] === property && query.direction !== 'asc' ? 'asc' : 'desc'
    const sortQuery = { ...query, order_by: property, direction, page: 1 }
    reRoute(sortQuery)
  }

  const handleChangePage = (event, newPage) => {
    const sortQuery = { ...query, page: newPage + 1 }
    reRoute(sortQuery)
  }

  const handleChangeRowsPerPage = event => {
    const sortQuery = { ...query, per_page: event.target.value }
    reRoute(sortQuery)
  }

  const reRoute = q => {
    const search = `?${queryString.stringify(q, { encode: false })}`
    return history.push({
      pathname: match.url,
      search
    })
  }

  const handleSelectAll = (event, setFieldValue) => {
    storages.forEach(storage => {
      setFieldValue(storage.id, event.target.checked)
    })
  }

  const handleDelete = group => {
    dataService.removeData({ model: `stocktaking_groups/${group.id}`, params: '' })
      .then(
        () => {
          setSnackbarItem(group)
          setGroups(prev => {
            return {
              ...prev,
              total: prev.total - 1,
              result: prev.result.filter(({ id }) => id !== group.id)
            }
          })
        },
        () => {
          // Handle error
        }
      )
  }

  const handleUndo = () => {
    const item = snackbarItem
    dataService.editData({ model: `stocktaking_groups/${item.id}`, values: { deleted_at: null } })
      .then(
        () => {
          setSnackbarItem({})
          setGroups(prev => {
            const result = [...prev.result]
            result.splice(item.index, 0, item)
            return {
              ...prev,
              total: prev.total + 1,
              result,
            }
          })
        },
        () => {
          // Handle error
        }
      )
  }

  const { result, total } = groups

  return (
    <React.Fragment>
      {Object.keys(filters).length > 0 && (
        <SelectedFilters filters={selectedFilterValues} cols={cols} onDelete={filter => handleRemoveFilter(filter)} />
      )}
      <Paper
        className={classes.paper}
      >
        <Toolbar className={classes.toolbar}>
          <React.Fragment>
            <Typography variant='h6'>
              Gruppinventeringar
            </Typography>
            <div>
              <Tooltip title='Filtrera' aria-label='filter'>
                <IconButton onClick={() => setFilterOpen(true)}>
                  {Object.keys(filters).length > 0 ? (
                    <Badge variant='dot' color='primary'>
                      <FilterIcon />
                    </Badge>
                  ) : (
                    <FilterIcon />
                  )}
                </IconButton>
              </Tooltip>
            </div>
          </React.Fragment>
        </Toolbar>
        <Table
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={query['order_by'] === 'created_at'}
                  direction={query['direction'] || 'desc'}
                  onClick={() => handleSort('created_at')}
                >
                  Datum
                </TableSortLabel>
              </TableCell>
              <TableCell>
                Lager
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result.length > 0 ? (
              result.map((group, index) => (
                <TableRow
                  key={group.id}
                  className={classes.tableRow}
                >
                  <TableCell>
                    {!loading ? (
                      format(new Date(group.created_at), 'd LLLL yyyy, HH:mm:ss', { locale: sv })
                    ) : (
                      <Skeleton variant='rect' className={classes.skeleton} />
                    )}
                  </TableCell>
                  <TableCell>
                    {!loading ? (
                      (!group.storage_labels || !group.storage_labels.length > 0)
                        ? ''
                        : `${group.storage_labels.slice(0, 3).join(', ')}${group.storage_labels.length > 3 ? ' m.fl.' : ''}`
                    ) : (
                      <Skeleton variant='rect' className={classes.skeleton} />
                    )}
                  </TableCell>
                  <TableCell>
                    <div className={classes.status}>
                      {!loading ? (
                        <React.Fragment>
                          {(group.total_stocktakings - group.active_stocktakings) / group.total_stocktakings === 1 ? (
                            <BorderLinearSucceed
                              variant='determinate'
                              color='secondary'
                              value={100}
                            />
                          ) : (
                            <BorderLinearProgress
                              variant='determinate'
                              color='secondary'
                              value={((group.total_stocktakings - group.active_stocktakings) / group.total_stocktakings) * 100}
                            />
                          )}
                          <Typography variant='caption'>
                            {`${group.total_stocktakings - group.active_stocktakings} av ${group.total_stocktakings}`}
                          </Typography>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <BorderLinearProgress
                            variant='determinate'
                            color='secondary'
                            value={0}
                          />
                          <Skeleton variant='rect' className={classes.skeleton} width={30} height={10} />
                        </React.Fragment>
                      )}
                    </div>
                  </TableCell>
                  <TableCell align='right'>
                    {!loading ? (
                      <React.Fragment>
                        <Tooltip title='Exportera' aria-label='export'>
                          <IconButton disabled={exporting} onClick={() => handleExport(group.id, group.created_at)}>
                            {
                              exporting === group.id ? (
                                <CircularProgress size={24} />
                              ) : (
                                <ExportFileIcon />
                              )
                            }
                          </IconButton>
                        </Tooltip>
                        <Link
                          to={`${match.url}/${group.id}`}
                        >
                          <Tooltip title='Visa' aria-label='read'>
                            <IconButton>
                              <ViewIcon />
                            </IconButton>
                          </Tooltip>
                        </Link>
                        {checkPermissions({ model: 'stocktaking_groups', type: 'remove' }) && (
                          <Tooltip title='Ta bort' aria-label='delete'>
                            <IconButton onClick={() => handleDelete({ ...group, index })}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Skeleton variant='circle' width={43} height={43} className={classes.circle} style={{ marginRight: 10 }} />
                        <Skeleton variant='circle' width={43} height={43} className={classes.circle} />
                      </React.Fragment>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <React.Fragment>
                {!loading ? (
                  <TableRow />
                ) : (
                  [...Array(20)].map((e, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Skeleton variant='rect' className={classes.skeleton} />
                      </TableCell>
                      <TableCell>
                        <div className={classes.status}>
                          <BorderLinearProgress
                            variant='determinate'
                            color='secondary'
                            value={0}
                          />
                          <Skeleton variant='rect' className={classes.skeleton} width={30} height={10} />
                        </div>
                      </TableCell>
                      <TableCell align='right'>
                        <Skeleton variant='circle' width={43} height={43} className={classes.circle} style={{ marginRight: 10 }} />
                        <Skeleton variant='circle' width={43} height={43} className={classes.circle} />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
        <TablePagination
          // rowsPerPageOptions={[20, 50, { value: total, label: 'Visa alla' }]}
          rowsPerPageOptions={[20, 50, 200, 500]}
          labelRowsPerPage='Rader per sida'
          component='div'
          count={total}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} av ${count}`}
          rowsPerPage={query.per_page ? query.per_page : 20}
          page={query.page ? query.page - 1 : 0}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        open={createActive}
        onClose={() => setCreateActive(false)}
        maxWidth='sm'
        fullWidth={true}
        scroll='paper'
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, { setSubmitting, setStatus }) => {
            const data = Object.keys(values).filter(key => values[key])
            const test = []
            data.forEach(d => { test.push(parseInt(d, 10)) })
            dataService.createData({ values: { storages: test }, model: 'stocktaking_groups' })
              .then(data => {
                const { id } = data
                history.push(`${match.url}/${id}`)
              })
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            submitForm
          }) => (
            <Form
              className={classes.form}
            >
              <DialogTitle>Välj lager att inventera</DialogTitle>
              <DialogContent dividers>
                <FormControl component='fieldset'>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          indeterminate={Object.keys(values).some(key => values[key]) && Object.keys(values).some(key => !values[key])}
                          color='primary'
                          checked={!Object.keys(values).some(key => !values[key])}
                          onChange={event => handleSelectAll(event, setFieldValue)}
                        />
                      }
                    />
                    {storages.map(storage => {
                      return (
                        <Field
                          name={storage.id}
                          type='checkbox'
                          checked={values[storage.id]}
                          value={values[storage.id]}
                          handleToggle={event => { setFieldValue(storage.id, event.target.checked) }}
                          label={storage.label}
                          component={CheckboxField}
                          color='primary'
                        />
                      )
                    })}
                  </FormGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color='secondary'
                  onClick={() => setCreateActive(false)}
                >
                  Avbryt
                </Button>
                <Button
                  color='primary'
                  type='submit'
                >
                  Gå vidare
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Drawer
        classes={{
          paper: classes.drawer
        }}
        anchor='right'
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant='h6'>
            Filtrera
          </Typography>
          <IconButton onClick={() => setFilterOpen(false)}>
            <ClearIcon />
          </IconButton>
        </Toolbar>
        <div className={classes.wrapper}>
          <Formik
            initialValues={{
              created_at_after: filters['created_at'] && filters['created_at'][0],
              created_at_before: filters['created_at'] && filters['created_at'][1]
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => handleFilter(values)}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              setFieldTouched
            }) => (
              <Form className={classes.form}>
                <Field
                  variant='outlined'
                  type='date'
                  margin='normal'
                  name='created_at_after'
                  fullWidth
                  component={TextField}
                  label='Efter datum'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <Field
                  variant='outlined'
                  type='date'
                  margin='normal'
                  name='created_at_before'
                  fullWidth
                  component={TextField}
                  label='Före datum'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <div className={classes.action}>
                  <Button
                    size='large'
                    fullWidth
                    variant='outlined'
                    disabled={isSubmitting}
                    color='primary'
                    onClick={() => handleClearFilter()}
                  >
                    Rensa filter
                  </Button>
                  <Button
                    className={classes.submit}
                    type='submit'
                    size='large'
                    fullWidth
                    variant='contained'
                    disabled={isSubmitting}
                    color='primary'
                  >
                    Filtrera
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackbarItem.id}
        autohideDuration={5000}
        onClose={() => setSnackbarItem({})}
        message={'Gruppinventering har tagits bort'}
        action={
          <React.Fragment>
            <Button color='primary' size='small' onClick={handleUndo} noMargin>
              Ångra
            </Button>
            <IconButton size='small' aria-label='close' color='inherit' onClick={() => setSnackbarItem({})}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </React.Fragment>
        }
      />
      <Fab
        variant='extended'
        aria-label='submit'
        color='primary'
        className={classes.fab}
        onClick={() => setCreateActive(true)}
      >
        <AddIcon className={classes.fabIcon} />
        Gruppinventering
      </Fab>
    </React.Fragment>
  )
}