import React from 'react'
import { Route, Link, Switch } from 'react-router-dom'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import ListIcon from '@material-ui/icons/List'
import BarcodeOffIcon from './icons/BarcodeOffIcon'
import BarcodeFilter from './components/System/BarcodeFilter'
import WomLogs from './components/System/WomLogs'
import checkPermissions from './helpers/checkPermissions'

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    padding: theme.spacing(1),
    marginBottom: 24,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  },
}));

export default ({
  match,
  location
}) => {
  const classes = useStyles()
  const handlesWom = checkPermissions({ model: 'wom_logs', type: 'write' })
  const handlesFilters = checkPermissions({ model: 'scanner_filters', type: 'write' })

  return (
    <React.Fragment>
      <div className={classes.demo2}>
        <StyledTabs value= {`/${location.pathname.split(`${match.url}/`).pop().split('/').shift()}`}>
          {handlesWom && (
            <StyledTab
              label='WOM Logg'
              icon={<ListIcon />}
              value={'/'}
              component={Link}
              to={match.url}
            />
          )}
          {handlesFilters && (
            <StyledTab
              label='Streckkodsfilter'
              icon={<BarcodeOffIcon />}
              value={handlesWom
                ? '/filters'
                : '/'
              }
              component={Link}
              to={handlesWom
                ? `${match.url}/filters`
                : match.url
              }
            />
          )}
        </StyledTabs>
      </div>
      <Switch>
        {handlesFilters && (
          <Route
            path={handlesWom
              ? `${match.url}/filters`
              : match.url
            }
            render={props => <BarcodeFilter {...props} />}
          />
        )}
        {handlesWom && (
          <Route
            path={match.url}
            render={props => <WomLogs {...props} />}
          />
        )}
      </Switch>
    </React.Fragment>
  )
}