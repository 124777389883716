import React from 'react'

import Table from './components/Table'

const cols = [
  {
    id: 1,
    key: 'id',
    label: 'Id',
    visible: true,
    sortable: true,
    filterable: false,
    edit: false,
    create: false,
    read: true,
    type: 'text'
  },
  {
    id: 2,
    key: 'label',
    label: 'Titel',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'text'
  },
  {
    id: 3,
    key: 'lock',
    label: 'Varna vid ändring',
    default: false,
    visible: false,
    sortable: false,
    filterable: false,
    edit: true,
    create: true,
    read: true,
    type: 'boolean'
  }
]

export default ({
  match,
  history,
  location
}) => {
  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={cols}
      title='Statusar'
      singular='Status'
      model='statuses'
    />
  )
}
