import React,{useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ExpandMore from '@material-ui/icons/ExpandMore'

import { dataService } from '../services'



export default ({history, match, location}) => {
  const [open, setOpen] = React.useState(false)
  const [user, setUser] = useState(0)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState(0)
  const anchorRef = React.useRef(null)

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
        getData()
      }, [])

  const setOrganization = event => {
    const selected_organization_id = event.target.value
    dataService.editData({ model: `/users/${user}`, values: { selected_organization_id }})
      .then(data => {
        getData()
        setOpen(false)
        const { pathname } = location
        return history.push({
          pathname,
          search: `?so=${selected_organization_id}`
        })
      })
  }

  const getData = () => {
    dataService.getData({ model: '/me/organizations', params: ''})
      .then(data => {
        const { organizations, selected_organization_id, user_id } = data
        setUser(user_id)
        setOptions(organizations)
        setSelected(selected_organization_id)
      })
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  return (
    <React.Fragment>
      {options && options.length > 1 && (
        <React.Fragment>
          <Button
            color='primary'
            variant='text'
            onClick={handleToggle}
            ref={anchorRef}
            endIcon={<ExpandMore />}
          >
            {Boolean(options.find(option => option.id === selected)) && options.find(option => option.id === selected).label}
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper id="menu-list-grow">
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          selected={option.id === selected}
                          onClick={setOrganization}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}


// import React, { useEffect, useState } from 'react'
// import Select from '@material-ui/core/Select'
// import MenuItem from '@material-ui/core/MenuItem'
// import FormControl from '@material-ui/core/FormControl'
// import InputLabel from '@material-ui/core/InputLabel'
// import { makeStyles } from '@material-ui/core/styles'

// import { dataService } from '../services'

// const useStyles = makeStyles(theme => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 180,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }))

// export default (() => {
//   const classes = useStyles()
//   const [user, setUser] = useState(0)
//   const [options, setOptions] = useState([])
//   const [selected, setSelected] = useState(0)
  
//   useEffect(() => {
//     getData()
//   }, [])

//   const inputLabel = React.useRef(null);
//   const [labelWidth, setLabelWidth] = React.useState(0);
//   React.useEffect(() => {
//     setLabelWidth(inputLabel.current.offsetWidth);
//   }, []);

//   const setOrganization = event => {
//     const selected_organization_id = event.target.value
//     dataService.editData({ model: `/users/${user}`, values: { selected_organization_id }})
//       .then(data => {
//         getData()
//       })
//   }

//   const getData = () => {
//     dataService.getData({ model: '/me/organizations', params: ''})
//       .then(data => {
//         const { organizations, selected_organization_id, user_id } = data
//         setUser(user_id)
//         setOptions(organizations)
//         setSelected(selected_organization_id)
//       })
//   }

//   return (
//     <FormControl variant='outlined' className={classes.formControl}>
//       <InputLabel id='demo-simple-select-outlined-label' ref={inputLabel}>Välj organisation</InputLabel>
//       <Select
//         labelId="demo-simple-select-outlined-label"
//         id="demo-simple-select-outlined"
//         value={selected}
//         onChange={setOrganization}
//         labelWidth={labelWidth}
//       >
//         {options.map(organization => (
//           <MenuItem value={organization.id} key={organization.id}>{organization.label}</MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   )
// })