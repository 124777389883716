import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import LatestImports from './LatestImports'


import AllImports from './AllImports'
import ViewImports from './ViewImports'
import CreateImport from './CreateImport'


export default ({
  match,
  location
}) => {
  const [refresh, setRefresh] = useState(Date) 

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{alignItems: 'flex-start'}}>
        <Grid item xs={12} md={6} lg={8}>
          <Switch>
            <Route
              exact
              path={`${match.url}/imports`}
              render={props => <AllImports {...props} />}
            />
            <Route
              exact
              path={`${match.url}/imports/:id`}
              render={props => <ViewImports {...props} />}
            />
            <Route
              path={match.url}
              render={props => <CreateImport {...props} setRefresh={() => setRefresh(Date)}/>}
            />
          </Switch>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <LatestImports refresh={refresh} match={match}/>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}