import React, { useEffect, useState } from 'react'
import { Form, Formik, Field } from 'formik'
import { useSnackbar } from 'notistack'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import { DialogContentText, DialogTitle } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import TextField from './fields/TextField'
import CheckboxField from './fields/CheckboxField'
import CheckboxBoolean from './fields/CheckboxBoolean'
import AsyncSelect from './fields/AsyncSelect'
import ReactSelect from './fields/ReactSelect'
import Button from './Button'
import { dataService } from '../services'
import SliderField from './fields/SliderField'
import ItemActivities from './Items/ItemActivities'
import BundleJoin from './Items/BundleJoin'

const TabPanel = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && children}
  </div>
)

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    minWidth: 'auto',
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />)

const useFilterStyles = makeStyles(theme => ({
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  action: {
    marginTop: 'auto'
  },
  submit: {
    marginTop: 15
  }
}))

const Multichange = ({
  cols,
  model,
  onClose,
  onMultiChange,
  items,
  callback
}) => {
  const classes = useEditStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [warning, setWarning] = useState('')
  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        const multiData = {
          values: items.map(item => {
            const tmp = typeof item === 'object' && item !== null ? item : { id: item }
            Object.keys(values).map(key => key !== 'ignore_lock' && (tmp[key] = values[key]))
            return tmp
          })
        }
        const testModel = values.ignore_lock ? `${model}?ignore_lock=true` : model

        try {
          const data = await dataService.editData({ model: testModel, values: multiData.values })
          setSubmitting(false)
          onMultiChange(data)
          onClose()
        } catch (error) {
          setSubmitting(false)
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type || err, { variant: 'error' })
            })
          } else if (error.status === 423) {
            setWarning(error.error)
          } else {
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => (
        <Form>
          {cols.map(col => col.multichange && (
            <React.Fragment>
              {(() => {
                switch (col.type) {
                  case 'array':
                    return (
                      <ReactSelect
                        name={col.key}
                        options={col.options}
                        placeholder={`Välj ${col.label}`}
                        label={col.label}
                        onChange={option => {
                          setFieldValue(col.key, option && option.length > 0 ? option.map(obj => obj) : [])
                        }}
                        onBlur={() => setFieldTouched(col.key, true)}
                        isMulti
                        errors={errors}
                        touched={touched}
                      // value={values[col.key]}
                      />
                    )
                  case 'select':
                    return (
                      <ReactSelect
                        name={col.key}
                        options={col.options}
                        placeholder={`Välj ${col.label}`}
                        label={col.label}
                        onChange={col.trigger ? (option => {
                          callback(option ? option.value : null)
                          setFieldValue(col.key, option ? option.value : null)
                        }) : (option => {
                          setFieldValue(col.key, option ? option.value : null)
                        })}
                        onBlur={() => setFieldTouched(col.key, true)}
                        isDisabled={(col.depends_on && !values[col.depends_on]) || (col.options && !col.options.length)}
                        errors={errors}
                        touched={touched}
                      />
                    )
                  case 'boolean':
                    return (
                      <Field
                        name={col.key}
                        type='checkbox'
                        value={values[col.key]}
                        checked={values[col.key]}
                        handleToggle={event => { setFieldValue(col.key, event.target.checked) }}
                        label={col.label}
                        component={CheckboxField}
                        color='primary'
                      />
                    )
                  case 'slider':
                    return (
                      <Field
                        name={col.key}
                        type='slider'
                        value={values[col.key]}
                        onChangeCommitted={(event, value) => { setFieldValue(col.key, value) }}
                        label={col.label}
                        component={SliderField}
                        valueLabelDisplay="auto"
                        step={1}
                        marks={col.marks}
                        min={1}
                        max={12}
                      />
                    )
                  default:
                    return (
                      <Field
                        variant='outlined'
                        margin='normal'
                        multiline={col.multiline}
                        type={col.type || 'text'}
                        name={col.key}
                        fullWidth
                        component={TextField}
                        label={col.label}
                      />
                    )
                }
              })()}
            </React.Fragment>
          ))}
          <Dialog
            open={Boolean(warning)}
            onClose={() => setWarning('')}
            fullWidth
            maxWidth={'sm'}
            aria-labelledby='warning-title'
            aria-describedby='warning-description'
          >
            <DialogTitle
              id='warning-title'
              disableTypography={true}
              classes={{
                root: classes.root
              }}
            >
              <WarningIcon color='error' style={{ marginRight: 10 }} />
              <Typography variant='h6' component='h2'>{warning}</Typography>
            </DialogTitle>
            <DialogContent
            // classes={{
            //   root: classes.dialogRoot
            // }}
            >
              <DialogContentText
                id='warning-description'
              >
                <Field
                  name='ignore_lock'
                  type='checkbox'
                  value={values['ignore_lock']}
                  checked={values['ignore_lock']}
                  handleToggle={event => { setFieldValue('ignore_lock', event.target.checked) }}
                  label='Ignorera lås'
                  component={CheckboxField}
                  color='primary'
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setWarning('')}
                style={{ margin: 0 }}
                color='secondary'
              >
                Avbryt
              </Button>
              <Button
                onClick={() => submitForm()}
                color='primary'
                disabled={!values['ignore_lock']}
                style={{ margin: 0 }}
              >
                Gå vidare
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            type='submit'
            size='large'
            fullWidth
            variant='contained'
            disabled={isSubmitting}
            loading={isSubmitting}
            color='primary'
          >
            Spara
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const getOptionValue = (option, col) => {
  if (Array.isArray(option)) {
    return option.map(option => option.value).join('|')
  }

  return option
    ? (col.filter && col.filter.key)
      ? option[col.filter.key]
      : option.value
    : null
}

const Filter = ({
  cols,
  model,
  filters,
  onFilter,
  onClearFilter,
  callback
}) => {
  const classes = useFilterStyles()
  const parsedFilters = Object.entries(filters).reduce((result, [key, value]) => {
    const col = cols.find(col => col.key === key)

    const parsedValue = (
      col &&
      !(col.filter && col.filter.type !== 'list') &&
      !(col.filter && col.filter.exact) &&
      ['text', 'datetime'].includes(col.type) &&
      value.includes('|')
    )
      ? value.replace(/\|+/g, ' ')
      : value

    return {
      ...result,
      [key]: parsedValue
    }
  }, {})

  const submitFilters = (values, { setSubmitting }) => {
    const parsedValues = Object.entries(values).reduce((result, [key, value]) => {
      const col = cols.find(col => col.key === key)

      const parsedValue = (
        col &&
        !(col.filter && col.filter.type !== 'list') &&
        !(col.filter && col.filter.exact) &&
        ['text', 'datetime'].includes(col.type) &&
        (/\s/g).test(value)
      )
        ? value.replace(/\s+/g, ' ').trim().split(' ')
        : value

      return {
        ...result,
        [key]: parsedValue
      }
    }, {})
    onFilter(parsedValues).then(() => {
      setSubmitting(false)
    })
  }

  return (
    <Formik
      initialValues={parsedFilters}
      enableReinitialize
      onSubmit={submitFilters}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => (
        <Form className={classes.form}>
          {cols.map(col => col.filterable && (
            <React.Fragment>
              {(() => {
                const type = (col.filter ? col.filter.type : null) || col.type
                switch (type) {
                  case 'select':
                    return (
                      <ReactSelect
                        name={col.nested ? col.nested.filterKey : col.key}
                        options={col.options}
                        placeholder={`Välj ${col.label}`}
                        label={col.label}
                        onChange={col.trigger
                          ? option => {
                            callback(getOptionValue(option, col))
                            setFieldValue(col.nested ? col.nested.filterKey : col.key, getOptionValue(option, col))
                          }
                          : option => {
                            setFieldValue(col.nested ? col.nested.filterKey : col.key, getOptionValue(option, col))
                          }
                        }
                        onBlur={() => setFieldTouched(col.nested ? col.nested.filterKey : col.key, true)}
                        errors={errors}
                        touched={touched}
                        value={
                          col.options && col.options.find(option => option.value === parseInt(values[col.nested ? col.nested.filterKey : col.key], 10)) ||
                          col.options && col.options.find(option => option.label === values[col.key])
                        }
                      />
                    )

                  case 'multiselect':
                    return (
                      <ReactSelect
                        name={col.nested ? col.nested.filterKey : col.key}
                        options={col.options}
                        isMulti
                        placeholder={`Välj ${col.label}`}
                        label={col.label}
                        onChange={option => {
                          if (col.trigger) {
                            callback(getOptionValue(option, cols))
                          }
                          setFieldValue(col.key, option && option.length > 0 ? option.map(obj => obj.value) : [])
                        }}
                        onBlur={() => setFieldTouched(col.nested ? col.nested.filterKey : col.key, true)}
                        errors={errors}
                        touched={touched}
                        value={col.options.filter(option => {
                          const splitValues = values[col.key] && (Array.isArray(values[col.key]) ? values[col.key] : String(values[col.key]).split('|')).map(v => Number(v))
                          return splitValues && splitValues.includes(option.value)
                        })}
                      />
                    )
                  case 'date':
                    return (
                      <Field
                        key={col.id}
                        variant='outlined'
                        margin='normal'
                        type={col.type || 'text'}
                        name={col.key}
                        fullWidth
                        component={TextField}
                        label={col.label}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )
                  case 'boolean':
                    return (
                      <Field
                        key={col.id}
                        name={col.key}
                        type='radio'
                        label={col.label}
                        value={values[col.key]}
                        checked={values[col.key]}
                        onChangeValue={value => {
                          setFieldValue(col.key, value === values[col.key] ? "" : value)
                        }}
                        filter={col.filter}
                        component={CheckboxBoolean}
                        color='primary'
                      />
                    )
                  default:
                    return (
                      <Field
                        key={col.id}
                        variant='outlined'
                        margin='normal'
                        type={col.type || 'text'}
                        name={col.nested ? col.nested.filterKey : col.key}
                        fullWidth
                        component={TextField}
                        label={col.label}
                      />
                    )
                }
              })()}
            </React.Fragment>
          ))}
          <div className={classes.action}>
            <Button
              size='large'
              fullWidth
              variant='outlined'
              disabled={isSubmitting}
              color='primary'
              onClick={onClearFilter}
            >
              Rensa filter
            </Button>
            <Button
              className={classes.submit}
              type='submit'
              size='large'
              fullWidth
              variant='contained'
              disabled={isSubmitting}
              color='primary'
            >
              Filtrera
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const View = ({
  cols,
  item,
  activities,
  relatedOrders
}) => {
  const [value, setValue] = useState(0)
  const [activeOrders, setActiveOrders] = useState([])

  useEffect(() => {
    if (Boolean(relatedOrders)) {
      dataService.getData({ model: `articles/${item[relatedOrders]}/orders`, params: '' })
        .then(orders => {
          const { result } = orders
          setActiveOrders(result)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  const tabProps = index => (
    {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    }
  )

  const handleChange = (e, newValue) => {
    setValue(newValue)
  }
  const renderColSecondary = (item, col) => {
    if (col.type === 'boolean')
      return (<Checkbox checked={item[col.key]} disabled />)
    let renderText = ''
    if (col.type === 'array')
      renderText = item[col.key].map(obj => obj.label)
    else if (col.human_key) {
      if (typeof item[col.human_key] === "object") {
        renderText = item[col.human_key] && item[col.human_key].label
      } else {
        renderText = item[col.human_key]
      }
    } else if (col.type === 'bundle')
      renderText = item.bundle
        && item.bundle.items
        && item.bundle.items.map(bundle =>
          bundle.serial_number !== item.serial_number
          && `${bundle.serial_number}${' '}`)
    else
      renderText = item[col.key]
    return (<Typography variant='subtitle1'>{renderText}</Typography>)
  }
  return (
    <React.Fragment>
      {Boolean(activities) || Boolean(relatedOrders) ? (
        <React.Fragment>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label='tabs'
          >
            <StyledTab label='Data' {...tabProps(0)} />
            <StyledTab style={{ display: !Boolean(activities) ? 'none' : 'inherit' }} label='Händelser' {...tabProps(1)} />
            {Boolean(activeOrders.length) && (
              <StyledTab label='Aktiva ordrar' {...tabProps(2)} />
            )}
          </StyledTabs>
          {value === 0 ? (
            <div
              value={value}
              index={0}
              hidden={value !== 0}
              role="tabpanel"
              id={`tabpanel-0`}
              aria-labelledby={`tab-0`}
            >
              <List>
                {cols.map(col => col.read && (
                  <ListItem key={col.key}>
                    <ListItemText
                      primary={<Typography variant='subtitle2' color='textSecondary'>{col.type === 'bundle' ? (item.bundle ? 'Sammankopplade individer' : '') : col.label}</Typography>}
                      secondary={renderColSecondary(item, col)}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          ) : (
            <React.Fragment>
              {value === 1 ? (
                <div
                  value={value}
                  index={1}
                  hidden={value !== 1}
                  role="tabpanel"
                  id={`tabpanel-1`}
                  aria-labelledby={`tab-1`}
                >
                  <ItemActivities item={item} />
                </div>
              ) : (
                <div
                  value={value}
                  index={2}
                  hidden={value !== 2}
                  role="tabpanel"
                  id={`tabpanel-2`}
                  aria-labelledby={`tab-2`}
                >
                  <List>
                    {activeOrders.map(order => (
                      <ListItem key={order.id}>
                        <Card style={{ width: '100%' }}>
                          <CardContent>
                            <Typography variant='body2' color='textSecondary'>
                              {format(new Date(order.created_at), 'd LLLL yyyy, HH:mm:ss', { locale: sv })}
                            </Typography>
                            <Typography variant='body2'>
                              {order.order_rows.find(row => row.article_id === item[relatedOrders]).qty}st på väg till {order.storage}
                            </Typography>
                          </CardContent>
                        </Card>
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <List>
          {cols.map(col => col.read && (
            <ListItem key={col.nested ? `${col.nested.model}_${col.nested.key}` : col.key}>
              <ListItemText
                primary={<Typography variant='subtitle2' color='textSecondary'>{col.label}</Typography>}
                secondary={col.type === 'boolean' ? <Checkbox checked={item[col.key]} disabled /> : <Typography variant='subtitle1'>{col.type === 'array' ? item[col.key].map(obj => obj.label) : (col.human_key ? item[col.human_key] : (col.nested ? item[col.nested.model][col.nested.key] : item[col.key]))}</Typography>}
              />
            </ListItem>
          ))}
        </List>
      )}
    </React.Fragment>
  )
}

const useEditStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const Edit = ({
  item,
  cols,
  validationSchema,
  model,
  onEdit,
  onBundle,
  onClose,
  callback
}) => {
  const classes = useEditStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [warning, setWarning] = useState('')
  const [bundleWarning, setBundleWarning] = useState(false)
  const [value, setValue] = useState(0)

  const tabProps = index => (
    {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    }
  )

  const handleTabChange = (e, newValue) => {
    setValue(newValue)
  }

  return (
    <Formik
      initialValues={item}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        const { bundle, bundle_id, ignore_bundle_warning } = values

        if (bundle && bundle.items && bundle.items.length > 1 && !ignore_bundle_warning) {
          return setBundleWarning(true)
        }
        // delete values.ignore_bundle_warning

        Object.keys(values).forEach(key => {
          if (Array.isArray(values[key]) && key !== 'bundle_id') {
            values[`${key}_attributes`] = []
            values[key].forEach(obj => {
              values[`${key}_attributes`].push({ id: obj.value })
            })
          }
        })

        const perform = async reload => {
          try {
            const data = await dataService.editData({ values, model: `${model}/${item.id}` })
            setSubmitting(false)
            onEdit(data)
            if (reload) {
              onBundle()
            }
            onClose()
          } catch (error) {
            setSubmitting(false)
            if (Array.isArray(error)) {
              error.forEach(err => {
                enqueueSnackbar(err.message || err.type || err, { variant: 'error' })
              })
            } else if (error.status === 423) {
              setWarning(error.error)
            } else {
              enqueueSnackbar(error, { variant: 'error' })
            }
          }
        }


        if (Array.isArray(bundle_id)) {
          bundle_id.push({ value: item.id, label: item.label })
          if (item.bundle_id) {
            const response = await dataService.editData({ values: bundle_id, model: `bundles/${item.bundle_id}` })
            values.bundle_id = response
            await perform(true)
          } else {
            const response = await dataService.createData({ values: bundle_id, model: 'bundles' })
            values.bundle_id = response
            await perform(true)
          }
        }
        else {
          await perform()
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => (
        <Form>
          {cols.some(col => col.exclude) && (
            <React.Fragment>
              <StyledTabs
                value={value}
                onChange={handleTabChange}
                aria-label='tabs'
              >
                {cols.map((col, index) => col.exclude && (
                  <StyledTab label={col.label} {...tabProps(index)} />
                ))}
              </StyledTabs>
              {cols.map((col, index) => col.exclude && (
                <TabPanel value={value} index={index}>
                  {(() => {
                    switch (col.type) {
                      case 'array':
                        return (
                          <ReactSelect
                            name={col.key}
                            options={col.options}
                            placeholder={`Välj ${col.label}`}
                            label={col.label}
                            onChange={option => {
                              setFieldValue(col.key, option && option.length > 0 ? option.map(obj => (obj)) : [])
                            }}
                            onBlur={() => setFieldTouched(col.key, true)}
                            isMulti
                            errors={errors}
                            touched={touched}
                            value={values[col.key]}
                          />
                        )
                      case 'select':
                        return (
                          <ReactSelect
                            name={col.key}
                            options={col.options}
                            placeholder={`Välj ${col.label}`}
                            label={col.label}
                            onChange={col.trigger ? (option => {
                              callback(option ? option.value : null)
                              setFieldValue(col.key, option ? option.value : null)
                            }) : (option => {
                              setFieldValue(col.exclude, '')
                              setFieldValue(col.key, option ? option.value : null)
                            })}
                            onBlur={() => setFieldTouched(col.key, true)}
                            errors={errors}
                            touched={touched}
                            value={col.options.find(option => option.value === values[col.key])}
                          />
                        )
                      case 'boolean':
                        return (
                          <Field
                            name={col.key}
                            type='checkbox'
                            value={values[col.key]}
                            checked={values[col.key]}
                            handleToggle={event => { setFieldValue(col.key, event.target.checked) }}
                            label={col.label}
                            component={CheckboxField}
                            color='primary'
                          />
                        )
                      case 'slider':
                        return (
                          <Field
                            name={col.key}
                            type='slider'
                            value={values[col.key]}
                            onChangeCommitted={(event, value) => { setFieldValue(col.key, value) }}
                            label={col.label}
                            component={SliderField}
                            valueLabelDisplay="auto"
                            step={1}
                            marks={col.marks}
                            min={1}
                            max={12}
                          />
                        )
                      default:
                        return (
                          <Field
                            variant='outlined'
                            margin='normal'
                            type={col.type || 'text'}
                            multiline={col.multiline}
                            name={col.key}
                            fullWidth
                            component={TextField}
                            label={col.label}
                          />
                        )
                    }
                  })()}
                </TabPanel>
              ))}
            </React.Fragment>
          )}
          {cols.map(col => col.edit && !col.exclude && (
            <React.Fragment>
              {(() => {
                switch (col.type) {
                  case 'bundle':
                    return (
                      <BundleJoin
                        defaultValue={item.bundle && item.bundle.items ? item.bundle.items.filter(bundle => bundle.serial_number !== item.serial_number).map(b => ({ value: b.id, label: b.serial_number })) : []}
                        errors={errors}
                        touched={touched}
                        onChange={bundles => {
                          setFieldValue(col.key, bundles && bundles.length > 0 ? bundles : [])
                        }}
                        value={values[col.key]}
                        exclude={item.serial_number}
                      />
                    )
                  case 'async': {
                    return (
                      <AsyncSelect
                        name={col.key}
                        placeholder={`Välj ${col.label}`}
                        defaultValue={{ value: item[col.nested ? col.nested.model : col.key], label: col.nested ? item[col.nested.model][col.nested.key] : item[col.human_key] }}
                        errors={errors}
                        tocuhed={touched}
                        settings={col.asyncSettings}
                        onChange={option => {
                          setFieldValue(col.key, option ? option.value : null)
                        }}
                      />
                    )
                  }
                  case 'array':
                    return (
                      <ReactSelect
                        name={col.key}
                        options={col.options}
                        placeholder={`Välj ${col.label}`}
                        label={col.label}
                        onChange={option => {
                          setFieldValue(col.key, option && option.length > 0 ? option.map(obj => (obj)) : [])
                        }}
                        onBlur={() => setFieldTouched(col.key, true)}
                        isMulti
                        errors={errors}
                        touched={touched}
                        value={values[col.key]}
                      />
                    )
                  case 'select':
                    return (
                      <ReactSelect
                        name={col.key}
                        options={col.options}
                        placeholder={`Välj ${col.label}`}
                        label={col.label}
                        onChange={col.trigger ? (option => {
                          callback(option ? option.value : null)
                          setFieldValue(col.key, option ? option.value : null)
                        }) : (option => {
                          setFieldValue(col.key, option ? option.value : null)
                        })}
                        onBlur={() => setFieldTouched(col.key, true)}
                        errors={errors}
                        touched={touched}
                        value={col.options.find(option => option.value === values[col.key])}
                      />
                    )
                  case 'boolean':
                    return (
                      <Field
                        name={col.key}
                        type='checkbox'
                        value={values[col.key]}
                        checked={values[col.key]}
                        handleToggle={event => { setFieldValue(col.key, event.target.checked) }}
                        label={col.label}
                        component={CheckboxField}
                        color='primary'
                      />
                    )
                  case 'slider':
                    return (
                      <Field
                        name={col.key}
                        type='slider'
                        value={values[col.key]}
                        onChangeCommitted={(event, value) => { setFieldValue(col.key, value) }}
                        label={col.label}
                        component={SliderField}
                        valueLabelDisplay="auto"
                        step={1}
                        marks={col.marks}
                        min={1}
                        max={12}
                      />
                    )
                  default:
                    return (
                      <Field
                        variant='outlined'
                        margin='normal'
                        type={col.type || 'text'}
                        multiline={col.multiline}
                        name={col.key}
                        fullWidth
                        component={TextField}
                        label={col.label}
                      />
                    )
                }
              })()}
            </React.Fragment>
          ))}
          <Dialog
            open={Boolean(warning)}
            onClose={() => setWarning('')}
            fullWidth
            maxWidth={'sm'}
            aria-labelledby='warning-title'
            aria-describedby='warning-description'
          >
            <DialogTitle
              id='warning-title'
              disableTypography={true}
              classes={{
                root: classes.root
              }}
            >
              <WarningIcon color='error' style={{ marginRight: 10 }} />
              <Typography variant='h6' component='h2'>{warning}</Typography>
            </DialogTitle>
            <DialogContent
            // classes={{
            //   root: classes.dialogRoot
            // }}
            >
              <DialogContentText
                id='warning-description'
              >
                <Field
                  name='ignore_lock'
                  type='checkbox'
                  value={values['ignore_lock']}
                  checked={values['ignore_lock']}
                  handleToggle={event => { setFieldValue('ignore_lock', event.target.checked) }}
                  label='Ignorera lås'
                  component={CheckboxField}
                  color='primary'
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setWarning('')}
                style={{ margin: 0 }}
                color='secondary'
              >
                Avbryt
              </Button>
              <Button
                onClick={() => submitForm()}
                color='primary'
                disabled={!values['ignore_lock']}
                style={{ margin: 0 }}
              >
                Gå vidare
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={Boolean(bundleWarning)}
            onClose={() => setBundleWarning(false)}
            fullWidth
            maxWidth={'sm'}
            aria-labelledby='warning-title'
            aria-describedby='warning-description'
          >
            <DialogTitle
              id='warning-title'
              disableTypography={true}
              classes={{
                root: classes.root
              }}
            >
              <WarningIcon color='error' style={{ marginRight: 10 }} />
              <Typography variant='h6' component='h2'>
                Sammankopplad individ
              </Typography>
            </DialogTitle>
            <DialogContent
            // classes={{
            //   root: classes.dialogRoot
            // }}
            >
              <DialogContentText
                id='warning-description'
              >
                Du håller på att ändra en individ i en sammankopplad artikel individuellt. Är du säker på detta?
                <br />
                <Field
                  name='ignore_bundle_warning'
                  type='checkbox'
                  value={values['ignore_bundle_warning']}
                  checked={values['ignore_bundle_warning']}
                  handleToggle={event => { setFieldValue('ignore_bundle_warning', event.target.checked) }}
                  label='Genomför ändringar'
                  component={CheckboxField}
                  color='primary'
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setBundleWarning(false)}
                style={{ margin: 0 }}
                color='secondary'
              >
                Avbryt
              </Button>
              <Button
                onClick={() => submitForm()}
                color='primary'
                disabled={!values['ignore_bundle_warning']}
                style={{ margin: 0 }}
              >
                Gå vidare
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            type='submit'
            size='large'
            fullWidth
            variant='contained'
            disabled={isSubmitting}
            loading={isSubmitting}
            color='primary'
          >
            Spara
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const Create = ({
  model,
  validationSchema,
  cols,
  onCreate,
  onClose,
  callback
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [value, setValue] = useState(0)

  const tabProps = index => (
    {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    }
  )

  const handleTabChange = (e, newValue) => {
    setValue(newValue)
  }

  const initial = {}

  cols.filter(col => Boolean(col.default) || typeof col.default === 'boolean').forEach(col => {
    initial[col.key] = col.default
  })

  return (
    <Formik
      initialValues={initial}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        Object.keys(values).forEach(key => {
          if (Array.isArray(values[key])) {
            values[`${key}_attributes`] = []
            values[key].forEach(obj => {
              values[`${key}_attributes`].push({ id: obj.value })
            })
          }
        })

        try {
          const data = await dataService.createData({ values, model })
          setSubmitting(false)
          onCreate(data)
          onClose()
        } catch (error) {
          setSubmitting(false)
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type || err, { variant: 'error' })
            })
          } else {
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        status,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => (
        <Form>
          {cols.some(col => col.exclude) && (
            <React.Fragment>
              <StyledTabs
                value={value}
                onChange={handleTabChange}
                aria-label='tabs'
              >
                {cols.map((col, index) => col.exclude && (
                  <StyledTab label={col.label} {...tabProps(index)} />
                ))}
              </StyledTabs>
              {cols.map((col, index) => col.exclude && (
                <TabPanel value={value} index={index}>
                  {(() => {
                    switch (col.type) {
                      case 'array':
                        return (
                          <ReactSelect
                            name={col.key}
                            options={col.options}
                            placeholder={`Välj ${col.label}`}
                            label={col.label}
                            onChange={option => {
                              setFieldValue(col.key, option && option.length > 0 ? option.map(obj => obj) : [])
                            }}
                            onBlur={() => setFieldTouched(col.key, true)}
                            isMulti
                            errors={errors}
                            touched={touched}
                          // value={values[col.key]}
                          />
                        )
                      case 'async': {
                        return (
                          <AsyncSelect
                            name={col.key}
                            placeholder={`Välj ${col.label}`}
                            errors={errors}
                            tocuhed={touched}
                            settings={col.asyncSettings}
                            onChange={option => {
                              setFieldValue(col.key, option ? option.value : null)
                            }}
                          />
                        )
                      }
                      case 'select':
                        return (
                          <ReactSelect
                            name={col.key}
                            options={col.options}
                            placeholder={`Välj ${col.label}`}
                            label={col.label}
                            onChange={col.trigger ? (option => {
                              callback(option ? option.value : null)
                              setFieldValue(col.key, option ? option.value : null)
                            }) : (option => {
                              setFieldValue(col.exclude, '')
                              setFieldValue(col.key, option ? option.value : null)
                            })}
                            onBlur={() => setFieldTouched(col.key, true)}
                            isDisabled={(col.depends_on && !values[col.depends_on]) || (col.options && !col.options.length)}
                            errors={errors}
                            touched={touched}
                            value={col.options.find(option => option.value === values[col.key] || '')}
                          />
                        )
                      case 'boolean':
                        return (
                          <Field
                            name={col.key}
                            type='checkbox'
                            value={values[col.key]}
                            checked={values[col.key]}
                            handleToggle={event => { setFieldValue(col.key, event.target.checked) }}
                            label={col.label}
                            component={CheckboxField}
                            color='primary'
                          />
                        )
                      case 'slider':
                        return (
                          <Field
                            name={col.key}
                            type='slider'
                            value={values[col.key]}
                            onChangeCommitted={(event, value) => { setFieldValue(col.key, value) }}
                            label={col.label}
                            component={SliderField}
                            valueLabelDisplay="auto"
                            step={1}
                            marks={col.marks}
                            min={1}
                            max={12}
                          />
                        )
                      default:
                        return (
                          <Field
                            variant='outlined'
                            margin='normal'
                            multiline={col.multiline}
                            type={col.type || 'text'}
                            name={col.key}
                            fullWidth
                            component={TextField}
                            label={col.label}
                          />
                        )
                    }
                  })()}
                </TabPanel>
              ))}
            </React.Fragment>
          )}
          {cols.map(col => col.create && !col.exclude && (
            <React.Fragment>
              {(() => {
                switch (col.type) {
                  case 'array':
                    return (
                      <ReactSelect
                        name={col.key}
                        options={col.options}
                        placeholder={`Välj ${col.label}`}
                        label={col.label}
                        onChange={option => {
                          setFieldValue(col.key, option && option.length > 0 ? option.map(obj => obj) : [])
                        }}
                        onBlur={() => setFieldTouched(col.key, true)}
                        isMulti
                        errors={errors}
                        touched={touched}
                      // value={values[col.key]}
                      />
                    )
                  case 'async': {
                    return (
                      <AsyncSelect
                        name={col.key}
                        placeholder={`Välj ${col.label}`}
                        errors={errors}
                        tocuhed={touched}
                        settings={col.asyncSettings}
                        onChange={option => {
                          setFieldValue(col.key, option ? option.value : null)
                        }}
                      />
                    )
                  }
                  case 'select':
                    return (
                      <ReactSelect
                        name={col.key}
                        options={col.options}
                        placeholder={`Välj ${col.label}`}
                        label={col.label}
                        onChange={col.trigger ? (option => {
                          callback(option ? option.value : null)
                          setFieldValue(col.key, option ? option.value : null)
                        }) : (option => {
                          setFieldValue(col.key, option ? option.value : null)
                        })}
                        onBlur={() => setFieldTouched(col.key, true)}
                        isDisabled={(col.depends_on && !values[col.depends_on]) || (col.options && !col.options.length)}
                        errors={errors}
                        touched={touched}
                      />
                    )
                  case 'boolean':
                    return (
                      <Field
                        name={col.key}
                        type='checkbox'
                        value={values[col.key]}
                        checked={values[col.key]}
                        handleToggle={event => { setFieldValue(col.key, event.target.checked) }}
                        label={col.label}
                        component={CheckboxField}
                        color='primary'
                      />
                    )
                  case 'slider':
                    return (
                      <Field
                        name={col.key}
                        type='slider'
                        value={values[col.key]}
                        onChangeCommitted={(event, value) => { setFieldValue(col.key, value) }}
                        label={col.label}
                        component={SliderField}
                        valueLabelDisplay="auto"
                        step={1}
                        marks={col.marks}
                        min={1}
                        max={12}
                      />
                    )
                  default:
                    return (
                      <Field
                        variant='outlined'
                        margin='normal'
                        multiline={col.multiline}
                        type={col.type || 'text'}
                        name={col.key}
                        fullWidth
                        component={TextField}
                        label={col.label}
                      />
                    )
                }
              })()}
            </React.Fragment>
          ))}
          <Button
            type='submit'
            size='large'
            fullWidth
            variant='contained'
            disabled={isSubmitting}
            loading={isSubmitting}
            color='primary'
          >
            Spara
          </Button>
        </Form>
      )}
    </Formik>

  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 500,
    maxWidth: '80%',
    borderRadius: theme.shape.borderRadius,
    height: 'calc(100% - 40px)',
    margin: 20
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  },
  wrapper: {
    width: '100%',
    padding: 24,
    height: '100%'
  },
}))

export default ({
  drawer,
  activities,
  relatedOrders,
  onClose,
  cols,
  model,
  filters,
  onFilter,
  onClearFilter,
  onCreate,
  onEdit,
  onBundle,
  onMultiChange,
  callback
}) => {

  const classes = useStyles()
  const title = () => {
    switch (drawer.type) {
      case 'read':
        return 'Visa'
      case 'edit':
        return 'Ändra'
      case 'create':
        return 'Skapa'
      case 'filter':
        return 'Filtrera'
      default:
        return
    }
  }

  return (
    <Drawer
      classes={{
        paper: classes.drawer
      }}
      anchor='right'
      open={drawer.open}
      onClose={onClose}
    >
      <Toolbar className={classes.toolbar}>
        <Typography variant='h6'>
          {title()}
        </Typography>
        <IconButton onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </Toolbar>
      <div className={classes.wrapper}>
        {(() => {
          switch (drawer.type) {
            case 'read':
              return (
                <View
                  cols={cols}
                  item={drawer.item}
                  activities={activities}
                  relatedOrders={relatedOrders}
                />
              )
            case 'edit':
              return (
                <Edit
                  cols={cols}
                  item={drawer.item}
                  model={model}
                  onEdit={onEdit}
                  onClose={onClose}
                  onBundle={onBundle}
                  callback={callback}
                />
              )
            case 'create':
              return (
                <Create
                  cols={cols}
                  model={model}
                  onCreate={onCreate}
                  onClose={onClose}
                  callback={callback}
                />
              )
            case 'filter':
              return (
                <Filter
                  cols={cols}
                  model={model}
                  filters={filters}
                  onFilter={onFilter}
                  onClearFilter={onClearFilter}
                  callback={callback}
                />
              )
            case 'multichange':
              return (
                <Multichange
                  cols={cols}
                  model={model}
                  onClose={onClose}
                  onMultiChange={onMultiChange}
                  items={drawer.item}
                  callback={callback}
                />
              )
            default:
              return
          }
        })()}
      </div>
    </Drawer>
  )
}
