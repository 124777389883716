import React, {useEffect, useState} from 'react'

import Table from './components/Table'
import { dataService } from './services'

const cols = [
  {
    id: 1,
    key: 'id',
    label: 'Id',
    visible: true,
    sortable: true,
    filterable: true,
    edit: false,
    create: false,
    read: true,
    type: 'number'
  },
  {
    id: 2,
    key: 'label',
    label: 'Titel',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'text'
  },
  {
    id: 3,
    key: 'check_in',
    label: 'Händelse inleverans',
    visible: false,
    sortable: false,
    filterable: false,
    edit: true,
    create: true,
    read: true,
    type: 'select',
    options: [{
      value: 'Ingen_inleverans',
      label: 'Ingen_inleverans',
    }, {
      value: 'Visa_checklista',
      label: 'Visa_checklista',
    }]
  },
  {
    id: 4,
    key: 'check_out',
    label: 'Händelse utleverans',
    visible: false,
    sortable: false,
    filterable: false,
    edit: true,
    create: true,
    read: true,
    type: 'select',
    options: [{
      value: 'Ingen_utleverans',
      label: 'Ingen_utleverans',
    }, {
      value: 'Välj_äldst',
      label: 'Välj_äldst',
    }]
  },
  {
    id: 5,
    key: 'change_status_to',
    label: 'Automatisk status',
    visible: false,
    sortable: false,
    filterable: false,
    edit: true,
    create: true,
    read: true,
    type: 'select'
  },
  {
    id: 6,
    key: 'change_status_after',
    label: 'Ändra status efter (månader)',
    visible: false,
    sortable: false,
    filterable: false,
    edit: true,
    create: true,
    read: true,
    type: 'slider',
    marks: [{
      label: 1,
      value: 1
    },
    {
      label: 2,
      value: 2
    },
    {
      label: 3,
      value: 3
    },
    {
      label: 4,
      value: 4
    },
    {
      label: 5,
      value: 5
    },
    {
      label: 6,
      value: 6
    },
    {
      label: 7,
      value: 7
    },
    {
      label: 8,
      value: 8
    },
    {
      label: 9,
      value: 9
    },
    {
      label: 10,
      value: 10
    },
    {
      label: 11,
      value: 11
    },
    {
      label: 12,
      value: 12
    },
  ]
  },
  {
    id: 7,
    key: 'handles_bulk',
    label: 'Hanterar plockvaror',
    default: true,
    visible: true,
    sortable: false,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'boolean'
  },
  {
    id: 8,
    key: 'on_dashboard',
    label: 'Visa på startsida',
    default: true,
    visible: true,
    sortable: false,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'boolean'
  }
]

export default ({
  match,
  history,
  location
}) => {
  const [enhanced, setEnhanced] = useState(cols)
  useEffect(() => {
    dataService.getData({ model: 'statuses', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label})
        })
        cols.find(col => col.id === 5).options = options
        setEnhanced(cols)
      })
  })

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Lager'
      singular='Lager'
      model='storages'
    />
  )
}
