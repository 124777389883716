import classNames from 'clsx';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link, Route, Switch } from 'react-router-dom';
import xlsx from 'xlsx';

import { makeStyles, withStyles } from '@material-ui/styles';

import {
  CircularProgress, Fab, IconButton, Modal, Paper, Tab, Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Tabs, Toolbar, Typography
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/PlaylistAdd';
import SaveIcon from '@material-ui/icons/Save';

import Csv from '../../images/csv';
import { dataService } from '../../services';
import Button from '../Button';
import ReactSelect from '../fields/ReactSelect';
import TextField from '../fields/TextField';

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />
));

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'scroll',
    transition:
      'transform .2s cubic-bezier(.29,1.09,.74,1.3), opacity .2s ease-in-out',
    height: '100%',
    '&:focus': {
      outline: 0,
    },
  },
  active: {
    transform: 'scale(0.95)',
    opacity: 0.7,
  },
  noContent: {
    width: 'auto',
  },
  header: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  cell: {
    whiteSpace: 'nowrap',
  },
  headerCell: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    marginBottom: theme.spacing(2),
    display: 'block',
    transition: 'transform .2s cubic-bezier(.29,1.09,.74,1.3)',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 50,
  },
  fabIcon: {
    marginRight: 10,
  },
  fields: {
    // margin: 10,
    padding: '12px',
    // border: '1px solid #e4e4e4',
    // borderRadius: '5px',
  },
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    padding: '30px',
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: '5px',
    border: '1px solid red',
  },
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    padding: '30px, 50px'
  }
}));

export default ({ location, match, setRefresh }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('')

  const [importing, setImporting] = useState(false);
  const [importedData, setImportedData] = useState({
    fields: [],
    data: [],
    errors: [],
  });
  const [articles, setArticles] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false)
  const handleOpen = () => setModalOpen(true)

  function handleLoading(boolian, text) {
    setLoading(boolian)
    if (text) setLoadingText(text)
  }

  useEffect(() => {
    dataService
      .getData({
        model: 'articles',
        params:
          '?filter[0][key]=selectable&filter[0][value]=1&pagination=off',
      })
      .then((data) => {
        const { result } = data;
        const options = result.map((option) => ({
          value: option.id,
          label: option.label,
          article_number: option.article_number,
        }));
        setArticles(options);
      });
  }, [match]);

  const onDropAccepted = useCallback((acceptedFiles) => {
    const [file] = acceptedFiles;
    const reader = new FileReader();

    const rABS = !!reader.readAsBinaryString;

    handleLoading(true, 'Läser in...')
    reader.onerror = () => {
      setLoading(false)
    };
    reader.onabort = () => {
      setLoading(false)
    };
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = xlsx.read(bstr, {
        type: rABS ? 'binary' : 'array',
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

      // this dose this -> titleMapper.serienummer = 0
      // The titles in the exel files can be in any order but they have to be spelled correctly.
      let titleMapper = {}
      data[0].map((title, index) => {
        titleMapper[title.toLowerCase()] = index
      })
      const titles = Object.keys(titleMapper)
      const allowedTitles = ["serienummer", "pall-id", "ordernummer", "artikelnummer", "artikel"]
      const hasMissingTitles = !allowedTitles.every(title => titles.indexOf(title) >= 0)
      if (hasMissingTitles) {
        handleOpen()
        setLoading(false)
        return
      }

      const formatted = data.map((array, index) => {
        if (index === 0) return null
        return {
          'serial_number': array[titleMapper["serienummer"]],
          'pallet': array[titleMapper["pall-id"]],
          'order_number': array[titleMapper["ordernummer"]],
          'article_number': array[titleMapper["artikelnummer"]],
          'label': array[titleMapper["artikel"]]
        }
      }).filter(item => item)

      setImportedData({ data: formatted });
      setLoading(false)
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }, []);

  const onDropRejected = useCallback(() => {
    enqueueSnackbar('Vald fild kan inte användas', {
      variant: 'error',
    });
  }, [enqueueSnackbar]);

  const { getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      onDropAccepted,
      onDropRejected,
      noClick: true,
      maxFiles: 1,
      accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

  return (
    <Paper
      className={classNames(
        classes.paper,
        isDragActive && classes.active
      )}
    >
      <Toolbar>
        <StyledTabs
          value={`/${location.pathname
            .split(`${match.url}/`)
            .pop()
            .split('/')
            .shift()}`}
        >
          <StyledTab
            label="Mätarfil"
            value={'/'}
            component={Link}
            to={match.url}
          />
          <StyledTab
            label="Manuell"
            value={'/manual'}
            component={Link}
            to={`${match.url}/manual`}
          />
        </StyledTabs>
      </Toolbar>
      <Switch>
        <Route
          exact
          path={match.url}
          render={() => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {importedData.data.length > 0 ? (
                <div>
                  <Formik
                    initialValues={{
                      serial_number: '',
                      article_nummber: '',
                      pallet: '',
                      order_number: '',
                      article_nummber: '',
                      // article_id: '',
                      items: importedData.data,
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values, {
                      resetForm,
                      setSubmitting,
                      setFieldValue,
                    }) => {
                      handleLoading(true, 'Importerar... Detta kan ta flera minuter')
                      dataService
                        .createData({
                          model: 'stock_imports',
                          values: { items: values.items },
                        })
                        .then(
                          (data) => {
                            handleLoading(false, '')
                            setSubmitting(false);
                            setRefresh();
                            resetForm();
                            setImportedData({
                              fields: [],
                              data: [],
                              errors: [],
                            });
                            enqueueSnackbar('Importen genomfördes', { variant: 'success' });
                          },
                          ({ error, items }) => {
                            handleLoading(false, '')
                            setSubmitting(false);

                            if (Array.isArray(error)) {
                              error.forEach((err) => {
                                enqueueSnackbar(
                                  err.message || err.type || err,
                                  { variant: 'error' }
                                );
                              });
                              if (items && Array.isArray(items)) {
                                const markedItems = values.items.map(item => ({
                                  ...item,
                                  duplicate: items.includes(item.serial_number)
                                }))
                                setFieldValue('items', markedItems);
                              }
                            } else {
                              enqueueSnackbar(error, { variant: 'error' });
                            }
                          }
                        );
                    }}
                  >
                    {({
                      isSubmitting,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                      setFieldTouched,
                    }) => (
                      <Form className={classes.form}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.headerCell}>
                                {'Serienummer'}
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                {'Pall-id'}
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                {'Ordernummer'}
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                {'Artikelnummer'}
                              </TableCell>
                              <TableCell className={classes.headerCell}>
                                {'Artikel'}
                              </TableCell>
                              <TableCell className={classes.headerCell} />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.items.map((item, index) => (
                              <TableRow key={Math.random()}>
                                <TableCell
                                  key={Math.random()}
                                  className={classes.cell}
                                  {...(item['duplicate'] && {
                                    style: {
                                      color: 'red',
                                    }
                                  })}
                                >
                                  {item['serial_number']}
                                </TableCell>
                                <TableCell
                                  key={Math.random()}
                                  className={classes.cell}
                                  {...(item['duplicate'] && {
                                    style: {
                                      color: 'red',
                                    }
                                  })}
                                >
                                  {item['pallet']}
                                </TableCell>
                                <TableCell
                                  key={Math.random()}
                                  className={classes.cell}
                                  {...(item['duplicate'] && {
                                    style: {
                                      color: 'red',
                                    }
                                  })}
                                >
                                  {item['order_number']}
                                </TableCell>
                                <TableCell
                                  key={Math.random()}
                                  className={classes.cell}
                                  {...(item['duplicate'] && {
                                    style: {
                                      color: 'red',
                                    }
                                  })}
                                >
                                  {item['article_number']}
                                </TableCell>
                                <TableCell
                                  key={Math.random()}
                                  className={classes.cell}
                                  {...(item['duplicate'] && {
                                    style: {
                                      color: 'red',
                                    }
                                  })}
                                >
                                  {item['label']}
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    size={'small'}
                                    onClick={() => {
                                      const newData = [...values.items]
                                      newData.splice(index, 1)
                                      setFieldValue('items', newData)
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <Fab
                          variant="extended"
                          aria-label="submit"
                          type="submit"
                          color="primary"
                          disabled={isSubmitting || values.items.length < 1}
                          className={classes.fab}
                        >
                          <SaveIcon className={classes.fabIcon} />
                          {isSubmitting && (
                            <CircularProgress
                              style={{
                                position: 'absolute',
                                left: 5,
                                color: '#fff',
                              }}
                              size={45}
                            />
                          )}
                          Importera
                        </Fab>
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    paddingBottom: 50,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Csv
                    style={{ width: 200, marginBottom: 40 }}
                    color="#bebebe"
                  />
                  <Typography
                    component="h2"
                    variant="h5"
                    align="center"
                  >
                    Ladda upp Mätarfil (*.xlsx)
                  </Typography>
                  <Typography
                    component="h3"
                    variant="subtitle1"
                    align="center"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Drag och släpp fil från dator eller{' '}
                    <Button
                      onClick={open}
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ margin: 10 }}
                    >
                      Klicka här
                    </Button>
                  </Typography>
                </div>
              )}
            </div>
          )}
        />
        <Route
          exact
          path={`${match.url}/manual`}
          render={() => (
            <Formik
              enableReinitialize
              initialValues={{
                items: [
                  {
                    serial_number: '',
                    pallet: '',
                    order_number: '',
                    article: '',
                  },
                ],
              }}


              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleLoading(true, 'Importerar...')
                const { items } = values;
                items.forEach((item) => {
                  const { article_number, label } = item.article;
                  item.article_number = article_number;
                  item.label = label;
                  delete item.article;
                });
                dataService
                  .createData({
                    model: 'stock_imports',
                    values: { items },
                  })
                  .then(
                    (data) => {
                      setSubmitting(false);
                      setRefresh();
                      resetForm();
                      enqueueSnackbar('Importen genomfördes', { variant: 'success' });
                      handleLoading(false, '')
                    },
                    ({ error, items }) => {
                      setSubmitting(false);
                      handleLoading(false, '')

                      if (Array.isArray(error)) {
                        error.forEach((err) => {
                          enqueueSnackbar(
                            err.message || err.type || err,
                            { variant: 'error' }
                          );
                        });
                        if (items && Array.isArray(items)) {
                          items.forEach((item) => {
                            enqueueSnackbar(`Duplicate: ${item}`, {
                              variant: 'error',
                            });
                          });
                        }
                      } else {
                        enqueueSnackbar(error, { variant: 'error' });
                      }
                    }
                  );
              }}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Serienummer</TableCell>
                        <TableCell>Pall-Id</TableCell>
                        <TableCell>Ordernummer</TableCell>
                        <TableCell>Artikel</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <FieldArray
                        name="items"
                        render={(arrayHelpers) => (
                          <React.Fragment>
                            {values.items &&
                              values.items.map((item, index) => (
                                <TableRow key={index}>

                                  <TableCell>
                                    <Field
                                      name={`items[${index}][serial_number]`}
                                      fullWidth
                                      label="Serienummer"
                                      variant="outlined"
                                      margin="dense"
                                      type="text"
                                      errors={errors}
                                      component={TextField}
                                    />
                                  </TableCell>

                                  <TableCell>
                                    <Field
                                      name={`items[${index}][pallet]`}
                                      fullWidth
                                      label="Pall-Id"
                                      variant="outlined"
                                      margin="dense"
                                      type="text"
                                      component={TextField}
                                    />
                                  </TableCell>

                                  <TableCell>
                                    <Field
                                      name={`items[${index}][order_number]`}
                                      fullWidth
                                      label="Ordernummer"
                                      variant="outlined"
                                      margin="dense"
                                      type="text"
                                      component={TextField}
                                    />
                                  </TableCell>

                                  <TableCell
                                    style={{ minWidth: 275 }}
                                  >
                                    <ReactSelect
                                      name={`items[${index}][article]`}
                                      dense
                                      options={articles}
                                      placeholder="Välj artikel"
                                      label="Artikel"
                                      fullWidth
                                      onChange={(option) =>
                                        setFieldValue(
                                          `items[${index}][article]`,
                                          option
                                        )
                                      }
                                      onBlur={() =>
                                        setFieldTouched(
                                          `items[${index}][article]`,
                                          true
                                        )
                                      }
                                      value={articles.find(
                                        (option) =>
                                          option ===
                                          values['items'][index][
                                          'article'
                                          ]
                                      )}
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      onClick={() =>
                                        arrayHelpers.remove(index)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            <TableRow>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell align="right">
                                <IconButton
                                  onClick={() =>
                                    arrayHelpers.push({
                                      serial_number: '',
                                      pallet: '',
                                      order_number: '',
                                      article: '',
                                    })
                                  }
                                >
                                  <AddIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        )}
                      />
                    </TableBody>
                  </Table>
                  <Fab
                    variant="extended"
                    aria-label="submit"
                    type="submit"
                    color="primary"
                    disabled={isSubmitting || values.items.length < 1}
                    className={classes.fab}
                  >
                    <SaveIcon className={classes.fabIcon} />
                    {isSubmitting && (
                      <CircularProgress
                        style={{
                          position: 'absolute',
                          left: 5,
                          color: '#fff',
                        }}
                        size={45}
                      />
                    )}
                    Importera
                  </Fab>
                </Form>
              )}
            </Formik>
          )}
        />
      </Switch>
      {/* <Switch>
        <Route
          exact
          path={match.url}
          render={() => (
            <Fab
              variant="extended"
              aria-label="submit"
              onClick={() => importData()}
              color="primary"
              disabled={importing || importedData.data.length <= 0}
              className={classes.fab}
            >
              <SaveIcon className={classes.fabIcon} />
              {importing && (
                <CircularProgress
                  style={{
                    position: 'absolute',
                    left: 5,
                    color: '#fff',
                  }}
                  size={45}
                />
              )}
              Importera
            </Fab>
          )}
        />
      </Switch> */}

      {/* Error massage */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalWrapper}
      >
        <div className={classes.modalContent}>
          <h1>Fel! <br /> Titel saknas eller är felstavad </h1>
          <h2> Följande rubriker måste finnas och vara rätt stavade.
            <br /> Stor eller liten bokstav spelar ingen roll. </h2>
          <h3>"serienummer", "pall-id", "ordernummer", "artikelnummer", "artikel"</h3>

          <Button style={{ backgroundColor: '#e8e8e8' }} onClick={handleClose}>Tillbaks</Button>
        </div>
      </Modal>

      {/* Loading */}
      <Modal
        open={loading}
        className={classes.modalWrapper}
      >
        <div className={classes.loaderWrapper}>

          <CircularProgress
            style={{
              color: '#fff',
            }}
            size={45}
            disableShrink
          />
          <h2>{loadingText}</h2>
        </div>
      </Modal>

    </Paper>
  );
};
