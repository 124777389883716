import React, { useEffect, useState } from 'react'
import { dataService } from '../../services'

import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TablePagination } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  makeStyles
} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  circle: {
    display: 'inline-flex'
  },
  skeleton: {
    margin: '2px 0 1px'
  },
}))

export default ({ stocktakingId, status }) => {
  const [result, setResult] = useState({ result: [], total: 0 })
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [loading, setLoading] = useState(true)
  const classes = useStyles()

  useEffect(() => {
    setPage(1)
  }, [status])

  useEffect(() => {
    setLoading(true)
    dataService.getData({
      model: '/stocktaking_entries', params: `?filter[0][key]=stocktaking_id&filter[0][value]=${stocktakingId}&filter[1][key]=status&filter[1][value]=${status}&page=${page}&per_page=${perPage}`
    })
      .then(data => {
        setResult(data)
        setLoading(false)
      })
  }, [stocktakingId, status, page, perPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = event => {
    setPerPage(event.target.value)
    setPage(1)
  }

  return (
    < React.Fragment >
      <ExpansionPanelDetails>
        <Table size='small'>

          <TableHead>
            <TableRow>
              <TableCell>
                Artikel
              </TableCell>
              <TableCell>
                Serienummer
              </TableCell>
              <TableCell>
                Lager
              </TableCell>
              <TableCell>
                Lagerplats
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading && result.result.map(stockitem => Boolean(stockitem.item) ? (
              <TableRow key={stockitem.item_id}>
                <React.Fragment>
                  <TableCell>
                    {stockitem.item.article}
                  </TableCell>
                  <TableCell>
                    {stockitem.item.serial_number}
                  </TableCell>
                  <TableCell>
                    {stockitem.item.storage}
                  </TableCell>
                  <TableCell>
                    {stockitem.item.storage_location}
                  </TableCell>
                </React.Fragment>
              </TableRow>
            ) : (
              <TableRow key={stockitem.id}>
                <TableCell>
                </TableCell>
                <TableCell>
                  {stockitem.ean}
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            ))}
            {loading && result.result.map(stockitem => Boolean(stockitem.item) ? (
              <TableRow key={stockitem.item_id}>
                <React.Fragment>
                  <TableCell>
                    <Skeleton variant='rect' className={classes.skeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='rect' className={classes.skeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='rect' className={classes.skeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='rect' className={classes.skeleton} />
                  </TableCell>
                </React.Fragment>
              </TableRow>
            ) : (
              <TableRow key={stockitem.id}>
                <TableCell>
                </TableCell>
                <TableCell>
                  {/* {stockitem.ean} */}
                  <Skeleton variant='rect' className={classes.skeleton} />
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            ))}

            {/* {result.result.map(stockitem => Boolean(stockitem.item) ? (
              <TableRow key={stockitem.item_id}>
                <TableCell>
                  {stockitem.item.article}
                </TableCell>
                <TableCell>
                  {stockitem.item.serial_number}
                </TableCell>
                <TableCell>
                  {stockitem.item.storage}
                </TableCell>
                <TableCell>
                  {stockitem.item.storage_location}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={stockitem.id}>
                <TableCell>
                </TableCell>
                <TableCell>
                  {stockitem.ean}
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            ))} */}

            <TablePagination
              rowsPerPageOptions={[20, 50, 200, 500]}
              labelRowsPerPage='Rader per sida'
              count={result.total}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} av ${count}`}
              rowsPerPage={perPage}
              page={page ? page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />

          </TableBody>

        </Table>

      </ExpansionPanelDetails>

    </React.Fragment >
  )
}
