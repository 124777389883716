import React, { useEffect, useState } from 'react'
import classNames from 'clsx'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'
import { Formik, Form, Field, FieldArray } from 'formik'
import { Route, Switch, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'

import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import { Paper, IconButton, Fab, CircularProgress, List, ListItemText, ListItem, Toolbar, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { authenticationService, dataService } from './services'
import ReactSelect from './components/fields/ReactSelect'
import TextField from './components/fields/TextField'
import { background } from './components/colors'
import ExportFileIcon from './icons/ExportFileIcon'
import GenericTable from './components/Table'


const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: 30
  },
  orderRow: {
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    flex: 2,
    paddingRight: 24
  },
  qty: {
    flex: 1,
    paddingRight: 24
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20
  },
  fabIcon: {
    marginRight: 10
  },
  progress: {
    position: 'absolute',
    left: 5,
    color: '#fff'
  },
  form: {
    padding: 24
  },
  link: {
    textDecoration: 'none',
    display: 'block',
    paddingBottom: 23
  }
}))

export default props => {
  const { history, match, location } = props
  const classes = useStyles()
  const [options, setOptions] = useState([])
  const [latest, setLatest] = useState([])

  const timeOfDay = hour => {
    if (hour >= 17) {
      return 'God kväll'
    }
    else if (hour >= 12) {
      return 'God eftermiddag'
    }
    else if (hour >= 4) {
      return 'God morgon'
    }
    else {
      return ' Sov gott'
    }
  }

  useEffect(() => {
    dataService.getData({ model: 'articles', params: '?filter[0][key]=selectable&filter[0][value]=1&select[0]=id&select[1]=label&select[2]=article_number&pagination=off' })
      .then(data => {
        const { result } = data
        const articles = []
        result.forEach(article => {
          articles.push({ value: article.id, label: article.article_number + ': ' + article.label })
        })
        setOptions(articles)
      })
    getOrders()
  }, [])

  const getOrders = () => {
    if (authenticationService.currentUserValue && authenticationService.currentUserValue.id) {
      const { currentUserValue: { id } } = authenticationService
      dataService.getData({ model: 'orders', params: `?per_page=5&order_by=created_at&filter[0][key]=user_id&filter[0][value]=${id}` })
        .then(
          data => {
            const { result } = data
            setLatest(result)
          },
          error => {
            // console.log(error)
          }
        )
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4' className={classes.header}>{timeOfDay(format(new Date(), 'H'))}, {authenticationService.currentUserValue && authenticationService.currentUserValue.name}</Typography>
        {location.pathname === match.url ? (
          <Typography variant='subtitle1' color='textSecondary' className={classes.header}>Vad vill du beställa idag?</Typography>
        ) : (
          <Link
            to={match.url}
            className={classes.link}
          >
            <Button
              variant='contained'
              color='primary'
            >
              Skapa ny beställning
            </Button>
          </Link>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={8}>
            <Paper>
              <Switch>
                <Route
                  exact
                  path={`${match.url}/mine`}
                  render={props => <AllOrders {...props} />}
                />
                <Route
                  exact
                  path={`${match.url}/mine/:id`}
                  render={props => <Order {...props} />}
                />
                <Route
                  exact
                  path={match.url}
                  render={props => (
                    <Formik
                      initialValues={{
                        order_rows: [{
                          article_id: '',
                          qty: '',
                        }]
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        const { order_rows } = values
                        const payload = {
                          order_rows
                        }
                        dataService.createData({ model: 'orders', values: payload })
                          .then(
                            data => {
                              getOrders()
                              setSubmitting(false)
                              resetForm()
                              history.push({
                                pathname: `${match.url}/mine/${data.id}`
                              })
                            },
                            error => {
                              debugger
                            }
                          )
                      }}
                    >
                      {({
                        submitForm,
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        setFieldTouched
                      }) => (
                        <Form className={classes.form}>
                          <FieldArray
                            name='order_rows'
                            render={arrayHelpers => (
                              <React.Fragment>
                                {values.order_rows && values.order_rows.map((row, index) => (
                                  <div
                                    className={classes.orderRow}
                                    key={index}
                                  >
                                    <div className={classes.select}>
                                      <ReactSelect
                                        name={`order_rows[${index}][article_id]`}
                                        options={options}
                                        placeholder='Väj artikel'
                                        label='Artikel'
                                        onChange={option => setFieldValue(`order_rows[${index}][article_id]`, option.value)}
                                        onBlur={() => setFieldTouched(`order_rows[${index}][article_id]`, true)}
                                        value={options.find(option => option.value === values['order_rows'][index]['article_id'])}
                                        errors={errors}
                                        touched={touched}
                                      // {...console.log('options' ,options)} Delete Icon inuti denna React select funkar ej
                                      />
                                    </div>
                                    <Field
                                      className={classes.qty}
                                      variant='outlined'
                                      margin='normal'
                                      fullWidth
                                      type='number'
                                      label='Antal'
                                      name={`order_rows[${index}][qty]`}
                                      component={TextField}
                                    />
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                ))}
                                <div className={classes.orderRow}>
                                  <div className={classes.select} />
                                  <div className={classes.qty} />
                                  <IconButton
                                    onClick={() => arrayHelpers.push({ article_id: '', qty: '' })}
                                    color='primary'
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </div>
                              </React.Fragment>
                            )}
                          />
                          <Fab
                            variant='extended'
                            aria-label='submit'
                            type='submit'
                            color='primary'
                            disabled={isSubmitting || !values.order_rows || values.order_rows < 1}
                            className={classes.fab}
                          >
                            <SendIcon
                              className={classes.fabIcon}
                            />
                            {isSubmitting && <CircularProgress className={classes.progress} size={45} />}
                            Skapa beställning
                          </Fab>
                        </Form>
                      )}
                    </Formik>
                  )}
                />
              </Switch>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <LatestOrders latest={latest} url={match.url} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useLatestStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'scroll',
    transition: 'transform .2s cubic-bezier(.29,1.09,.74,1.3), opacity .2s ease-in-out',
    height: '100%',
    '&:focus': {
      outline: 0
    }
  },
  headerWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'space-between'
  },
  all: {
    textDecoration: 'none',
    color: 'inherit',
  },
  buttonLabel: {
    whiteSpace: 'nowrap',
    textTransform: 'none'
  },
  header: {
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    marginBottom: theme.spacing(2),
    display: 'block',
    transition: 'transform .2s cubic-bezier(.29,1.09,.74,1.3)',
    '&:hover': {
      transform: 'scale(1.02)'
    }
  },
  skeleton: {
    marginTop: 3,
    marginBottom: 3
  }
}))

const LatestOrders = ({ latest, url }) => {
  const classes = useLatestStyles()

  return (
    <React.Fragment>
      <div className={classes.headerWrapper}>
        <Typography
          variant='h6'
          className={classes.header}
        >
          Tidigare beställningar
        </Typography>
        <Link
          to={`${url}/mine`}
          className={classes.all}
        >
          <Button
            classes={{
              label: classes.buttonLabel
            }}
          >
            Visa alla
          </Button>
        </Link>
      </div>
      {latest.map((order, index) => (
        <Link
          key={order.id}
          to={`${url}/mine/${order.id}`}
          className={classNames(classes.link, index === 4 && classes.lastLink)}
        >
          <Paper className={classes.paper}>
            <List>
              <ListItem>
                <ListItemText
                  primary={`${format(new Date(order.created_at), 'd LLLL yyyy, HH:mm:ss', { locale: sv })}`}
                  secondary={`${order.order_rows && order.order_rows.map(row => `${row.qty}st ${row.article}`)}`}

                />
              </ListItem>
            </List>
          </Paper>
        </Link>
      ))}
    </React.Fragment>
  )
}

const useOrderStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: 70,
    overflow: 'scroll'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  },
  wrapper: {
    width: '100%',
    padding: 24
  },
  list: {
    borderTop: `1px solid ${background}`,
    borderBottom: `1px solid ${background}`,
    padding: 8
  },
  actions: {
    padding: '8px 12px'
  },
  button: {
    margin: 12
  },
  icon: {
    marginRight: 10
  },
  image: {
    width: '100%',
    maxWidth: 300
  },
  to: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const Order = ({ match }) => {
  const classes = useOrderStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [order, setOrder] = useState({})
  const [exporting, setExporting] = useState(false)
  useEffect(() => {
    dataService.getData({ model: `orders/${match.params.id}`, params: '' })
      .then(
        data => {
          setOrder(data)
        }
      )
  }, [match])

  const handleExport = () => {
    setExporting(true)
    const { order_rows } = order
    let find = 'find[0][key]=id&find[0][value]='
    if (order_rows) {
      const findArray = order_rows.map(row => (
        row.id
      ))
      find += `[${findArray}]`
    }
    dataService.getXlsx({ model: 'order_rows.xlsx', params: `?pagination=off&${find}&select[0]=article_id&select[1]=qty` })
      .then(
        data => {
          const filename = `Beställning_${format(new Date(order.created_at), 'yyyyMMddHHmmss')}.xlsx`
          setExporting(false)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof (error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Typography variant='h6'>
          Order
        </Typography>
        <Tooltip
          title='Exportera'
        >
          <IconButton disabled={exporting} onClick={handleExport}>
            {exporting ? (
              <CircularProgress size={24} />
            ) : (
              <ExportFileIcon />
            )}
          </IconButton>
        </Tooltip>
      </Toolbar>
      <List className={classes.list}>
        <ListItem alignItems='flex-start'>
          <ListItemText
            primary={order.created_at && format(new Date(order.created_at), 'd LLLL yyyy, HH:mm:ss', { locale: sv })}
          />
        </ListItem>
      </List>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Artikel
            </TableCell>
            <TableCell>
              Artikelnummer
            </TableCell>
            <TableCell>
              Antal
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.order_rows && order.order_rows.map(row => (
            <TableRow
              key={row.id}
            >
              <TableCell>
                {row.article}
              </TableCell>
              <TableCell>
                {row.article_number}
              </TableCell>
              <TableCell>
                {row.qty}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

const AllOrders = ({ match, history, location }) => {
  const cols = [
    {
      id: 1,
      key: 'created_at',
      visible: true,
      sortable: true,
      label: 'Skapades',
      type: 'date'
    }
  ]
  return (
    <GenericTable
      match={match}
      history={history}
      location={location}
      cols={cols}
      title='Ordrar'
      singular='Order'
      model='orders'
      selector={`?filter[1000][key]=user_id&filter[1000][value]=${authenticationService.currentUserValue && authenticationService.currentUserValue.id}`}
      clickable
    />
  )
}

