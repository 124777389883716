import React from 'react'
import Table from '../Table'

const cols = [
  {
    id: 1,
    key: 'created_at',
    visible: true,
    sortable: true,
    label: 'Skapades',
    type: 'datetime'
  }
]

export default ({
  match,
  history,
  location
}) => (
  <Table
    match={match}
    history={history}
    location={location}
    cols={cols}
    title='Importer'
    singular='Import'
    model='stock_imports'
    defaultSort='order_by=created_at&direction=DESC'
    clickable
  />
)
