import React, { useEffect, useState } from 'react'

import Table from './components/Table'
import { dataService } from './services'

const cols = [
  {
    id: 1,
    key: 'role_id',
    human_key: 'role',
    label: 'Roll',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'select'
  },
  {
    id: 2,
    key: 'model_type',
    label: 'Model',
    type: 'text',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true
  },
  {
    id: 3,
    key: 'read',
    label: 'Läsa',
    type: 'boolean',
    visible: true,
    sortable: true,
    filterable: false,
    edit: true,
    create: true,
    read: true
  },
  {
    id: 4,
    key: 'write',
    label: 'Skriva',
    type: 'boolean',
    visible: true,
    sortable: true,
    filterable: false,
    edit: true,
    create: true,
    read: true
  },
  {
    id: 5,
    key: 'remove',
    label: 'Ta bort',
    type: 'boolean',
    visible: true,
    sortable: true,
    filterable: false,
    edit: true,
    create: true,
    read: true
  }
]

export default ({
  match,
  history,
  location
}) => {
  useEffect(() => {
    dataService.getData({model: 'roles', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        cols.find(col => col.id === 1).options = options
        setEnhanced(cols)
      })
  }, [])

  const [enhanced, setEnhanced] = useState(cols)

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Behörigheter'
      singular='Behörighet'
      model='permissions'
    />
  )
}