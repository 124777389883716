import React, {useEffect, useState} from 'react'
import queryString from 'query-string'
import classNames from 'clsx'
import { VictoryPie } from 'victory'
import CountUp from 'react-countup'

import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'
import Chip from '@material-ui/core/Chip'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Popover from '@material-ui/core/Popover'
import Skeleton from '@material-ui/lab/Skeleton'
import ReactSelect from '../fields/ReactSelect'
import { dataService } from '../../services'
import StoragesIcon from '../../icons/StoragesIcon'
import ArticlesIcon from '../../icons/ArticlesIcon'
import { Dialog, DialogContent } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {

  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  toolbarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  selectWrapper: {
    width: '50%'
  },
  buttonBase: {
    flexDirection: 'column'
  },
  noLink: {
    cursor: 'default'
  },
  pie: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  balance: {
    position: 'absolute'
  },
  overflow: {
    overflow: 'visible'
  },
  popoverRoot: {
  },
  popoverPaper: {
    marginTop: 10,
    minWidth: 300,
    overflow: 'visible',
    padding: 10
  }
}))

export default ({
  match,
  location,
  history,
  articles,
  storages
}) => {
  const classes = useStyles()
  const [graphData, setGraphData] = useState([...Array(6)].map((e, i) => (
    {
      data: [
        { x: '', y: 100},
        { x: '', y: 0}
      ]}
  )))
  const [storageLabel, setStorageLabel] = useState(false)
  const [articleLabel, setArticleLabel] = useState(false)
  const [article, setArticle] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openStorageSelect, setOpenStorageSelect] = useState(false)
  const [openArticleSelect, setOpenArticleSelect] = useState(false)
  const [query, setQuery] = useState({})

  useEffect(() => {
    const a =  articles.find(ar => ar.value === parseInt(article))
    if (Boolean(a)) {
      const { label } = a
      setArticleLabel(label)
    } else {
      setArticleLabel(false)
    }
  }, [articles, article])

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    const tmpQuery = {}
    Object.keys(queryParams).forEach(q => {
      if (queryParams[q] === 'article_id' || queryParams[q] === 'storage_id') {
        const key = q.split('filter[').pop().split('][key]')[0]
        tmpQuery[q] = queryParams[q]
        tmpQuery[`filter[${key}][value]`] = queryParams[`filter[${key}][value]`]
      }
    })
    if (query !== tmpQuery  ) {
      setQuery(tmpQuery)
      const summarize = !Boolean(Object.keys(tmpQuery).some(q => tmpQuery[q] === 'storage_id')) ? 'storages' : 'statuses'
      const params = `?summarize=${summarize}&${queryString.stringify(tmpQuery, {encode: false})}`
      // const params = `?pagination=off&${queryString.stringify(tmpQuery, {encode: false})}`
      dataService.getData({ model: 'items', params})
        .then(
          data => {
            const { total, result, filters } = data
            // const statistics = []
            if (Boolean(Object.keys(tmpQuery).some(q => tmpQuery[q] === 'article_id'))) {
              const i = Object.keys(tmpQuery).find(q => tmpQuery[q] === 'article_id').split('filter[').pop().split('][key]')[0]
              setArticle(tmpQuery[`filter[${i}][value]`])
            } else {
              setArticle(false)
            }
            if (summarize === 'storages') {
              setStorageLabel(false)
            } else {
              const storageLabel = filters && filters.storage && filters.storage.label
              setStorageLabel(storageLabel)
            }
            const statistics = result.map(({ id, label, count }) => {
              const percent = (count / total) * 100
              return {
                id: id,
                label: label,
                balance: count,
                data: [
                  { x: '', y: 100 - percent },
                  { x: '', y: percent }
                ]
              }
            })
            // statistics.sort((a, b) => b.balance > a.balance ? 1 : (a.balance > b.balance ? -1 : 0) )
            setGraphData(statistics)
          }
        )
    }
    // const { article_id, storage_id } = queryParams
    // setQuery(queryParams)
    // const params = `?pagination=off${article_id ? `&filter[0][key]=article_id&filter[0][value]=${article_id}` : ''}${storage_id ? `&filter[1][key]=storage_id&filter[1][value]=${storage_id}` : ''}`
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, articles])

  const groupBy = (list, getter) => {
    const map = new Map()
    list.forEach(item => {
      const key = getter(item)
      const collection = map.get(key)
      if(!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }

  const getArticle = article => {
    const filterQuery = {...query}
    if (Object.keys(filterQuery).some(q => filterQuery[q] === 'article_id')) {
      const i = Object.keys(filterQuery).find(q => filterQuery[q] === 'article_id').split('filter[').pop().split('][key]')[0]
      filterQuery[`filter[${i}][value]`] = article
    } else {
      const i = Object.keys(filterQuery).filter(q => q.includes('[key')).length
      filterQuery[`filter[${i}][key]`] = 'article_id'
      filterQuery[`filter[${i}][value]`] = article
    }
    setOpenArticleSelect(false)
    reRoute(filterQuery)
  }

  const getStorage = storage => {
    const filterQuery = {...query}
    if (Object.keys(filterQuery).some(q => filterQuery[q] === 'storage_id')) {
      const i = Object.keys(filterQuery).find(q => filterQuery[q] === 'storage_id').split('filter[').pop().split('][key]')[0]
      filterQuery[`filter[${i}][value]`] = storage
    } else {
      const i = Object.keys(filterQuery).filter(q => q.includes('[key')).length
      filterQuery[`filter[${i}][key]`] = 'storage_id'
      filterQuery[`filter[${i}][value]`] = storage
    }
    setOpenStorageSelect(false)
    reRoute(filterQuery)
  }

  const handleStorageOpen = event => {
    if (event.currentTarget.nodeName === 'svg') {
      setAnchorEl(event.currentTarget.parentElement)
    } else {
      setAnchorEl(event.currentTarget)
    }
    setOpenStorageSelect(true)
  }

  const handleArticleOpen = event => {
    if (event.currentTarget.nodeName === 'svg') {
      setAnchorEl(event.currentTarget.parentElement)
    } else {
      setAnchorEl(event.currentTarget)
    }
    setOpenArticleSelect(true)
  }

  const removeStorage = () => {
    const filterQuery = {...query}
    const i = Object.keys(filterQuery).find(q => filterQuery[q] === 'storage_id').split('filter[').pop().split('][key]')[0]
    delete filterQuery[`filter[${i}][value]`]
    delete filterQuery[`filter[${i}][key]`]
    reRoute(filterQuery)
  }
  const removeArticle = () => {
    const filterQuery = {...query}
    const i = Object.keys(filterQuery).find(q => filterQuery[q] === 'article_id').split('filter[').pop().split('][key]')[0]
    delete filterQuery[`filter[${i}][value]`]
    delete filterQuery[`filter[${i}][key]`]
    reRoute(filterQuery)
  }

  const reRoute = q => {
    const search = `${queryString.stringify(q, { encode: false})}`
    return history.push({
      pathname: match.url,
      search
    })
  }

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Chip
          component='a'
          label={storageLabel ? storageLabel : 'Alla lager'}
          icon={<StoragesIcon fontSize='small' />}
          onClick={storageLabel ? () => removeStorage() : handleStorageOpen}
          onDelete={storageLabel ? () => removeStorage() : handleStorageOpen}
          color='primary'
          deleteIcon={!storageLabel && <ExpandMoreIcon /> }
          style={{ marginRight: 24 }}
        />
        <Popover 
          open={openStorageSelect}
          classes={{
            root: classes.popoverRoot,
            paper: classes.popoverPaper
          }}
          onClose={() => setOpenStorageSelect(false)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ReactSelect
            dense
            placeholder='Alla lager'
            label='Välj lager'
            options={storages}
            onChange={option => getStorage(option.value)}
          />
        </Popover>
        <Chip
          component='a'
          label={articleLabel ? articleLabel : 'Alla Individer'}
          icon={<ArticlesIcon fontSize='small' />}
          onClick={articleLabel ? () => removeArticle() : handleArticleOpen}
          onDelete={articleLabel ? () => removeArticle() : handleArticleOpen}
          color='secondary'
          deleteIcon={!articleLabel && <ExpandMoreIcon />}
        />
        <Popover 
          open={openArticleSelect}
          classes={{
            root: classes.popoverRoot,
            paper: classes.popoverPaper
          }}
          onClose={() => setOpenArticleSelect(false)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ReactSelect
            dense
            placeholder='Alla individer'
            label='Välj individ'
            options={articles}
            onChange={option => getArticle(option.value)}
          />
        </Popover>
      </Toolbar>    
      <Grid container spacing={2} style={{ padding: '24px 0 48px'}}>
        {graphData.map((storage, index) => (
          <Grid
            item
            xs={index < 2 ? 6 : 4}
            sm={index < 3 ? 4 : 3}
            md={index < 4 ? 3 : 2}
            lg={index < 6 ? 2 : 1}
            key={storage.id}
          >
            <ButtonBase
              className={classNames(classes.buttonBase, { [classes.noLink]: storageLabel })}
              disableRipple
              onClick={!storageLabel ? () => getStorage(storage.id) : undefined}
            >
              <div 
                className={classes.pie}
              >
                <Typography
                  className={classes.balance}
                  variant='h5'
                >
                  <CountUp
                    end={storage.balance}
                    separator=''
                  />
                </Typography>
                <VictoryPie
                  labels={() => null}
                  colorScale={!Boolean(query) || !Boolean(storageLabel) ? [ '#f9f9fc', '#1a76d0' ] : ['#f9f9fc', '#1ad075']}
                  data={storage.data}
                  innerRadius={140}
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 }
                  }}
                />
              </div>
              <Typography
                variant='subtitle2'
                color='textSecondary'
              >
                {storage.label || <Skeleton variant='rect' height={13} width={150} style={{marginTop: 4, marginBottom: 4, marginLeft: 'auto', marginRight: 'auto'}}/>}
              </Typography>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
      {/* <Dialog
        open={openStorageSelect}
        onClose={() => setOpenStorageSelect(false)}
        fullWidth
        maxWidth={'sm'}
        classes={{ 
          paperScrollPaper: classes.overflow
        }}
      >
        <DialogContent
          className={classes.overflow}
        >
        <ReactSelect
          dense
          placeholder='Alla lager'
          label='Välj lager'
          options={storages}
          onChange={option => getStorage(option.value)}
        />
        </DialogContent>
      </Dialog> */}
      {/* <Dialog
        open={openArticleSelect}
        onClose={() => setOpenArticleSelect(false)}
        fullWidth
        maxWidth={'sm'}
        classes={{ 
          paperScrollPaper: classes.overflow
        }}
      >
        <DialogContent
          className={classes.overflow}
        >
        <ReactSelect
          dense
          placeholder='Alla individer'
          label='Välj individ'
          options={articles}
          onChange={option => getArticle(option.value)}
        />
        </DialogContent>
      </Dialog> */}
    </Paper>
  )
}