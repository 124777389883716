import React from 'react'
import Table from '../Table'

const cols = [
  {
    id: 0,
    key: 'find_text',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Söktext',
    type: 'text'
  },
  {
    id: 1,
    key: 'replace_with',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Ersätt med',
    type: 'text'
  },
  {
    id: 2,
    key: 'trim_characters',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Trimma avslutande',
    type: 'number'
  },
  {
    id: 3,
    key: 'is_prefix',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Prefix',
    type: 'boolean',
    default: 1
  },
  {
    id: 4,
    key: 'required_length',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Kodens teckenlängd',
    type: 'text',
  },
  {
    id: 5,
    key: 'label',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Etikett',
    type: 'text',
  },
  {
    id: 6,
    key: 'sort_order',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Prioritet',
    type: 'number'
  }
]

export default ({
  match,
  history,
  location
}) => {
  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={cols}
      title='Streckkodsfilter'
      singular='Streckkodsfilter'
      model='scanner_filters'
    />
  )
}