import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'

import { makeStyles, lighten, withStyles } from '@material-ui/core/styles'
import { Paper, Toolbar, Typography, Fab, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableBody, TableCell, Avatar, IconButton, CircularProgress, Tooltip, Drawer, TablePagination, TableSortLabel, FormControl, FormLabel, FormGroup, Checkbox, FormControlLabel, InputAdornment, Snackbar } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import LinearProgress from '@material-ui/core/LinearProgress'
import ViewIcon from '@material-ui/icons/RemoveRedEye'
import FilterIcon from '@material-ui/icons/FilterList'
import ClearIcon from '@material-ui/icons/Clear'
import KeyboardIcon from '@material-ui/icons/Keyboard'

import { dataService } from '../../../services'
import checkPermissions from '../../../helpers/checkPermissions'
import { Formik, Form, Field } from 'formik'
import CheckboxField from '../../fields/CheckboxField'
import ExportFileIcon from '../../../icons/ExportFileIcon'
import { primary, success, error, warning } from '../../colors'
import TextField from '../../fields/TextField'
import SelectedFilters from '../../SelectedFilters'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import BarcodeIcon from '../../../icons/BarcodeIcon'
import BarcodeField from '../../fields/BarcodeField'
import ReactSelect from '../../fields/ReactSelect'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: 70
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    textDecoration: 'none'
  },
  fabIcon: {
    marginRight: 10
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  },
  tableRow: {
    textDecoration: 'none'
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  drawer: {
    width: 500,
    maxWidth: '80%',
    borderRadius: theme.shape.borderRadius,
    height: 'calc(100% - 40px)',
    margin: 20
  },
  form: {
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  overflow: {
    overflow: 'visible'
  },
  wrapper: {
    width: '100%',
    padding: 24,
    height: '100%'
  },
  action: {
    marginTop: 'auto'
  },
  submit: {
    marginTop: 15
  },
  // form: {
  //   overflowY: 'auto',
  //   display: 'flex',
  //   flexDirection: 'column'
  // },
  success: {
    backgroundColor: success,
  },
  error: {
    backgroundColor: error
  },
  pending: {
    backgroundColor: warning
  },
  circle: {
    display: 'inline-flex'
  },
  skeleton: {
    margin: '2px 0 1px'
  }
}))

export default ({
  match,
  history,
  location
}) => {

  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()
  const params = queryString.parse(location.search)
  const usedFilters = {}
  const selectedFilterValues = {}
  Object.keys(params).forEach(q => {
    if (q.includes('[key') && !q.includes('[1000]')) {
      if (q.includes('between[')) {
        const key = q.split('between[').pop().split('][key]')[0]
        const value = []
        let string = ''
        JSON.parse(params[`between[${key}][value]`]).forEach((date, index) => {
          if (index > 0) {
            string = `${string} - `
          }
          if (date === '1970-01-01' || date === '3000-01-01') {
            value.push(null)
            string = `${string}...`
          } else {
            value.push(date)
            string = `${string}${date}`
          }
        })
        selectedFilterValues[params[q]] = string
        usedFilters[params[q]] = value
      }
      if (q.includes('find[')) {
        const key = q.split('find[').pop().split('][key]')[0]

        selectedFilterValues[params[q]] = params[`find[${key}][value]`]
        usedFilters[params[q]] = JSON.parse(params[`find[${key}][value]`])
      }
    }
  })

  const [exporting, setExporting] = useState(false)
  const [storages, setStorages] = useState([])
  const [createActive, setCreateActive] = useState(false)
  const [storageLocations, setStorageLocations] = useState([])
  const [stocktakings, setStocktakings] = useState({ result: [], total: 0 })
  const [filterOpen, setFilterOpen] = useState(false)
  const [query, setQuery] = useState(params)
  const [filters, setFilters] = useState(usedFilters)
  const [loading, setLoading] = useState(false)
  const [scannerMode, setScannerMode] = useState(true)
  const [singleExport, setSingleExport] = useState(null)
  const [snackbarItem, setSnackbarItem] = useState({})
  const [cols, setCols] = useState([
    {
      key: 'created_at',
      label: 'Datum'
    }, {
      key: 'storage_id',
      human_key: 'storage',
      label: 'Lager',
      array: true
    }
  ])

  useEffect(() => {
    dataService.getData({ model: 'storages', params: '?pagination=off' })
      .then(
        data => {
          const { result } = data
          const options = []
          result.forEach(option => {
            options.push({ value: option.id, label: option.label })
          })
          setStorages(options)
          setCols(
            [{
              key: 'created_at',
              label: 'Datum'
            }, {
              key: 'storage_id',
              human_key: 'storage',
              label: 'Lager',
              array: true,
              options
            }]
          )
        }
      )
  }, [])

  useEffect(() => {
    setLoading(true)
    dataService.getData({ model: 'stocktakings', params: `?filter[0][key]=stocktaking_group_id&filter[0][value]=NULL&${queryString.stringify(params)}` })
      .then(data => {
        setStocktakings(data)
        setQuery(params)
        setFilters(usedFilters)
        setLoading(false)
      })
  }, [location.search])

  const getStorageByCode = ({ ean, setFieldValue, submitForm, storages }) => {
    dataService.getData({ model: 'storage_locations', params: `?filter[0][key]=ean&filter[0][value]=${ean}` })
      .then(data => {
        const { result } = data
        if (result.length > 0) {
          const options = []
          result.forEach(option => {
            options.push({ value: option.id, label: option.label })
          })
          setStorageLocations(options)
          const storage = storages.find(st => st.value === result[0].storage_id)
          setFieldValue('storage_id', storage.value)
          setFieldValue('storage_location_id', result[0].id)
          submitForm()
        }
      })
  }

  const getStorageLocations = storage_id => {
    setStorageLocations([])
    dataService.getData({ model: 'storage_locations', params: `?filter[0][key]=storage_id&filter[0][value]=${storage_id}&pagination=off` })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        setStorageLocations(options)
      })
  }

  const handleExport = () => {
    const { created_at, storage_id } = filters
    const from = created_at ? (created_at[0] || '1970-01-01') : '1970-01-01'
    const to = created_at ? (created_at[1] || '3000-01-01') : '3000-01-01'
    dataService.getXlsx({ model: 'stocktakings_summary.xlsx', params: `?pagination=off&group_id=NULL&from=${from}&to=${to}&storages=[${storage_id}]` })
      .then(
        data => {
          const filename = `Inventeringar_${format(new Date(from), 'yyyyMMddHH')}-${format(new Date(to), 'yyyyMMddHH')}.xlsx`
          setExporting(false)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof (error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }

  const handleFilter = values => {
    setFilterOpen(false)
    let search = ''
    if (values.created_at_after || values.created_at_before) {
      search = `between[0][key]=created_at&between[0][value]=["${values.created_at_after || '1970-01-01'}", "${values.created_at_before || '3000-01-01'}"]`
    }
    if (values.storages) {
      const ids = values.storages.map(storage => storage.value)
      search = search.length ? `${search}&find[0][key]=storage_id&find[0][value]=[${ids}]` : `find[0][key]=storage_id&find[0][value]=[${ids}]`
    }
    return history.push({
      pathname: match.url,
      search
    })
  }

  const handleRemoveFilter = filter => {
    const keyValue = Object.keys(query).find(key => query[key] === filter)
    const valueKey = keyValue.replace('key', 'value')
    const filterQuery = { ...query }
    delete filterQuery[keyValue]
    delete filterQuery[valueKey]
    reRoute(filterQuery)
  }

  const handleClearFilter = () => {
    setFilterOpen(false)
    const allFilters = Object.keys(query).filter(key => key.includes('[key]') || key.includes('[value]'))
    const filterQuery = { ...query }
    allFilters.forEach(filter => {
      delete filterQuery[filter]
    })
    reRoute(filterQuery)
  }

  const handleSort = property => {
    const direction = query['order_by'] === property && query.direction !== 'asc' ? 'asc' : 'desc'
    const sortQuery = { ...query, order_by: property, direction, page: 1 }
    reRoute(sortQuery)
  }

  const handleChangePage = (event, newPage) => {
    const sortQuery = { ...query, page: newPage + 1 }
    reRoute(sortQuery)
  }

  const handleChangeRowsPerPage = event => {
    const sortQuery = { ...query, per_page: event.target.value }
    reRoute(sortQuery)
  }

  const reRoute = q => {
    const search = `?${queryString.stringify(q, { encode: false })}`
    return history.push({
      pathname: match.url,
      search
    })
  }

  const handleSelectAll = (event, setFieldValue) => {
    storages.forEach(storage => {
      setFieldValue(storage.id, event.target.checked)
    })
  }

  const handleSingleExport = (id, storage, created_at) => {
    setSingleExport(id)
    dataService.getXlsx({ model: 'stocktakings_summary.xlsx', params: `?stocktaking_id=${id}` })
      .then(
        data => {
          const filename = `${storage}_${format(new Date(created_at), 'yyyyMMddHHmmss')}.xlsx`
          setSingleExport(null)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof (error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }

  const handleDelete = stocktaking => {
    dataService.removeData({ model: `stocktakings/${stocktaking.id}`, params: '' })
      .then(
        () => {
          setSnackbarItem(stocktaking)
          setStocktakings(prev => {
            return {
              ...prev,
              total: prev.total - 1,
              result: prev.result.filter(({ id }) => id !== stocktaking.id)
            }
          })
        },
        () => {
          // Handle error
        }
      )
  }

  const handleUndo = () => {
    const item = snackbarItem
    dataService.editData({ model: `stocktakings/${item.id}`, values: { deleted_at: null } })
      .then(
        () => {
          setSnackbarItem({})
          setStocktakings(prev => {
            const result = [...prev.result]
            result.splice(item.index, 0, item)
            return {
              ...prev,
              total: prev.total + 1,
              result,
            }
          })
        },
        () => {
          // Handle error
        }
      )
  }

  const { result, total } = stocktakings

  return (
    <React.Fragment>
      {Object.keys(filters).length > 0 && (
        <SelectedFilters filters={selectedFilterValues} cols={cols} onDelete={filter => handleRemoveFilter(filter)} />
      )}
      <Paper
        className={classes.paper}
      >
        <Toolbar className={classes.toolbar}>
          <React.Fragment>
            <Typography variant='h6'>
              Inventeringar
            </Typography>
            <div>
              <Tooltip title='Exportera' aria-label='export'>
                <IconButton disabled={exporting} onClick={() => handleExport()}>
                  {
                    exporting ? (
                      <CircularProgress size={24} />
                    ) : (
                      <ExportFileIcon />
                    )
                  }
                </IconButton>
              </Tooltip>
              <Tooltip title='Filtrera' aria-label='filter'>
                <IconButton onClick={() => setFilterOpen(true)}>
                  <FilterIcon />
                </IconButton>
              </Tooltip>
            </div>
          </React.Fragment>
        </Toolbar>
        <Table
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel
                  active={query['order_by'] === 'status'}
                  direction={query['direction'] || 'desc'}
                  onClick={() => handleSort('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={query['order_by'] === 'storage_id'}
                  direction={query['direction'] || 'desc'}
                  onClick={() => handleSort('storage_id')}
                >
                  Lager
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={query['order_by'] === 'storage_location_id'}
                  direction={query['direction'] || 'desc'}
                  onClick={() => handleSort('storage_location_id')}
                >
                  Lagerplats
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={query['order_by'] === 'created_at'}
                  direction={query['direction'] || 'desc'}
                  onClick={() => handleSort('created_at')}
                >
                  Skapades
                </TableSortLabel>
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result.length > 0 ? (
              result.map((stocktaking, index) => {
                const statusObject = () => {
                  switch (stocktaking.status) {
                    case 'failed':
                      return {
                        label: 'Genomförd med fel',
                        avatar: () => (
                          <Avatar className={classes.error}>
                            <ThumbDownAltIcon />
                          </Avatar>)
                      }
                    case 'ok':
                      return {
                        label: 'Genomförd',
                        avatar: () => (
                          <Avatar className={classes.success}>
                            <ThumbUpAltIcon />
                          </Avatar>)
                      }
                    default:
                      return {
                        label: 'Ej genomförd',
                        avatar: () => (
                          <Avatar className={classes.pending}>
                            <HourglassEmptyIcon />
                          </Avatar>)
                      }
                  }
                }
                return (
                  <TableRow
                    key={stocktaking.id}
                    className={classes.tableRow}
                  >
                    <TableCell>
                      {!loading ? (
                        statusObject().avatar()
                      ) : (
                        <Skeleton variant='circle' width={43} height={43} />
                      )}
                    </TableCell>
                    <TableCell>
                      {!loading ? (
                        statusObject().label
                      ) : (
                        <Skeleton variant='rect' className={classes.skeleton} />
                      )}
                    </TableCell>
                    <TableCell>
                      {!loading ? (
                        stocktaking.storage
                      ) : (
                        <Skeleton variant='rect' className={classes.skeleton} />
                      )}
                    </TableCell>
                    <TableCell>
                      {!loading ? (
                        stocktaking.storage_location
                      ) : (
                        <Skeleton variant='rect' className={classes.skeleton} />
                      )}
                    </TableCell>
                    <TableCell>
                      {!loading ? (
                        format(new Date(stocktaking.created_at), 'd LLLL yyyy, HH:mm:ss', { locale: sv })
                      ) : (
                        <Skeleton variant='rect' className={classes.skeleton} />
                      )}
                    </TableCell>
                    <TableCell align='right'>
                      {!loading ? (
                        <React.Fragment>
                          <Tooltip title='Exportera' aria-label='export-single'>
                            <IconButton
                              disabled={singleExport === stocktaking.id}
                              onClick={() => handleSingleExport(stocktaking.id, stocktaking.storage, stocktaking.created_at)}>
                              {singleExport === stocktaking.id ? (
                                <CircularProgress size={24} />
                              ) : (
                                <ExportFileIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Link
                            to={`${match.url}/${stocktaking.id}`}
                          >
                            <Tooltip title='Visa' aria-label='read'>
                              <IconButton>
                                <ViewIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          {checkPermissions({ model: 'stocktakings', type: 'remove' }) && (
                            <Tooltip title='Ta bort' aria-label='delete'>
                              <IconButton onClick={() => handleDelete({ ...stocktaking, index })}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Skeleton variant='circle' width={43} height={43} className={classes.circle} style={{ marginRight: 10 }} />
                          <Skeleton variant='circle' width={43} height={43} className={classes.circle} />
                        </React.Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <React.Fragment>
                {!loading ? (
                  <TableRow />
                ) : (
                  [...Array(20)].map((e, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Skeleton variant='circle' width={43} height={43} className={classes.circle} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='rect' className={classes.skeleton} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='rect' className={classes.skeleton} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='rect' className={classes.skeleton} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='rect' className={classes.skeleton} />
                      </TableCell>
                      <TableCell align='right'>
                        <Skeleton variant='circle' width={43} height={43} className={classes.circle} style={{ marginRight: 10 }} />
                        <Skeleton variant='circle' width={43} height={43} className={classes.circle} />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
        <TablePagination
          // rowsPerPageOptions={[20, 50, { value: total, label: 'Visa alla' }]}
          rowsPerPageOptions={[20, 50, 200, 500]}
          labelRowsPerPage='Rader per sida'
          component='div'
          count={total}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} av ${count}`}
          rowsPerPage={query.per_page ? query.per_page : 20}
          page={query.page ? query.page - 1 : 0}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        open={createActive}
        onClose={() => setCreateActive(false)}
        maxWidth='sm'
        fullWidth={true}
        classes={{
          paper: classes.overflow
        }}
      >
        <Formik
          initialValues={{}}
          enableReinitialize
          onSubmit={(values, { setSubmitting, setStatus }) => {
            dataService.createData({ model: 'stocktakings', values })
              .then(
                data => {
                  const { id } = data
                  history.push(`${match.url}/${id}`)
                },
                error => {
                  setSubmitting(false)
                  if (Array.isArray(error)) {
                    error.forEach(err => {
                      enqueueSnackbar(err.message || err.type, { variant: 'error' })
                    })
                  } else {
                    if (typeof (error) === 'object') {
                      error = 'Systemfel, kontakta support'
                    }
                    enqueueSnackbar(error, { variant: 'error' })
                  }
                }
              )
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            submitForm
          }) => (
            <Form
              className={clsx(classes.form, classes.overflow)}
            >
              <DialogTitle>Välj lager & lagerplats</DialogTitle>
              <DialogContent
                className={classes.overflow}
                dividers
              >
                <Field
                  name='barcode'
                  label='Skanna lagerplats'
                  variant='outlined'
                  margin='normal'
                  autoFocus
                  type='text'
                  fullWidth
                  onComplete={scannerMode ? data => getStorageByCode({ ean: data, setFieldValue, submitForm, storages }) : undefined}
                  InputProps={{
                    inputProps: {
                      onBlur: !scannerMode ? () => getStorageByCode({ ean: values.barcod, setFieldValue, submitForm, storages }) : undefined
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setScannerMode(!scannerMode)}
                        >
                          {scannerMode ? (
                            <BarcodeIcon />
                          ) : (
                            <KeyboardIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  component={scannerMode ? BarcodeField : TextField}
                />
                <Typography
                  variant='subtitle1'
                  color='primary'
                  style={{
                    textAlign: 'center'
                  }}
                >
                  eller
                </Typography>

                <ReactSelect
                  name='storage_id'
                  placeholder='Välj lager'
                  label='Inventera lager'
                  options={storages}
                  onChange={option => {
                    getStorageLocations(option ? option.value : null)
                    return setFieldValue('storage_id', option ? option.value : null)
                  }}
                  onBlur={() => setFieldTouched('storage_id', true)}
                  errore={errors}
                  touched={touched}
                />
                <ReactSelect
                  name='storage_location_id'
                  placeholder='Välj lagerplats'
                  options={storageLocations}
                  onChange={option => setFieldValue('storage_location_id', option ? option.value : null)}
                  onBlur={() => setFieldTouched('storage_location_id', true)}
                  errors={errors}
                  touched={touched}
                  isDisabled={!values['storage_id'] || !storageLocations.length}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color='secodary'
                  onClick={() => setCreateActive(false)}
                >
                  Avbryt
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  aria-label='submit'
                  color='primary'
                  disabled={isSubmitting}
                >
                  Nästa
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Drawer
        classes={{
          paper: classes.drawer
        }}
        anchor='right'
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant='h6'>
            Filtrera
          </Typography>
          <IconButton onClick={() => setFilterOpen(false)}>
            <ClearIcon />
          </IconButton>
        </Toolbar>
        <div className={classes.wrapper}>
          <Formik
            initialValues={{
              created_at_after: filters['created_at'] && filters['created_at'][0],
              created_at_before: filters['created_at'] && filters['created_at'][1],
              storages: filters['storage_id'] && filters['storage_id'].map(storage => storages.find(st => storage === st.value))
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => handleFilter(values)}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              setFieldTouched
            }) => (
              <Form className={classes.form}>
                <Field
                  variant='outlined'
                  type='date'
                  margin='normal'
                  name='created_at_after'
                  fullWidth
                  component={TextField}
                  label='Efter datum'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <Field
                  variant='outlined'
                  type='date'
                  margin='normal'
                  name='created_at_before'
                  fullWidth
                  component={TextField}
                  label='Före datum'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <ReactSelect
                  name='storages'
                  options={storages}
                  placeholder='Välj lager'
                  label='Lager'
                  onChange={option => {
                    setFieldValue('storages', option && option.length > 0 ? option.map(obj => (obj)) : [])
                  }}
                  onBlur={() => setFieldTouched('storages', true)}
                  isMulti
                  errors={errors}
                  touched={touched}
                  value={values['storages']}
                />
                <div className={classes.action}>
                  <Button
                    size='large'
                    fullWidth
                    variant='outlined'
                    disabled={isSubmitting}
                    color='primary'
                    onClick={() => handleClearFilter()}
                  >
                    Rensa filter
                  </Button>
                  <Button
                    className={classes.submit}
                    type='submit'
                    size='large'
                    fullWidth
                    variant='contained'
                    disabled={isSubmitting}
                    color='primary'
                  >
                    Filtrera
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackbarItem.id}
        autohideDuration={5000}
        onClose={() => setSnackbarItem({})}
        message={'Gruppinventering har tagits bort'}
        action={
          <React.Fragment>
            <Button color='primary' size='small' onClick={handleUndo} noMargin>
              Ångra
            </Button>
            <IconButton size='small' aria-label='close' color='inherit' onClick={() => setSnackbarItem({})}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </React.Fragment>
        }
      />
      <Fab
        variant='extended'
        aria-label='submit'
        color='primary'
        className={classes.fab}
        onClick={() => setCreateActive(true)}
      >
        <AddIcon className={classes.fabIcon} />
        Inventering
      </Fab>
    </React.Fragment>
  )
}