import React from 'react'

export default ({type, fill='none', stroke = '#FFFFFF', size = 26, strokeWidth = 0.25}) => {
  switch (type) {
    case 'plain':
      return (
        <svg
          version='1.1'
          id='Lager_1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          x='0px'
          y='0px'
          viewBox='0 0 17.5 18'
          style={{enableBackground: 'new 0 0 17.5 18'}}
          xmlSpace='preserve'
          width={size}
          height={size}
        >
          <polyline
            style={{
              fill,
              stroke,
              strokeWidth: strokeWidth,
              strokeMiterlimit: '10'
            }}
            points='10.8,0.1 10.8,18.1 0.2,18.1 6.8,4.1 8.8,0.1 6.8,4.1 4.9,4.1 4.9,2.1 8.8,0.1 10.8,0.1 17.7,18.1 
          10.8,18.1 '
          />
        </svg>
      )
    default: 
      return (
        <svg
          version='1.1'
          id='Lager_1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          x='0px'
          y='0px'
          viewBox='0 0 17.5 18'
          style={{enableBackground: 'new 0 0 17.5 18'}}
          xmlSpace='preserve'
          width={size}
          height={size}
        >
        <linearGradient
          id='SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1='5.9866'
          y1='1.6067'
          x2='8.4634'
          y2='2.9598'
        >
          <stop 
            offset='1.161859e-03'
            style={{stopColor:'#186ABC'}}
          />
          <stop 
            offset='0.9996'
            style={{stopColor:'#020B14'}}
          />
        </linearGradient>
        <polygon style={{fill: 'url(#SVGID_1_)'}} points='6.8,4 4.8,4 4.8,2 8.6,0 '/>
        <linearGradient
          id='SVGID_2_'
          gradientUnits='userSpaceOnUse'
          x1='0.4525'
          y1='8.2788'
          x2='10.9776'
          y2='9.8383'
        >
          <stop  offset='0.3981' style={{stopColor:'#186ABC'}}/>
          <stop  offset='1' style={{stopColor:'#0A2F53'}}/>
        </linearGradient>
        <polygon style={{fill: 'url(#SVGID_2_)'}} points='8.6,0 8.6,0 10.6,0 10.6,18 0,18 6.6,4 '/>
        <linearGradient
          id='SVGID_3_'
          gradientUnits='userSpaceOnUse'
          x1='6.641'
          y1='4.1691'
          x2='6.641'
          y2='4.1691'
        >
          <stop 
            offset='0'
            style={{stopColor:'#2581C4'}}
          />
          <stop
          offset='1'
          style={{stopColor:'#2581C4'}}
          />
        </linearGradient>
        <path style={{fill: 'url(#SVGID_3_)'}} d='M6.6,4.2'/>
        <linearGradient
          id='SVGID_4_'
          gradientUnits='userSpaceOnUse'
          x1='10.8483'
          y1='9'
          x2='15.4169'
          y2='9'
        >
          <stop
          offset='0'
          style={{stopColor:'#1B76D1'}}
          />
          <stop 
            offset='1'
            style={{stopColor:'#155EA7'}}
          />
        </linearGradient>
        <polyline style={{fill: 'url(#SVGID_4_)'}} points='10.6,18 17.5,18 10.6,0 '/>
        <linearGradient
          id='SVGID_5_'
          gradientUnits='userSpaceOnUse'
          x1='10.64'
          y1='9'
          x2='10.64'
          y2='9'
        >
          <stop 
            offset='0'
            style={{stopColor:'#2581C4'}}
          />
          <stop
            offset='1'
            style={{stopColor:'#2581C4'}}
          />
        </linearGradient>
        <line style={{fill: 'url(#SVGID_5_)'}} x1='10.6' y1='0' x2='10.6' y2='18'/>
        <linearGradient
          id='SVGID_6_'
          gradientUnits='userSpaceOnUse'
          x1='8.6429'
          y1='0'
          x2='10.6353'
          y2='0'
        >
          <stop 
            offset='0'
            style={{stopColor:'#2581C4'}}
          />
          <stop 
            offset='1'
            style={{stopColor:'#2581C4'}}
          />
        </linearGradient>
        <line style={{fill: 'url(#SVGID_6_)'}} x1='8.6' y1='0' x2='10.6' y2='0'/>
        </svg>
      )
  }
}
