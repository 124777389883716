import React from 'react'

import Table from './components/Table'
import { dataService } from './services'

const cols = [
  {
    id: 1,
    key: 'label',
    label: 'Titel',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'text'
  }
]

export default ({
  match,
  history,
  location
}) => {
  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={cols}
      title='Roller'
      singular='Roll'
      model='roles'
    />
  )
}