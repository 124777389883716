import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export default ({
  field: { ...fields },
  label,
  required,
  handleToggle,
  form: { touched, errors, ...rest },
  ...props
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={handleToggle}
          onBlur={handleToggle}
          {...props}
        />
      }
      label={label}
    />
  )
}