import React from 'react'
import { Route, Link, Switch } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Items from './components/Values/Items'
import Articles from './components/Values/Articles'
const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    marginBottom: 24,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  }
}))

export default ({
  match,
  location
}) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <div className={classes.root}>
      <StyledTabs value= {`/${location.pathname.split(`${match.url}/`).pop().split('/').shift()}`}>
          <StyledTab label='Standarvärden' value={'/'} to={match.url} component={Link} />
          <StyledTab label='Lagervärden' value={'/items'} component={Link} to={`${match.url}/items`} />
        </StyledTabs>
      </div>
      <Switch>
        <Route path={`${match.url}/items`} render={props => <Items {...props} />} />
        <Route path={match.url} render={props => <Articles {...props} />} />
      </Switch>
    </React.Fragment>
  )
}
