import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'
import { Formik, Form, Field, FieldArray } from 'formik'

import {
  makeStyles,
  lighten,
  withStyles
} from '@material-ui/core/styles'

import {
  Paper,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  InputAdornment,
  ListItemAvatar,
  Breadcrumbs,
  Tooltip,
  CircularProgress
} from '@material-ui/core'

import KeyboardIcon from '@material-ui/icons/Keyboard'
import LinearProgress from '@material-ui/core/LinearProgress'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import ReplayIcon from '@material-ui/icons/Replay'
import HomeIcon from '@material-ui/icons/Home'

import { dataService } from '../../../services'

import Barcode from '../../../icons/BarcodeIcon'
import { primary, background } from '../../colors'
import TextField from '../../fields/TextField'
import BarcodeField from '../../fields/BarcodeField'
import Button from '../../Button'
import Skeleton from '@material-ui/lab/Skeleton'
import ExportFileIcon from '../../../icons/ExportFileIcon'

import StockTakingResult from '../StockTakingResult'

const TabPanel = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && children}
  </div>
)

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    minWidth: 'auto',
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />)

const BorderLinearProgress = withStyles({
  root: {
    height: 20,
    width: '100%',
    backgroundColor: lighten(primary, 0.95),
    borderRadius: 5
  },
  bar: {
    // borderRadius: 20,
    backgroundColor: primary,
  },
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: 70
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    textDecoration: 'none'
  },
  fabIcon: {
    marginRight: 10
  },
  tableRow: {
    textDecoration: 'none'
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  showQuantity: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10
  },
  quantity: {
    marginRight: 10
  },
  wrapper: {
    width: '100%',
    padding: 24,
    height: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  list: {
    borderTop: `1px solid ${background}`,
    borderBottom: `1px solid ${background}`,
    padding: 8
  },
  action: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  circle: {
    display: 'inline-flex'
  },
  skeleton: {
    margin: '2px 0 1px'
  },
  breadcrumb: {
    display: 'flex',
    textDecoration: 'none',
    color: 'initial'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  },
}))

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

  return [htmlElRef, setFocus]
}

export default ({
  match,
  history,
  location
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [createActive, setCreateActive] = useState(false)
  const [storages, setStorages] = useState([])
  const [stocktaking, setStocktaking] = useState({})
  const [scannerMode, setScannerMode] = useState(true)
  const [inputRef, setInputFocus] = useFocus()
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(true)
  const [exporting, setExporting] = useState(false)
  const [statusId, setStatusId] = useState(0)


  useEffect(() => {
    setLoading(true)
    dataService.getData({ model: `stocktakings/${match.params.location}`, params: '' })
      .then(data => {
        setStocktaking(data)
        setStatusId(data.statuses.matched > 0
          ? 1
          : data.statuses.missing > 0
            ? 5
            : data.statuses.mismatched > 0
              ? 2
              : data.statuses.unavailable > 0
                ? 4
                : 0
        )
        // console.log(data)
        setLoading(false)
      })
  }, [match.params.location, location.search])

  const setItem = (item, arrayHelpers, setFieldValue, type = 'ean') => {
    const { form: { values: { items } } } = arrayHelpers

    if (item && item.length > 4) {
      if (items && items.find(i => i.ean === item && i.type === type)) {
        setFieldValue('item', '')
        // console.log(item)
        enqueueSnackbar('Redan scannad', { variant: 'error' })
        return
      }

      const newItem = {
        ean: item,
        type: type,
      }
      arrayHelpers.push(newItem)
      setFieldValue('item', '')
    } else if (item && item.length <= 4) {
      enqueueSnackbar('Ingen giltig streckkod', { variant: 'error' })
    }
    setInputFocus()
    return
  }
  const setBox = (box, arrayHelpers, setFieldValue) => {
    setItem(box, arrayHelpers, setFieldValue, 'box')
    return
  }
  const setPallet = (pallet, arrayHelpers, setFieldValue) => {
    setItem(pallet, arrayHelpers, setFieldValue, 'pallet')
    return
  }

  const getItems = (value, key, arrayHelpers, setFieldValue) => {
    if (key === 'box' && value.length > 0) {
      dataService.getData({ model: 'items', params: `?filter[0][key]=${key}&filter[0][value]=${value && value.replace(String.fromCharCode(16), "")}&per_page=0` })
        .then(data => {
          const { total } = data
          // console.log(total)
          // setFieldValue('box', '')
          setInputFocus()
          if (total) {
            // console.log(total)
            return setBox(value, arrayHelpers, setFieldValue)
            // result.map(box => {
            //   const {
            //     serial_number,
            //   } = box
            // })
          } else {
            enqueueSnackbar('Lådan finns inte', { variant: 'error' })
          }
        })
    } else if (key === 'pallet' && value.length > 0) {
      dataService.getData({ model: 'items', params: `?filter[0][key]=${key}&filter[0][value]=${value && value.replace(String.fromCharCode(16), "")}&per_page=0` })
        .then(data => {
          const { total } = data
          // setFieldValue('pallet', '')
          setInputFocus()
          if (total) {
            return setPallet(value, arrayHelpers, setFieldValue)
            // result.map(pallet => {
            //   const {
            //     serial_number,
            //   } = pallet
            // })
          } else {
            enqueueSnackbar('Pallen finns inte', { variant: 'error' })
          }
        })
    }
  }

  const tabProps = index => (
    {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    }
  )

  const tabChange = (e, newValue) => {
    setValue(newValue)
  }

  const restart = from => {
    dataService.createData({ model: `/stocktakings/${from}/restart` })
      .then(
        data => {
          const { id } = data
          return history.push(`${match.url.substr(0, match.url.lastIndexOf("/"))}/${id}`)
        }
      )
  }

  const handleExport = () => {
    setExporting(true)
    dataService.getXlsx({ model: 'stocktakings_summary.xlsx', params: `?stocktaking_id=${stocktaking.id}` })
      .then(
        data => {
          const filename = `${stocktaking.storage}_${format(new Date(stocktaking.created_at), 'yyyyMMddHHmmss')}.xlsx`
          setExporting(false)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof (error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }
  const tabResult = (e, value) => {
    setStatusId(value)
    // console.log(value)
  }

  return (
    <React.Fragment>
      <Breadcrumbs style={{ paddingBottom: 8 }} separator={<NavigateNextIcon fontSize="small" />}>
        <Link
          className={classes.breadcrumb}
          to={match.params.id ? '/admin/items/stock-taking' : '/admin/items/stock-taking/singles'}
        >
          <HomeIcon className={classes.icon} />
        </Link>
        {match.params.id && (
          <Link
            className={classes.link}
            style={{ color: 'initial' }}
            to={`/admin/items/stock-taking/${match.params.id}`}
          >
            Grupp
          </Link>
        )}
        <Typography color='textSexondary'>
          Inventering
        </Typography>
      </Breadcrumbs>
      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <Typography variant='h6'>
            Inventering
          </Typography>
          {!loading && stocktaking.status !== 'pending' ? (
            <Tooltip title='Exportera' aria-label='export'>
              <IconButton disabled={exporting} onClick={() => handleExport()}>
                {exporting ? (
                  <CircularProgress size={24} />
                ) : (
                  <ExportFileIcon />
                )}
              </IconButton>
            </Tooltip>
          ) : loading ? (
            <Skeleton variant='circle' width={43} height={43} className={classes.circle} />
          ) : (<></>)}
        </Toolbar>
        <List className={classes.list}>
          <ListItem alignItems='flex-start'>
            <ListItemText
              primary={
                !loading ? (
                  <React.Fragment>
                    {stocktaking.storage_location && (
                      `${stocktaking.storage_location}, `
                    )}
                    {stocktaking.storage}
                  </React.Fragment>
                ) : (
                  <Skeleton variant='rect' width={150} height={20} className={classes.skeleton} />
                )
              }
              secondary={
                <React.Fragment>
                  {loading && (
                    <React.Fragment>
                      <Skeleton variant='rect' width={250} height={16} className={classes.skeleton} />
                      <Skeleton variant='rect' width={200} height={16} className={classes.skeleton} />
                    </React.Fragment>
                  )}
                  {!loading && stocktaking.created_at && (
                    <Typography variant='body2'>
                      {`Skapades av ${stocktaking.created_by_user && stocktaking.created_by_user.name} ${format(new Date(stocktaking.created_at), 'd LLLL yyyy, HH:mm:ss', { locale: sv })}`}
                    </Typography>
                  )}
                  {!loading && stocktaking.submitted_at && (
                    <Typography variant='body2'>
                      {`Genomfördes av ${stocktaking.submitted_by_user && stocktaking.submitted_by_user.name} ${format(new Date(stocktaking.submitted_at), 'd LLLL yyyy, HH:mm:ss', { locale: sv })}`}
                    </Typography>
                  )}

                </React.Fragment>
              }
            />
            <ListItemAvatar>
              {stocktaking.status !== 'pending' && !loading && (
                <Button
                  startIcon={<ReplayIcon />}
                  color='primary'
                  style={{ margin: 0 }}
                  onClick={() => restart(stocktaking.id)}
                >
                  Gör om
                </Button>
              )}
            </ListItemAvatar>
          </ListItem>
        </List>
        <div className={classes.wrapper}>
          {stocktaking.status === 'pending' ? (
            <Formik
              initialValues={{
                items: []
              }}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                const items = values.items.filter(i => i.type === 'ean').map(i => i.ean)
                const boxes = values.items.filter(i => i.type === 'box').map(i => i.ean)
                const pallets = values.items.filter(i => i.type === 'pallet').map(i => i.ean)

                dataService.createData({
                  model: `stocktakings/${stocktaking.id}/entries`, values:
                  {
                    eans: items,
                    boxes: boxes,
                    pallets: pallets,
                  }
                })
                  .then(
                    data => {
                      setStocktaking(data)
                      setStatusId(data.statuses.matched > 0
                        ? 1
                        : data.statuses.missing > 0
                          ? 5
                          : data.statuses.mismatched > 0
                            ? 2
                            : data.statuses.unavailable > 0
                              ? 4
                              : 0
                      )
                    },
                    error => {
                      setSubmitting(false)
                    }
                  )
              }}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue
              }) => (
                <Form>
                  <FieldArray
                    name='items'
                    render={arrayHelpers => {

                      const eans = values.items.filter(i => i.type === 'ean').map(i => i.ean)
                      const boxes = values.items.filter(i => i.type === 'box').map(i => i.ean)
                      const pallets = values.items.filter(i => i.type === 'pallet').map(i => i.ean)

                      return (
                        <React.Fragment>

                          {values.items.map((item, index) => (
                            <div
                              key={index}
                              style={{ display: 'flex' }}
                            >
                              <Field
                                name={`items[${index}].ean`}
                                label={item.type === 'pallet'
                                  ? 'Pallnummer'
                                  : item.type === 'box'
                                    ? 'Lådnummer'
                                    : 'Streckkod'
                                }
                                disabled
                                variant='outlined'
                                margin='dense'
                                type='text'
                                fullWidth
                                component={TextField}
                              />
                            </div>
                          ))}

                          <div className={classes.showQuantity}>
                            {eans && Boolean(eans.length === 1) ? (
                              <Typography className={classes.quantity} variant='body2' color='textSecondary'>{eans.length} Individ</Typography>
                            ) : eans && Boolean(eans.length > 1) ? (<Typography className={classes.quantity} variant='body2' color='textSecondary'>{eans.length} Individer</Typography>) : (<></>)}

                            {pallets && Boolean(pallets.length === 1) ? (
                              <Typography className={classes.quantity} variant='body2' color='textSecondary'>{pallets.length} Pall</Typography>
                            ) : pallets && Boolean(pallets.length > 1) ? (<Typography className={classes.quantity} variant='body2' color='textSecondary'>{pallets.length} Pallar</Typography>) : (<></>)}

                            {boxes && Boolean(boxes.length === 1) ? (
                              <Typography className={classes.quantity} variant='body2' color='textSecondary'>{boxes.length} Låda</Typography>
                            ) : boxes && Boolean(boxes.length > 1) ? (<Typography className={classes.quantity} variant='body2' color='textSecondary'>{boxes.length} Lådor</Typography>) : (<></>)}
                          </div>

                          <StyledTabs
                            value={value}
                            onChange={tabChange}
                            aria-label='tabs'
                          >
                            <StyledTab label='Individ' {...tabProps(0)} />
                            <StyledTab label='Pall' {...tabProps(1)} />
                            <StyledTab label='Låda' {...tabProps(2)} />
                          </StyledTabs>
                          <TabPanel value={value} index={0}>
                            <Field
                              name='item'
                              label='EAN'
                              variant='outlined'
                              margin='normal'
                              type='text'
                              fullWidth
                              autoFocus
                              onComplete={scannerMode ? data => setItem(data, arrayHelpers, setFieldValue) : undefined}
                              InputProps={{
                                inputProps: {
                                  ref: inputRef,
                                  onBlur: !scannerMode ? () => setItem(values.item, arrayHelpers, setFieldValue) : undefined
                                },
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      onClick={() => setScannerMode(!scannerMode)}
                                    >
                                      {scannerMode ? (
                                        <Barcode />
                                      ) : (
                                        <KeyboardIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              component={scannerMode ? BarcodeField : TextField}
                            />
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <Field
                              name='item'
                              label='EAN'
                              variant='outlined'
                              margin='normal'
                              autoFocus
                              type='text'
                              fullWidth
                              onComplete={scannerMode ? data => getItems(data, 'pallet', arrayHelpers, setFieldValue) : undefined}
                              InputProps={{
                                inputProps: {
                                  ref: inputRef,
                                  onBlur: !scannerMode ? () => getItems(values.item, 'pallet', arrayHelpers, setFieldValue) : undefined
                                },
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      onClick={() => setScannerMode(!scannerMode)}
                                    >
                                      {scannerMode ? (
                                        <Barcode />
                                      ) : (
                                        <KeyboardIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                )

                              }}
                              component={scannerMode ? BarcodeField : TextField}
                            />
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <Field
                              name='item'
                              label='EAN'
                              variant='outlined'
                              margin='normal'
                              autoFocus
                              type='text'
                              fullWidth
                              onComplete={scannerMode ? data => getItems(data, 'box', arrayHelpers, setFieldValue) : undefined}
                              InputProps={{
                                inputProps: {
                                  ref: inputRef,
                                  onBlur: !scannerMode ? () => getItems(values.item, 'box', arrayHelpers, setFieldValue) : undefined
                                },
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      onClick={() => setScannerMode(!scannerMode)}
                                    >
                                      {scannerMode ? (
                                        <Barcode />
                                      ) : (
                                        <KeyboardIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                )

                              }}
                              component={scannerMode ? BarcodeField : TextField}
                            />
                          </TabPanel>
                          <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={isSubmitting}
                          >
                            Slutför
                          </Button>
                        </React.Fragment>
                      )
                    }}
                  />

                </Form>
              )}
            </Formik>
          ) : (
            !loading && (
              <React.Fragment>
                <StyledTabs value={statusId} onChange={tabResult}>
                  <StyledTab
                    value={1}
                    label={`Matchade (${stocktaking.statuses.matched})`}
                    disabled={!stocktaking.statuses.matched}
                  />
                  <StyledTab
                    value={5}
                    label={`Ej skannade (${stocktaking.statuses.missing})`}
                    disabled={!stocktaking.statuses.missing}
                  />
                  <StyledTab
                    value={2}
                    label={`Fel lager (${stocktaking.statuses.mismatched})`}
                    disabled={!stocktaking.statuses.mismatched}
                  />
                  <StyledTab
                    value={4}
                    label={`Felaktig kod (${stocktaking.statuses.unavailable})`}
                    disabled={!stocktaking.statuses.unavailable}
                  />
                </StyledTabs>

                {!stocktaking.statuses.matched &&
                  !stocktaking.statuses.missing &&
                  !stocktaking.statuses.mismatched &&
                  !stocktaking.statuses.unavailable ? (<></>) : (<StockTakingResult stocktakingId={stocktaking.id} status={statusId} />)}

              </React.Fragment>
            )
          )}
        </div>
      </Paper>
    </React.Fragment>
  )
}