import React, { useState, useEffect } from 'react'

import Table from './components/Table'
import { dataService } from './services'

const cols = [
  {
    id: 1,
    key: 'label',
    label: 'Titel',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'text',
    filter: {
      like: true
    }
  },
  {
    id: 2,
    key: 'storage_id',
    human_key: 'storage',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Lager',
    type: 'select'
  },
  {
    id: 3,
    key: 'ean',
    visible: true,
    sortable: false,
    filterable: false,
    edit: false,
    create: false,
    read: true,
    label: 'Streckkod',
    type: 'ean'
  }
]

export default ({
  match,
  history,
  location
}) => {

  useEffect(() => {
    dataService.getData({ model: 'storages', params: '?pagination=off' })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        cols.find(col => col.id === 2).options = options
        setEnhanced(cols)
      })
  }, [])

  const [enhanced, setEnhanced] = useState(cols)

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Lagerplatser'
      singular='Lagerplats'
      model='storage_locations'
    />
  )
}
