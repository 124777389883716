import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import classNames from 'clsx'

import { makeStyles } from '@material-ui/styles'

import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'

import Skeleton from '@material-ui/lab/Skeleton'
import { Button } from '@material-ui/core'


import { dataService } from '../../services'
import { success, error, warning } from '../colors'

const useStatusStyles = makeStyles(() => ({
  success: {
    backgroundColor: success
  },
  error: {
    backgroundColor: error
  }
}))

const StatusAvatar = ({
  status
}) => {
  const classes = useStatusStyles()

  switch(status) {
    case 'ok':
      return (
        <Avatar className={classes.success}>
          <ThumbUpAltIcon />
        </Avatar>
      )
    case 'not_ok':
      return (
        <Avatar className={classes.error}>
          <ThumbDownAltIcon />
        </Avatar>
      )
    default:
      return (
        <Avatar className={classes.success}>
          <ThumbUpAltIcon />
        </Avatar>
      )
  }

}

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'scroll',
    transition: 'transform .2s cubic-bezier(.29,1.09,.74,1.3), opacity .2s ease-in-out',
    height: '100%',
    '&:focus': {
      outline: 0
    }
  },
  headerWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'space-between'
  },
  all: {
    textDecoration: 'none',
    color: 'inherit',
  },
  buttonLabel: {
    whiteSpace: 'nowrap',
    textTransform: 'none'
  },
  header: {
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    marginBottom: theme.spacing(2),
    display: 'block',
    transition: 'transform .2s cubic-bezier(.29,1.09,.74,1.3)',
    '&:hover': {
      transform: 'scale(1.02)'
    }
  },
  skeleton: {
    marginTop: 3,
    marginBottom: 3
  }
}))

export default ({match, refresh}) => {
  const classes = useStyles()
  const [imports, setImports] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    dataService.getData({ model: 'stock_imports', params: '?order_by=updated_at&per_page=5'})
      .then(
        data => {
          const { result } = data
          setLoading(false)
          setImports(result)
        },
        error => {
          setLoading(false)
        }
      )
  }, [refresh])

  return (
    <React.Fragment>
      <div className={classes.headerWrapper}>
        <Typography
          variant='h6'
          className={classes.header}
        >
          Senaste importer
        </Typography>
        <Link
          to={`${match.url}/imports`}
          className={classes.all}
        >
          <Button
            classes={{
              label: classes.buttonLabel
            }}
          >
            Visa alla
          </Button>
        </Link>
      </div>
      {loading ? (
        <React.Fragment>
          {[...Array(5)].map((e, i) => (
            <div key={i} className={classNames(classes.link, i === 4 && classes.lastLink)}>
              <Paper>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Skeleton variant='rect' height={19} width={150} className={classes.skeleton} />}
                      secondary={<Skeleton variant='rect' height={16} width={250} className={classes.skeleton} />}
                    />
                  </ListItem>
                </List>  
              </Paper>
            </div>
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {imports.map((imp, index) => (
            <Link
              key={imp.id}
              to={`${match.url}/imports/${imp.id}`}
              className={classNames(classes.link, index === 4 && classes.lastLink)}
            >
              <Paper className={classes.paper}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <StatusAvatar status={imp.status} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${format(new Date(imp.updated_at), 'd LLLL yyyy, HH:mm:ss', {locale: sv})}`}
                      secondary={`${imp.user && imp.user.name}`}
                    />
                  </ListItem>
                </List>  
              </Paper>
            </Link>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}