import { authenticationService } from '../services'

export default ({ model, type }) => (
  Boolean(
    authenticationService.currentUserValue &&
    authenticationService.currentUserValue.permissions &&
    authenticationService.currentUserValue.permissions.length > 0 &&
    authenticationService.currentUserValue.permissions.find(permission => permission.model_type === model) &&
    authenticationService.currentUserValue.permissions.find(permission => permission.model_type === model)[type]
  )
)