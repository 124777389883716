import React, { useState, useEffect } from 'react'
import xlsx from 'xlsx'
import { dataService } from '../../services'
import Table from '../Table'

const cols = [
  {
    id: 0,
    key: 'item_id',
    human_key: 'serial_number',
    // nested: 'item',
    // nestedKey: 'serial_number',
    visible: true,
    sortable: true,
    filterable: true,
    create: true,
    edit: true,
    label: 'Individ',
    type: 'async',
    asyncSettings: {
      model: 'items',
      value: 'id',
      label: 'serial_number'
    }
  },
  {
    id: 1,
    key: 'article_id',
    human_key: 'article',
    visible: true,
    sortable: true,
    filterable: true,
    label: 'Artikel',
    type: 'select',
    filter: {
      exact: true,
    },
  },
  {
    id: 2,
    key: 'article_number',
    visible: true,
    sortable: true,
    filterable: false,
    label: 'Artikelnummer',
    type: 'text'
  },
  {
    id: 3,
    key: 'storage_id',
    human_key: 'storage',
    // nested: 'item',
    // nestedKey: 'storage',
    visible: true,
    sortable: true,
    filterable: true,
    label: 'Lager',
    type: 'select',
    trigger: true,
  },
  {
    id: 4,
    key: 'storage_location_id',
    human_key: 'storage_location',
    // nested: 'item',
    // nestedKey: 'storage_location',
    visible: true,
    sortable: true,
    filterable: true,
    options: [],
    depends_on: 'storage_id',
    label: 'Lagerplats',
    type: 'select'
  },
  {
    id: 5,
    key: 'status_id',
    human_key: 'status',
    // nested: 'item',
    // nestedKey: 'status',
    visible: true,
    sortable: true,
    filterable: true,
    label: 'Status',
    type: 'select'
  },
  {
    id: 6,
    key: 'value',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    label: 'Värde',
    type: 'number',
    multichange: true
  },
  {
    id: 7,
    key: 'approved',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    label: 'Värde godkänt',
    type: 'boolean',
    multichange: true
  },
  {
    id: 8,
    key: 'created_at',
    sortable: true,
    visible: true,
    label: 'Skapades',
    type: 'datetime'
  },
  {
    id: 9,
    key: 'updated_at',
    sortable: true,
    visible: true,
    label: 'Uppdaterades',
    type: 'datetime'
  }
]

export default ({
  match,
  history,
  location
}) => {
  const [enhanced, setEnhanced] = useState(cols)
  const [update, setUpdate] = useState(Date.now())
  const [importing, setImporting] = useState(false)

  useEffect(() => {
    dataService.getData({ model: 'articles', params: '?filter[0][key]=selectable&filter[0][value]=1&pagination=off' })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        cols.find(col => col.id === 1).options = options
        cols.find(col => col.id === 2).options = options
        dataService.getData({ model: 'storages', params: '?pagination=off' })
          .then(data => {
            const { result } = data
            const options = []
            result.forEach(option => {
              options.push({ value: option.id, label: option.label })
            })
            cols.find(col => col.id === 3).options = options
            dataService.getData({ model: 'statuses', params: '?pagination=off' })
              .then(data => {
                const { result } = data
                const options = []
                result.forEach(option => {
                  options.push({ value: option.id, label: option.label })
                })
                cols.find(col => col.id === 5).options = options
                setEnhanced(cols)
              })
          })
      })
  }, [])

  const getStorageLocations = storage_id => {
    return dataService.getData({ model: 'storage_locations', params: `?pagination=off&filter[0][key]=storage_id&filter[0][value]=${storage_id}` })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        enhanced.find(col => col.id === 4).options = options
        setEnhanced(enhanced)
      })
  }

  const createImport = event => {
    setImporting(true)
    const { files } = event.currentTarget
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onabort = () => { debugger }
    reader.onerror = () => { debugger }
    reader.onload = e => {
      const bstr = e.target.result
      const wb = xlsx.read(bstr, { type: rABS ? 'binary' : 'array' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 })
      const objectArray = []
      data.forEach(row => {
        if (row.length === 2) {
          objectArray.push({ serial_number: row[0], value: row[1] })
        }
      })
      if (objectArray.length > 0) {
        dataService
          .createData({ model: 'item_values/import', values: objectArray })
          .then(response => {
            setImporting(false)
            setUpdate(Date.now())
          })
      }
    }
    if (rABS) {
      reader.readAsBinaryString(files[0])
    }
    else {
      reader.readAsArrayBuffer(files[0])
    }
  }

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Lagervärden'
      singular='Lagervärde'
      model='item_values'
      callback={storage_id => getStorageLocations(storage_id)}
      update={update}
      showValue
      importSettings={{
        importing,
        submit: createImport,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }}
      getItem={(item) => {
         return dataService.getData({ model: `item_values/${item.id}`, params: ''})
      }}
    />
  )
}
