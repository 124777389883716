import React, { useEffect } from 'react'
import queryString from 'query-string'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { Form, Formik, Field } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'

import { authenticationService } from './services'

import TextField from './components/fields/TextField'
import PasswordField from './components/fields/PasswordField'
import Button from './components/Button'
import Logo from './images/logo'


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  }
}))

export default ({ history, location }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  
  return (
    <Container component='main' maxWidth='xs'>
      <Paper className={classes.paper}>
        <Formik
          initialValues={{}}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required('Du måste ange ett lösenord'),
            confirmation: Yup.string()
              .oneOf([Yup.ref('password')], 'Lösenorden matchar inte')
              .required('Du måste bekräfta ditt lösenord')
          })}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            const params = queryString.parse(location.search)
            const { token } = params
            const { password } = values
            const payload = {
              password,
              token
            }
            authenticationService.reset(payload)
              .then(
                user => {
                  const { from } = location.state || { from: { pathname: '/' } }
                  history.push(from)
                },
                error => {
                  setSubmitting(false)
                  if (Array.isArray(error)) {
                    error.forEach(err => {
                      enqueueSnackbar(err.message || err.type, { variant: 'error' })
                    })
                  } else {
                    enqueueSnackbar(error, { variant: 'error' })
                  }
                }
              )
          }}
        >
          {({
            isSubmitting
          }) => (
            <Form className={classes.form}>
              <Field
                variant='outlined'
                margin='normal'
                type='password'
                name='password'
                fullWidth
                component={PasswordField}
                label='Lösenord'
              />
              <Field
                variant='outlined'
                margin='normal'
                type='password'
                name='confirmation'
                fullWidth
                component={PasswordField}
                label='Bekfräfta lösenord'
              />
              <Button
                type='submit'
                size='large'
                fullWidth
                variant='contained'
                disabled={isSubmitting}
                color='primary'
              >
                Skapa lösenord
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  )
}
