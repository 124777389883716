import React from 'react'
import Table from '../Table'

const cols = [
  {
    id: 0,
    key: 'created_at',
    filterable: true,
    visible: true,
    sortable: true,
    label: 'Datum',
    type: 'datetime',
    filter: {
      type: 'list'
    },
  },
  {
    id: 1,
    key: 'report_type',
    filterable: true,
    visible: true,
    sortable: true,
    label: 'Typ',
    type: 'select',
    options: [
      { 
        alias: "work_report",
        label: "Arbetsrapport",
        value: 1 
      },
      { 
        alias: "status_report",
        label: "Statusrapport",
        value: 2
      }
    ]
  },
  {
    id: 2,
    key: 'report_status',
    filterable: true,
    visible: true,
    sortable: true,
    label: 'Status',
    type: 'multiselect',
    options: [
      {
        alias: "ok",
        label: "Genomförd",
        value: 0,
      },
      {
        alias: "ignored",
        label: "Ignorerad",
        value: 1,
      },
      {
        alias: "is_invalid",
        label: "Ej giltig",
        value: 2,
      },
      {
        alias: "no_items",
        label: "Utan individer",
        value: 3,
      },
      {
        alias: "not_found",
        label: "Ej hittade",
        value: 4,
      },
    ]
  },
  {
    id: 3,
    key: 'case_ids',
    filterable: true,
    visible: true,
    sortable: true,
    label: 'Ärende-Id',
    type: 'text',
  },
  {
    id: 4,
    key: 'case_type',
    filterable: true,
    visible: true,
    sortable: true,
    label: 'Ärendetyp',
    type: 'text',
    filter: {
      exact: true,
    },
  },
  {
    id: 5,
    key: 'action_codes',
    filterable: true,
    visible: true,
    sortable: true,
    label: 'Orsakskod',
    type: 'text',
    filter: {
      type: 'list'
    },
  },
  {
    id: 6,
    key: 'updated',
    filterable: true,
    visible: true,
    sortable: true,
    label: 'Ändrade',
    type: 'text',
    filter: {
      type: 'list'
    },
  },
  {
    id: 7,
    key: 'not_updated',
    filterable: true,
    visible: true,
    sortable: true,
    label: 'Ej ändrade',
    type: 'text',
    filter: {
      type: 'list'
    },
  },
  {
    id: 8,
    key: 'not_found',
    filterable: true,
    visible: true,
    sortable: true,
    label: 'Saknade',
    type: 'text',
    filter: {
      type: 'list'
    },
  },
  {
    id: 9,
    key: 'note',
    filterable: true,
    visible: true,
    sortable: true,
    label: 'Meddelande',
    type: 'text',
  },
  {
    id: 10,
    key: 'message_ref',
    filterable: true,
    visible: true,
    label: 'Referens',
    type: 'text',
  },
]

export default ({
  match,
  history,
  location
}) => {
  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={cols}
      exportable={true}
      title='Meddelanden'
      singular='Meddelande'
      model='wom_logs'
    />
  )
}