import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'

import Login from './Login'
import Admin from './Admin'
import Auth from './Auth'
import Forgot from './Forgot'
import PrivateRoute from './components/PrivateRoute'
import {
  primary,
  secondary,
  contrastText,
  error,
  background
} from './components/colors'
import NoMatch from './NoMatch'

import favicon from './images/favicon.ico'



const theme = createMuiTheme({
  palette: {
    primary: {
      main: primary
    },
    secondary: {
      main: secondary,
      contrastText: contrastText
    },
    error: {
      main: error
    },
    background: {
      default: background
    }
  }
})

const useStyles = makeStyles({
  error: {
    backgroundColor: error
  }
})

const Snackbar = ({ children }) => {
  const classes =useStyles()
  return (
    <SnackbarProvider
      classes={{
        variantError: classes.error
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

theme.shadows[1] = '0 0 14px 0 rgba(53,64,82,.07)'
theme.shape.borderRadius = 10

export default () => (
  <ThemeProvider theme={theme}>
    <Snackbar>
      <Helmet>
        <title>OneAsset</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route path='/forgot' component={Forgot} />
          <Route path='/auth' component={Auth} />
          <PrivateRoute  path='/admin' component={Admin} />
          <Route component={NoMatch} />
        </Switch>
      </Router>
    </Snackbar>
  </ThemeProvider>
)
