import React from 'react'

import Table from './components/Table'
import { dataService } from './services'

const cols = [
  {
    id: 1,
    key: 'label',
    label: 'Namn',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    type: 'text'
  },
  {
    id: 2,
    key: 'default_import_storage',
    label: 'Importlager',
    edit: true,
    read: true,
    type: 'number'
  },
  {
    id: 3,
    key: 'default_import_status',
    label: 'Importstatus',
    edit: true,
    read: true,
    type: 'number'
  },
  {
    id: 4,
    key: 'default_mounted_storage',
    label: 'Monteringslager',
    edit: true,
    read: true,
    type: 'number'
  },
  {
    id: 5,
    key: 'default_mounted_status',
    label: 'Monteringsstatus',
    edit: true,
    read: true,
    type: 'number'
  },
  {
    id: 6,
    key: 'default_unmounted_storage',
    label: 'Returlager',
    edit: true,
    read: true,
    type: 'number'
  },
  {
    id: 7,
    key: 'default_unmounted_status',
    label: 'Returstatus',
    edit: true,
    read: true,
    type: 'number'
  },
  {
    id: 8,
    key: 'wom_reports',
    label: 'WOM',
    type: 'boolean',
    visible: true,
    sortable: true,
    filterable: false,
    edit: true,
    create: true,
    read: true
  }
]

export default ({
  match,
  history,
  location
}) => {
  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={cols}
      title='Organisationer'
      singular='Organisation'
      model='organizations'
    />
  )
}