import React, { useState, useEffect } from 'react'
import Table from '../Table'
import { dataService } from '../../services'

const cols = [
  {
    id: 13,
    key: 'article_number',
    visible: true,
    edit: true,
    create: true,
    read: true,
    label: 'Artikelnummer',
    type: 'text',
  },
  {
    id: 1,
    key: 'article_id',
    human_key: 'article',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Artikel',
    type: 'select',
    filter: {
      type: 'multiselect'
    }
  },
  {
    id: 2,
    key: 'serial_number',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Serienummer',
    type: 'text',
  },
  {
    id: 3,
    key: 'production_date',
    visible: true,
    sortable: true,
    filterable: true,
    edit: false,
    create: false,
    read: true,
    label: 'Produktionsdatum',
    type: 'date',
  },
  {
    id: 4,
    key: 'warranty_date',
    visible: false,
    sortable: false,
    filterable: false,
    edit: false,
    create: false,
    read: true,
    label: 'Garantidatum',
    type: 'date',
  },
  {
    id: 5,
    key: 'storage_id',
    human_key: 'storage',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Lager',
    type: 'select',
    filter: {
      type: 'multiselect'
    },
    trigger: true,
    multichange: true,
  },
  {
    id: 6,
    key: 'storage_location_id',
    human_key: 'storage_location',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Lagerplats',
    type: 'select',
    filter: {
      type: 'multiselect'
    },
    depends_on: 'storage_id',
    options: [],
    multichange: true,
  },
  {
    id: 7,
    key: 'pallet',
    visible: true,
    sortable: true,
    filterable: true,
    edit: false,
    create: false,
    read: true,
    label: 'Pall',
    type: 'text',
    multichange: false,
  },
  {
    id: 8,
    key: 'box',
    visible: true,
    sortable: true,
    filterable: true,
    edit: false,
    create: false,
    read: true,
    label: 'Låda',
    type: 'text',
    multichange: false,
  },
  {
    id: 9,
    key: 'status_id',
    human_key: 'status',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Status',
    type: 'select',
    filter: {
      type: 'multiselect'
    },
    multichange: true,
  },
  {
    id: 10,
    key: 'wom_action_code',
    visible: false,
    sortable: false,
    filterable: true,
    edit: false,
    create: false,
    read: true,
    label: 'Orsakskod',
    type: 'text',
    multichange: false,
  },
  {
    id: 11,
    key: 'bundle_id',
    human_key: 'bundle',
    visible: true,
    sortable: false,
    filterable: false,
    edit: true,
    create: false,
    read: true,
    label: 'Bundle',
    type: 'bundle',
    multichange: false,
  },
  {
    id: 12,
    key: 'order_number',
    visible: true,
    sortable: true,
    filterable: true,
    edit: false,
    create: true,
    read: true,
    label: 'Ordernummer',
    type: 'text',
  },
]

export default ({
  match,
  history,
  location
}) => {
  useEffect(() => {
    Promise.all([
      dataService.getData({ model: 'articles', params: '?filter[0][key]=selectable&filter[0][value]=1&select[0]=id&select[1]=label&select[2]=article_number&pagination=off' })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.article_number + ': ' + option.label })
        })
        cols.find(col => col.id === 1).options = options
      }),
      dataService.getData({ model: 'storages', params: '?pagination=off' })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        cols.find(col => col.id === 5).options = options
      }),
      dataService.getData({ model: 'statuses', params: '?pagination=off' })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        cols.find(col => col.id === 9).options = options
      })
    ]).finally(() => {
      setEnhanced(cols)
    })
  }, [])

  const [enhanced, setEnhanced] = useState(cols)

  const getStorageLocations = storage_id => {
    return dataService.getData({ model: 'storage_locations', params: `?pagination=off&filter[0][key]=storage_id&filter[0][value]=${storage_id}` })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        enhanced.find(col => col.id === 6).options = options
        setEnhanced(enhanced)
      })
  }

  const mapSelectedData = (data, selected) => {
    return data
      .filter(({ id }) => selected.includes(id))
      .map(({ id, box, pallet }) => ({ id, box, pallet }))
  }

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Individer'
      singular='Individ'
      model='items'
      activities='item_activities'
      callback={storage_id => getStorageLocations(storage_id)}
      selectedDataMapper={mapSelectedData}
      getItem={(item) => {
        return dataService.getData({ model: `items/${item.id}`, params: '' })
      }}
    />
  )
}
