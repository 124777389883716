import React, {useState, useEffect } from 'react'
import Table from '../Table'
import { dataService } from '../../services'

const cols = [
  {
    id: 0,
    key: 'article_id',
    human_key: 'article',
    visible: true,
    sortable: true,
    filterable: true,
    create: true,
    read: true,
    label: 'Artikel',
    type: 'select'
  },
  {
    id: 1,
    key: 'value',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Värde',
    type: 'number'
  }
]

export default ({
  match,
  history,
  location
}) => {
  useEffect(() => {
    dataService.getData({model: 'articles', params: '?filter[0][key]=selectable&filter[0][value]=1&pagination=off'})
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label})
        })
        cols.find(col => col.id === 0).options = options
        setEnhanced(cols)
      })
  }, [])

  const [enhanced, setEnhanced] = useState(cols)

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Standarvärden'
      singular='Standarvärde'
      model='article_values'
    />
  )
}