import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import {Waypoint} from 'react-waypoint'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import { dataService } from '../../services'
import { Typography, CircularProgress, Fab, Tooltip } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import ExportFileIcon from '../../icons/ExportFileIcon'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  loader: {
    width: '100%',
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fab: {
    position: 'fixed',
    bottom: 40,
    right: 40
  },
}))

export default ({ item }) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const [exporting, setExporting] = useState(false)
  const [activities, setActivities] = useState([])
  const [perPage, setPerPage] = useState(15)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getContent()
  }, [])

  const getContent = next => {
    setLoading(true)
    dataService.getData({ model: `items/${item.id}/activities`, params: `?order_by=created_at&direction=desc&per_page=${perPage}&page=${next || page}`})
      .then(
        data => {
          const { total, result } = data
          setActivities([ ...activities, ...result])
          setTotal(total)
          setLoading(false)
        }
      )
  }

  const handleExport = () => {
    setExporting(true)
    dataService.getXlsx({ model: `items/${item.id}/activities.xlsx`, params: '?pagination=off&order_by=created_at&direction=desc' })
      .then(
        data => {
          const filename = `Händelser_${item.serial_number}_${format(new Date(), 'yyyyMMddHHmmss')}.xlsx`
          setExporting(false)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof(error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }

  const loadMoreContent = () => {
    const next = page + 1
    // console.log(next)
    setPage(next)
    getContent(next)
  }

  // These keys determine if the change will be listed
  const models = {
    article_id: 'Artikel',
    storage_id: 'Lager',
    storage_location_id: 'Lagerplats',
    status_id: 'Status',
    serial_number: 'Serienummer',
    manufacturer_number: 'Tillverkningsnummer',
    order_number: 'Ordernummer',
    pallet: 'Pall',
    box: 'Låda',
    wom_action_code: 'Orsakskod',
    wom_case: 'Arbetsrapport'
  }

  const types = {
    update: 'ändrade',
    create: 'skapades av'
  }

  return (
    <List>
      {activities
        .filter(activity => {
          const modelKeys = Object.keys(models)
          return activity.method !== 'update' ||
            activity.changes.some(change => modelKeys.includes(change.model))
        })
        .map(activity => {
          return (
          <ListItem key={activity.id}>
            <Card style={{ width: '100%'}}>
              <CardContent>
                <Typography variant='body2' color='textSecondary'>
                  {format(new Date(activity.created_at), 'd LLLL yyyy, HH:mm:ss', {locale: sv})}
                  {' '}
                  {types[activity.method]}
                  {' '}
                  {activity.user}
                </Typography>
                {activity.method === 'create' && (
                  <span
                    role='img'
                    aria-label='Success'
                  >
                    &#127881;
                  </span>
                )}
                {activity.changes.map(change => models[change.model] && (
                  <Typography variant='body2'>
                    {`${models[change.model]} från ${change.from} till ${change.to}`}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </ListItem>
        )})
      }
      {total > activities.length && (
        <Waypoint
          onEnter={loadMoreContent}
        />
      )}
      {loading && (
        <React.Fragment>
          {Boolean(activities.length) ? (
            <div className={classes.loader}>
              <CircularProgress align='center'/>
            </div>
          ) : (
            [...Array(15)].map((e, i) => (
              <ListItem key={i}>
                <Card className={classes.card}>
                  <CardContent>
                    <Skeleton variant='rect' width={150} height={14} style={{ marginBottom: 5}}/>
                    <Skeleton variant='rect' height={16}/>

                  </CardContent>
                </Card>
              </ListItem>
            ))
          )}
        </React.Fragment>
      )}
      {Boolean(activities.length) && (
        <Tooltip
          title='Exportera'
        >
          <Fab
            color='primary'
            onClick={() => handleExport()}
            className={classes.fab}
            disabled={exporting}
          >
            {exporting ? (
              <CircularProgress size={24} />
            ) : (
              <ExportFileIcon />
            )}
          </Fab>
        </Tooltip>
      )}
    </List>
  )
}