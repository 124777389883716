import React, { useEffect, useState, useRef } from 'react'
import queryString from 'query-string'
import ReactToPrint from 'react-to-print'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles, lighten } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Fab from '@material-ui/core/Fab'
import FilterIcon from '@material-ui/icons/FilterList'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import ViewIcon from '@material-ui/icons/RemoveRedEye'
import LinkIcon from '@material-ui/icons/Link'
import Badge from '@material-ui/core/Badge'
import Skeleton from '@material-ui/lab/Skeleton'
import MuiButton from '@material-ui/core/Button'
import ImportIcon from '@material-ui/icons/AssignmentReturned'

import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'


import { dataService } from '../services'
import Drawer from './Drawer'
import Barcode from '../icons/BarcodeIcon'
import ExportFileIcon from '../icons/ExportFileIcon'
import Button from './Button'
import BarcodeGenerator from './BarcodeGenerator'
import checkPermissions from '../helpers/checkPermissions'
import SelectedFilters from './SelectedFilters'
import ValueCounter from './ValueCounter'
import { Grid, List, ListItem } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: 70,
    overflow: 'scroll'
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20
  },
  fabIcon: {
    marginRight: 10
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  },
  circle: {
    display: 'inline-flex'
  },
  skeleton: {
    margin: '2px 0 1px'
  },
  highlight: {
    // backgroundColor: lighten(theme.palette.primary.main, 0.95),
    color: theme.palette.primary.main
  },
  hidden: {
    display: 'none'
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: 0,
  },
}))

export default ({
  match,
  history,
  location,
  model,
  title,
  singular,
  cols,
  activities,
  relatedOrders,
  selector,
  clickable,
  callback,
  selectedDataMapper,
  importSettings,
  update,
  showValue,
  exportable,
  defaultParams,
  defaultSort,
  getItem
}) => {
  if (!getItem) {
    getItem = (item) => {
      return new Promise((resolve, reject) => {
        resolve(item)
      })
    }
  }
  const { enqueueSnackbar } = useSnackbar()
  const [reload, setReload] = useState(Date.now())
  const params = selector ? queryString.parse(`${selector}${location.search}`) : queryString.parse(location.search)
  const componentRef = useRef()

  const usedFilters = {}

  const parsedParams = Object.keys(params).reduce((result, q) => {
    let exact = {}
    let keyValue = params[q]
    if (q.includes('[key') && !q.includes('[1000]')) {
      const key = q.split('filter[').pop().split('][key]')[0]
      keyValue = params[`filter[${key}][value]`]

      // Check if the column has specific rules
      const col = cols.find(c => c.key === params[q])

      // Add like-parameter if exact-option isn't enabled AND value doesn't contain delimiter or filter-type is list
      if (
        !(col && col.filter && col.filter.exact === true) &&
        !(col && ['select', 'multiselect'].includes(col.type)) &&
        (!keyValue.includes('|') || (col && col.filter && col.filter.type === 'list'))
      ) {
        exact = {
          [`filter[${key}][like]`]: true
        }
      }
      usedFilters[params[q]] = keyValue
    }

    return {
      ...result,
      [q]: params[q],
      ...exact,
    }
  }, {})

  useEffect(() => {
    setLoading(true)
    const defaultString = defaultParams ? (`${defaultParams}&`) : ''
    Object.entries(parsedParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        parsedParams[key] = value.join('|')
      }
    })

    const queryAsString = queryString.stringify(parsedParams)
    const sortString = (defaultSort && !queryAsString.includes('order_by')) ? (`${defaultSort}&`) : ''
    dataService.getData({ model, params: `?${defaultString}${sortString}${queryAsString}` })
      .then(async data => {
        data.result.forEach(res => {
          Object.keys(res).forEach(key => {
            if (Array.isArray(res[key])) {
              try {
                res[key].forEach(obj => {
                  obj.value = obj.id
                })
              } catch (error) {
                // What?
              }
            }
          })
        })

        setData(data)
        setQuery(params)
        setFilters(usedFilters)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, reload, update])

  const [exporting, setExporting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({ result: [], total: 0 })
  const [drawer, setDrawer] = useState({ type: '', open: false, item: {} })
  const [query, setQuery] = useState(params)
  const [filters, setFilters] = useState(usedFilters)
  const [barcode, setBarcode] = useState({ code: '', label: '', storage: '' })
  const [snackbarItem, setSnackbarItem] = useState({})
  const [selected, setSelected] = useState([])
  const classes = useStyles()

  const handleDrawer = ({ type, item }) => {
    if (type === 'edit' && cols.some(col => col.depends_on) && Boolean(callback)) {
      const key = cols.find(col => Boolean(col.depends_on)).depends_on
      callback(item[key]).then(res => {
        setDrawer({
          type,
          open: !drawer.open,
          item
        })
      })
    } else if (type === 'filter' && cols.some(col => col.depends_on) && Boolean(callback)) {
      const key = cols.find(col => Boolean(col.depends_on)).depends_on
      if (filters[key]) {
        callback(filters[key]).then(res => {
          setDrawer({
            type,
            open: !drawer.open,
            item
          })
        })
      } else {
        setDrawer({
          type,
          open: !drawer.open,
          item
        })
      }
    } else {
      setDrawer({
        type,
        open: !drawer.open,
        item
      })
    }
  }

  const handleCloseBarcode = () => {
    setBarcode({ code: '', label: '', storage: '' })
  }

  const handleFilter = async values => {
    setDrawer({
      type: '',
      open: false,
      item: {}
    })

    const filterQuery = { ...query }

    Object.keys(values).forEach((key, index) => {
      filterQuery[`filter[${index}][key]`] = key

      if (Array.isArray(values[key])) {
        filterQuery[`filter[${index}][value]`] = values[key].join('|')
      }
      else {
        filterQuery[`filter[${index}][value]`] = values[key]
      }
    })

    Object.keys(filterQuery).forEach((key) => {
      if (key.includes('[value]')) {
        const valueKey = key.split('filter[').pop().split('][value]')[0]

        if (['', null, undefined].includes(filterQuery[key])) {
          delete filterQuery[`filter[${valueKey}][key]`]
          delete filterQuery[`filter[${valueKey}][value]`]
        }
      }
    })

    delete filterQuery.page
    reRoute(filterQuery)
  }

  const handleRemoveFilter = (filter, isMulti, source) => {
    const filterQuery = { ...query }

    if (isMulti && !!source) {
      const keyValue = Object.keys(query).find(key => query[key] === source)
      const valueKey = keyValue.replace('key', 'value')
      const newValue = query[valueKey].split('|').filter(v => v !== filter).join('|')
      filterQuery[valueKey] = newValue

    } else {
      const keyValue = Object.keys(query).find(key => query[key] === filter)
      const valueKey = keyValue.replace('key', 'value')
      delete filterQuery[keyValue]
      delete filterQuery[valueKey]
    }

    delete filterQuery.page
    reRoute(filterQuery)
  }

  const handleClearFilter = () => {
    setDrawer({
      type: '',
      open: false,
      item: {}
    })
    const allFilters = Object.keys(query).filter(key => key.includes('[key]') || key.includes('[value]'))
    const filterQuery = { ...query }
    allFilters.forEach(filter => {
      delete filterQuery[filter]
    })
    delete filterQuery.page
    reRoute(filterQuery)
  }

  const handleSort = property => {
    const direction = query['order_by'] === property && query.direction !== 'asc' ? 'asc' : 'desc'
    const sortQuery = { ...query, order_by: property, direction }
    delete sortQuery.page
    reRoute(sortQuery)

  }

  const handleChangePage = (event, newPage) => {
    const sortQuery = { ...query, page: newPage + 1 }
    reRoute(sortQuery)
  }

  const handleChangeRowsPerPage = event => {
    const sortQuery = { ...query, per_page: event.target.value }
    reRoute(sortQuery)
  }

  const handleDelete = ({ item }) => {
    dataService.removeData({ model: `${model}/${item.id}`, params: '' })
      .then(
        response => {
          const result = [...data.result]
          item.index = data.result.findIndex(i => i.id === item.id)
          result.splice(item.index, 1)
          setSnackbarItem(item)
          setData({
            total: total - 1,
            result
          })
        },
        error => {
          debugger
        }
      )
  }

  const handleUndo = () => {
    const item = snackbarItem
    dataService.editData({ model: `${model}/${item.id}`, values: { deleted_at: null } })
      .then(
        response => {
          setSnackbarItem({})
          const result = [...data.result]
          result.splice(item.index, 0, item)
          setData({
            total: total + 1,
            result
          })
        }
      )
  }

  const handleCreate = ({ item }) => {
    window.location.reload(false)
  }

  const handleMultiChange = (items) => {
    window.location.reload(false)
    //items.map(item => (
    //  handleEdit({ item })
    //))
  }

  const handleEdit = ({ item }) => {
    window.location.reload(false)
  }

  const handleExport = () => {
    setExporting(true)
    let select = ''
    cols.map((col, index) => col.visible && (
      select += `&select[${index}]=${col.nested ? col.nested.sortKey : col.key}`
    ))

    const params = `?pagination=off${select}&${queryString.stringify(parsedParams, { encode: false })}`

    dataService.getXlsx({ model: `${model}.xlsx`, params })
      .then(
        data => {
          const filename = `${title || model}_${format(new Date(), 'yyyyMMddHHmmss')}.xlsx`
          setExporting(false)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof (error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }

  const isSelected = id => selected.includes(id)

  const handleSelect = (event, id) => {
    if (isSelected(id)) {
      setSelected([...selected.slice(0, selected.indexOf(id)), ...selected.slice(selected.indexOf(id) + 1)])
    } else {
      setSelected([...selected, id])
    }
  }

  const handleBundleSelect = (event, items) => {
    if (event.target.checked) {
      setSelected([...selected, ...items.map(item => item.id)])
      return
    }
    const newArray = selected.filter(i => !items.some(y => y.id === i))
    setSelected(newArray)
  }

  const handleSelectAll = event => {
    if (event.target.checked) {
      setSelected(result.map(item => item.id))
      return
    }
    setSelected([])
  }

  const reRoute = q => {
    const search = `?${queryString.stringify(q, { encode: false })}`
    return history.push({
      pathname: match.url,
      search
    })
  }

  const selectedData = typeof selectedDataMapper === 'function'
    ? selectedDataMapper(data.result, selected)
    : selected

  const { result, total, value } = data

  const actions = {
    editable: cols.some(col => col.edit) && checkPermissions({ model, type: 'write' }),
    creatable: cols.some(col => col.create) && checkPermissions({ model, type: 'write' }),
    readable: cols.some(col => col.read && checkPermissions({ model, type: 'read' })),
    deletable: cols.some(col => col.create) && checkPermissions({ model, type: 'remove' }),
    filterable: cols.some(col => col.filterable),
    multichange: cols.some(col => col.multichange && checkPermissions({ model, type: 'write' })),
    exportable: exportable !== false,
  }

  const showActions = actions.editable || actions.creatable || actions.readable || actions.filterable

  return (
    <React.Fragment>
      {Boolean(showValue) && (
        <ValueCounter value={value} />
      )}
      {Object.keys(filters).length > 0 && (
        <SelectedFilters filters={filters} cols={cols} onDelete={(filter, isMulti, source) => handleRemoveFilter(filter, isMulti, source)} />
      )}
      <Paper className={classes.paper}>
        <Toolbar
          className={clsx(classes.toolbar, {
            [classes.highlight]: actions.multichange && Boolean(selected.length)
          })}
        >
          {actions.multichange && Boolean(selected.length) ? (
            <React.Fragment>
              <Typography variant='subtitle1'>
                {selected.length} markerade
              </Typography>
              <Tooltip
                title='Ändra'
              >
                <IconButton onClick={() => handleDrawer({ type: 'multichange', item: selectedData })}>
                  <EditIcon
                    color='primary'
                  />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography variant='h6'>
                {Boolean(data.total) && data.total}{' '}{title}
              </Typography>
              <div
              >
                {Boolean(importSettings) && (
                  <React.Fragment>
                    <input accept={importSettings.accept} id='import-file' type='file' className={classes.hidden} onChange={importSettings.submit} />
                    <label htmlFor='import-file'>
                      <Tooltip title='Importera'>
                        <IconButton component='span'>
                          {importSettings.importing ? (
                            <CircularProgress size={24} />
                          ) : (
                            <ImportIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </label>
                  </React.Fragment>
                )}
                {actions.exportable && (
                  <Tooltip title='Exportera'>
                    <IconButton disabled={exporting} onClick={() => handleExport()}>
                      {exporting ? (
                        <CircularProgress size={24} />
                      ) : (
                        <ExportFileIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                {actions.filterable && (
                  <Tooltip title='Filtrera' aria-label='filter'>
                    <IconButton onClick={() => handleDrawer({ type: 'filter', item: {} })}>
                      {Object.keys(filters).length > 0 ? (
                        <Badge variant='dot' color='primary'>
                          <FilterIcon />
                        </Badge>
                      ) : (
                        <FilterIcon />
                      )
                      }
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </React.Fragment>
          )}
        </Toolbar>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {actions.multichange && (
                <TableCell padding='checkbox'>
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < result.length}
                    color='primary'
                    checked={!loading && selected.length === result.length}
                    onChange={handleSelectAll}
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
              )}
              {cols.map(col => col.visible && (
                <TableCell key={col.id}>
                  {col.sortable ? (
                    <React.Fragment>
                      {col.nested ? (
                        <TableSortLabel
                          active={query['order_by'] === col.nested.sortKey}
                          direction={query['direction'] || 'desc'}
                          onClick={() => handleSort(col.nested.sortKey)}
                        >
                          {col.label}
                        </TableSortLabel>
                      ) : (
                        <TableSortLabel
                          active={query['order_by'] === col.key}
                          direction={query['direction'] || 'desc'}
                          onClick={() => handleSort(col.key)}
                        >
                          {col.label}
                        </TableSortLabel>
                      )}
                    </React.Fragment>
                  ) : (col.label)
                  }
                </TableCell>
              ))}
              {showActions && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {result.length > 0 ? (
              result.map(item => (
                <TableRow hover key={item.id} component={clickable && Link} to={`${match.url}/${item.id}`}>
                  {actions.multichange && (
                    <TableCell padding='checkbox'>
                      <Checkbox
                        checked={isSelected(item.id)}
                        onChange={event => item.bundle && item.bundle.items ? handleBundleSelect(event, item.bundle.items) : handleSelect(event, item.id)}
                        inputProps={{ 'aria-labelledby': `table-checkbox-${item.id}` }}
                        color='primary'
                      />
                    </TableCell>
                  )}
                  {cols.map(col => col.visible && (
                    <TableCell key={col.id}>
                      {!loading ? (
                        <React.Fragment>
                          {(() => {
                            let colValue = item[col.key]
                            if (col.getter) {
                              colValue = col.getter(item[col.key])
                            } else if (col.human_key) {
                              colValue = item[col.human_key]
                            } else if (col.nested && item[col.nested.model]) {
                              colValue = item[col.nested.model][col.nested.key]
                            }

                            const valueAlias = col.options && col.options.find(o => o.alias && o.alias === colValue)

                            if (valueAlias) {
                              return valueAlias.label
                            }

                            if (typeof colValue === 'string' && colValue.includes('\n')) {
                              return (
                                <List className={classes.list}>
                                  {colValue.split('\n').map((v, i) => (
                                    <ListItem key={i} className={classes.listItem}>{v}</ListItem>
                                  ))}
                                </List>
                              )
                            }

                            switch (col.type) {
                              case 'bundle':
                                return item.bundle && (
                                  <IconButton
                                    onClick={() => handleFilter({ bundle_id: colValue })}
                                  >
                                    <LinkIcon />
                                  </IconButton>
                                )
                              case 'ean':
                                return (
                                  <IconButton
                                    onClick={() => setBarcode({ code: colValue, label: item['label'], storage: item['storage'] })}
                                  >
                                    <Barcode />
                                  </IconButton>
                                )
                              case 'datetime':
                                return (
                                  format(new Date(colValue), 'd LLLL yyyy, HH:mm:ss', { locale: sv })
                                )
                              case 'boolean':
                                return (
                                  <Checkbox
                                    checked={colValue}
                                    disabled
                                  />
                                )
                              default:
                                return colValue
                            }
                          })()}
                        </React.Fragment>
                      ) : (
                        <Skeleton variant='rect' className={classes.skeleton} />
                      )
                      }
                    </TableCell>
                  ))}
                  {showActions && (
                    <TableCell style={{ whiteSpace: 'nowrap' }} align='right'>
                      {!loading ? (
                        <React.Fragment>
                          {actions.readable && (
                            <Tooltip title='Visa' aria-label='read'>
                              <IconButton onClick={() => {
                                getItem(item)
                                  .then(newItem => {
                                    handleDrawer({ type: 'read', item: newItem })
                                  })
                              }}>
                                <ViewIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {actions.editable && (
                            <Tooltip title='Ändra' aria-label='edit'>
                              <IconButton onClick={() => {
                                getItem(item)
                                  .then(newItem => {
                                    handleDrawer({ type: 'edit', item: newItem })
                                  })
                              }}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {actions.deletable && (
                            <Tooltip title='Ta bort' aria-label='delete'>
                              <IconButton onClick={() => {
                                handleDelete({ item })
                              }}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {actions.readable && (<Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} style={{ marginRight: 10 }} />)}
                          {actions.editable && (<Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} style={{ marginRight: 10 }} />)}
                          {actions.deletable && (<Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} />)}
                        </React.Fragment>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))) : (
              <React.Fragment>
                {!loading ? (
                  <TableRow>
                  </TableRow>
                ) : (
                  [...Array(20)].map((e, i) => (
                    <TableRow key={i}>
                      {actions.multichange && (
                        <TableCell padding='checkbox'>
                          <Skeleton variant='rect' style={{ marginLeft: 3 }} width={18} height={18} />
                        </TableCell>
                      )}
                      {cols.map(col => col.visible && (
                        <TableCell key={col.id}>
                          <Skeleton variant='rect' className={classes.skeleton} />
                        </TableCell>
                      ))}
                      {showActions && (
                        <TableCell align='right'>
                          {actions.readable && (<Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} style={{ marginRight: 10 }} />)}
                          {actions.editable && (<Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} style={{ marginRight: 10 }} />)}
                          {actions.deletable && (<Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} />)}
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
        <TablePagination
          // rowsPerPageOptions={[20, 50, { value: data.total, label: 'Visa alla' }]}
          rowsPerPageOptions={[20, 50, 200, 500]}
          labelRowsPerPage='Rader per sida'
          component='div'
          count={data.total}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} av ${count}`}
          rowsPerPage={query.per_page ? query.per_page : 20}
          page={query.page ? query.page - 1 : 0}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {showActions && (
          <Drawer
            drawer={drawer}
            activities={activities}
            relatedOrders={relatedOrders}
            cols={cols}
            onClose={() => handleDrawer({ type: '', item: {} })}
            model={model}
            filters={filters}
            onFilter={values => handleFilter(values)}
            onClearFilter={() => handleClearFilter()}
            onCreate={item => handleCreate({ item })}
            onEdit={item => handleEdit({ item })}
            onMultiChange={items => handleMultiChange(items)}
            onBundle={() => setReload(Date.now())}
            callback={callback}
          />
        )}
        {actions.creatable && (
          <Fab
            variant='extended'
            aria-label='submit'
            type='submit'
            color='primary'
            onClick={() => handleDrawer({ type: 'create', item: {} })}
            className={classes.fab}
          >
            <AddIcon className={classes.fabIcon} />
            Skapa {singular}
          </Fab>
        )}
        <Dialog
          open={Boolean(barcode.code)}
          onClose={handleCloseBarcode}
        // aria-labelledby='barcode-title'
        // aria-describedby='barcode-description'
        >
          {/* <DialogTitle id='barcode-title'>{'Use Googles location service?'}</DialogTitle> */}
          <DialogContent>
            <BarcodeGenerator barcode={barcode} ref={componentRef} />
          </DialogContent>
          <DialogActions>
            <ReactToPrint
              trigger={() => (
                <Button
                  color='primary'
                  autoFocus
                >
                  Skriv ut
                </Button>
              )}
              content={() => componentRef.current}
            />
            <Button
              onClick={handleCloseBarcode}
              color='secondary'
            >
              Stäng
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={snackbarItem.id}
          autohideDuration={5000}
          onClose={() => setSnackbarItem({})}
          message={snackbarItem.label ? `${singular} ${snackbarItem.label} har tagits bort` : `${singular} har tagits bort`}
          action={
            <React.Fragment>
              <Button color='primary' size='small' onClick={handleUndo} noMargin>
                Ångra
              </Button>
              <IconButton size='small' aria-label='close' color='inherit' onClick={() => setSnackbarItem({})}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </React.Fragment>
          }
        />
      </Paper>
    </React.Fragment>
  )
}
