import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { saveAs } from 'file-saver'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'

import { makeStyles, lighten, withStyles } from '@material-ui/core/styles'
import { Paper, Toolbar, Typography, Fab, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableBody, TableCell, Avatar, IconButton, Breadcrumbs, InputAdornment, Tooltip, CircularProgress, Snackbar } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import HomeIcon from '@material-ui/icons/Home'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import ViewIcon from '@material-ui/icons/RemoveRedEye'
import LinearProgress from '@material-ui/core/LinearProgress'
import Skeleton from '@material-ui/lab/Skeleton'

import ExportFileIcon from '../../../icons/ExportFileIcon'
import { dataService } from '../../../services'
import checkPermissions from '../../../helpers/checkPermissions'
import { Formik, Form, Field } from 'formik'
import { primary, success, error, warning } from '../../colors'
import BarcodeIcon from '../../../icons/BarcodeIcon'
import BarcodeField from '../../fields/BarcodeField'
import TextField from '../../fields/TextField'
import ReactSelect from '../../fields/ReactSelect'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: 70
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    textDecoration: 'none'
  },
  fabIcon: {
    marginRight: 10
  },
  tableRow: {
    textDecoration: 'none'
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  success: {
    backgroundColor: success,
  },
  error: {
    backgroundColor: error
  },
  pending: {
    backgroundColor: warning
  },
  circle: {
    display: 'inline-flex'
  },
  skeleton: {
    margin: '2px 0 1px'
  },
  form: {
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: 'initial'
  },
  overflow: {
    overflow: 'visible'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  },
}))

export default ({
  match,
  history,
  location
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [createActive, setCreateActive] = useState(false)
  const [storages, setStorages] = useState([])
  const [storageLocations, setStorageLocations] = useState([])
  const [scannerMode, setScannerMode] = useState(true)
  const [group, setGroup] = useState({})
  const [loading, setLoading] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [singleExport, setSingleExport] = useState(null)
  const [snackbarItem, setSnackbarItem] = useState({})

  useEffect(() => {
    dataService.getData({ model: 'storages', params: '?pagination=off' })
      .then(
        data => {
          const { result } = data
          const options = []
          result.forEach(option => {
            options.push({ value: option.id, label: option.label })
          })
          setStorages(options)
        }
      )
  }, [])

  useEffect(() => {
    setLoading(true)
    dataService.getData({ model: `stocktaking_groups/${match.params.id}`, params: '' })
      .then(data => {
        setGroup(data)
        setLoading(false)
      })
  }, [match.params.id, location.search])

  const getStorageByCode = ({ ean, setFieldValue, submitForm, storages }) => {
    dataService.getData({ model: 'storage_locations', params: `?filter[0][key]=ean&filter[0][value]=${ean}` })
      .then(data => {
        const { result } = data
        if (result.length > 0) {
          const options = []
          result.forEach(option => {
            options.push({ value: option.id, label: option.label })
          })
          setStorageLocations(options)
          const storage = storages.find(st => st.value === result[0].storage_id)
          setFieldValue('storage_id', storage.value)
          setFieldValue('storage_location_id', result[0].id)
          submitForm()
        }
      })
  }

  const getStorageLocations = storage_id => {
    setStorageLocations([])
    dataService.getData({ model: 'storage_locations', params: `?filter[0][key]=storage_id&filter[0][value]=${storage_id}&pagination=off` })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        setStorageLocations(options)
      })
  }

  const handleExport = () => {
    setExporting(true)
    dataService.getXlsx({ model: 'stocktakings_summary.xlsx', params: `?pagination=off&group_id=${group.id}` })
      .then(
        data => {
          const filename = `Gruppinventering_${format(new Date(group.created_at), 'yyyyMMddHHmmss')}.xlsx`
          setExporting(false)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof (error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }

  const handleSingleExport = (id, storage, created_at) => {
    setSingleExport(id)
    dataService.getXlsx({ model: 'stocktakings_summary.xlsx', params: `?stocktaking_id=${id}` })
      .then(
        data => {
          const filename = `${storage}_${format(new Date(created_at), 'yyyyMMddHHmmss')}.xlsx`
          setSingleExport(null)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof (error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }


  const handleDelete = stocktaking => {
    dataService.removeData({ model: `stocktakings/${stocktaking.id}`, params: '' })
      .then(
        () => {
          setSnackbarItem(stocktaking)
          setGroup(prev => {
            return {
              ...prev,
              stocktakings: prev.stocktakings.filter(({ id }) => id !== stocktaking.id)
            }
          })
        },
        () => {
          // Handle error
        }
      )
  }

  const handleUndo = () => {
    const item = snackbarItem
    dataService.editData({ model: `stocktakings/${item.id}`, values: { deleted_at: null } })
      .then(
        () => {
          setSnackbarItem({})
          setGroup(prev => {
            const stocktakings = [...prev.stocktakings]
            stocktakings.splice(item.index, 0, item)
            return {
              ...prev,
              stocktakings,
            }
          })
        },
        () => {
          // Handle error
        }
      )
  }

  return (
    <React.Fragment>
      <Breadcrumbs style={{ paddingBottom: 8 }} separator={<NavigateNextIcon fontSize="small" />}>
        <Link
          className={classes.link}
          to={'/admin/items/stock-taking'}
        >
          <HomeIcon className={classes.icon} />
        </Link>
        <Typography color='textSexondary'>
          Grupp
        </Typography>
      </Breadcrumbs>
      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <div>
            <Typography variant='h6'>
              Gruppinventering
            </Typography>
            {!loading ? (
              <Typography variant='caption' color='textSecondary'>
                {group.created_at && format(new Date(group.created_at), 'yyyy-MM-dd HH:mm:ss')}
              </Typography>
            ) : (
              <Skeleton variant='rect' width={150} height={17} className={classes.skeleton} />
            )}
          </div>
          {!loading ? (
            <Tooltip title='Exportera' aria-label='export'>
              <IconButton disabled={exporting} onClick={() => handleExport()}>
                {
                  exporting ? (
                    <CircularProgress size={24} />
                  ) : (
                    <ExportFileIcon />
                  )
                }
              </IconButton>
            </Tooltip>
          ) : (
            <Skeleton variant='circle' width={43} height={43} className={classes.circle} />
          )}
        </Toolbar>
        <Table
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell>
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Lager
              </TableCell>
              <TableCell>
                Lagerplats
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {group.stocktakings && group.stocktakings.length > 0 ? (
              group.stocktakings.map((stocktaking, index) => {
                const statusObject = () => {
                  switch (stocktaking.status) {
                    case 'failed':
                      return {
                        label: 'Genomförd med fel',
                        avatar: () => (
                          <Avatar className={classes.error}>
                            <ThumbDownAltIcon />
                          </Avatar>)
                      }
                    case 'ok':
                      return {
                        label: 'Genomförd',
                        avatar: () => (
                          <Avatar className={classes.success}>
                            <ThumbUpAltIcon />
                          </Avatar>)
                      }
                    default:
                      return {
                        label: 'Ej genomförd',
                        avatar: () => (
                          <Avatar className={classes.pending}>
                            <HourglassEmptyIcon />
                          </Avatar>)
                      }
                  }
                }

                return (
                  <TableRow
                    key={stocktaking.id}
                    className={classes.tableRow}
                  >
                    <TableCell>
                      {statusObject().avatar()}
                    </TableCell>
                    <TableCell>
                      {statusObject().label}
                    </TableCell>
                    <TableCell>
                      {stocktaking.storage}
                    </TableCell>
                    <TableCell>
                      {stocktaking.storage_location}
                    </TableCell>
                    <TableCell align='right'>
                      <Tooltip title='Exportera' aria-label='export-inividual'>
                        <IconButton disabled={singleExport === stocktaking.id} onClick={() => handleSingleExport(stocktaking.id, stocktaking.storage, stocktaking.created_at)}>
                          {singleExport === stocktaking.id ? (
                            <CircularProgress size={24} />
                          ) : (
                            <ExportFileIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Link
                        to={`${match.url}/${stocktaking.id}`}
                      >
                        <Tooltip title='Visa' aria-label='read'>
                          <IconButton>
                            <ViewIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      {checkPermissions({ model: 'stocktakings', type: 'remove' }) && (
                        <Tooltip title='Ta bort' aria-label='delete'>
                          <IconButton onClick={() => handleDelete({ ...stocktaking, index })}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <React.Fragment>
                {!loading ? (
                  <TableRow
                    className={classes.tableRow}
                  />
                ) : (
                  [...Array(20)].map((e, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Skeleton variant='circle' width={43} height={43} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='rect' className={classes.skeleton} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='rect' className={classes.skeleton} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='rect' className={classes.skeleton} />
                      </TableCell>
                      <TableCell align='right'>
                        <Skeleton variant='circle' width={43} height={43} className={classes.circle} style={{ marginRight: 10 }} />
                        <Skeleton variant='circle' width={43} height={43} className={classes.circle} />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={createActive}
        onClose={() => setCreateActive(false)}
        maxWidth='sm'
        fullWidth={true}
        classes={{
          paper: classes.overflow
        }}
      >
        <Formik
          initialValues={{}}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            values.stocktaking_group_id = group.id
            dataService.createData({ model: 'stocktakings', values })
              .then(
                data => {
                  const { id } = data
                  history.push(`${match.url}/${id}`)
                },
                error => {
                  setSubmitting(false)
                  if (Array.isArray(error)) {
                    error.forEach(err => {
                      enqueueSnackbar(err.message || err.type, { variant: 'error' })
                    })
                  } else {
                    if (typeof (error) === 'object') {
                      error = 'Systemfel, kontakta support'
                    }
                    enqueueSnackbar(error, { variant: 'error' })
                  }
                }
              )
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            submitForm
          }) => (
            <Form
              className={clsx(classes.form, classes.overflow)}
            >
              <DialogTitle>Välj lager & lagerplats</DialogTitle>
              <DialogContent
                className={classes.overflow}
                dividers
              >
                <Field
                  name='barcode'
                  label='Skanna lagerplats'
                  variant='outlined'
                  margin='normal'
                  autoFocus
                  type='text'
                  fullWidth
                  onComplete={scannerMode ? data => getStorageByCode({ ean: data, setFieldValue, submitForm, storages }) : undefined}
                  InputProps={{
                    inputProps: {
                      onBlur: !scannerMode ? () => getStorageByCode({ ean: values.barcod, setFieldValue, submitForm, storages }) : undefined
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setScannerMode(!scannerMode)}
                        >
                          {scannerMode ? (
                            <BarcodeIcon />
                          ) : (
                            <KeyboardIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  component={scannerMode ? BarcodeField : TextField}
                />
                <Typography
                  variant='subtitle1'
                  color='primary'
                  style={{
                    textAlign: 'center'
                  }}
                >
                  eller
                </Typography>
                <ReactSelect
                  name='storage_id'
                  placeholder='Välj lager'
                  label='Inventera lager'
                  options={storages}
                  onChange={option => {
                    getStorageLocations(option ? option.value : null)
                    return setFieldValue('storage_id', option ? option.value : null)
                  }}
                  onBlur={() => setFieldTouched('storage_id', true)}
                  errore={errors}
                  touched={touched}
                />
                <ReactSelect
                  name='storage_location_id'
                  placeholder='Välj lagerplats'
                  options={storageLocations}
                  onChange={option => setFieldValue('storage_location_id', option ? option.value : null)}
                  onBlur={() => setFieldTouched('storage_location_id', true)}
                  errors={errors}
                  touched={touched}
                  isDisabled={!values['storage_id'] || !storageLocations.length}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color='secodary'
                  onClick={() => setCreateActive(false)}
                >
                  Avbryt
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  aria-label='submit'
                  color='primary'
                  disabled={isSubmitting}
                >
                  Nästa
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackbarItem.id}
        autohideDuration={5000}
        onClose={() => setSnackbarItem({})}
        message={'Gruppinventering har tagits bort'}
        action={
          <React.Fragment>
            <Button color='primary' size='small' onClick={handleUndo} noMargin>
              Ångra
            </Button>
            <IconButton size='small' aria-label='close' color='inherit' onClick={() => setSnackbarItem({})}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </React.Fragment>
        }
      />
      <Fab
        variant='extended'
        aria-label='submit'
        color='primary'
        className={classes.fab}
        onClick={() => setCreateActive(true)}
      >
        <AddIcon className={classes.fabIcon} />
        Inventering
      </Fab>
    </React.Fragment>
  )
}