import { handleResponse } from '../helpers';
import { get, getExport, post, put, remove } from '../utils';

const getData = ({ model, params }) =>
  get(`/${model}${params}`).then(handleResponse);

const getXlsx = ({ model, params }) =>
  getExport(`/${model}${params}`).then(handleResponse);

const createData = ({ model, values }) =>
  post(`/${model}`, values)
    .then(handleResponse)
    .then((data) => data);

const editData = ({ model, values }) =>
  put(`/${model}`, values)
    .then(handleResponse)
    .then((data) => data);

const removeData = ({ model }) =>
  remove(`/${model}`)
    .then(handleResponse)
    .then((data) => data);

const validateData = ({ model, values }) =>
  post(`/${model}`, values)
    .then(handleResponse)
    .then((data) => data);

export default {
  getData,
  getXlsx,
  createData,
  validateData,
  editData,
  removeData,
};
