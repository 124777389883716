import React, { useEffect, useState } from 'react'
import ReactSelect from '../fields/ReactSelect'
import { dataService } from '../../services'

const debounce = (f, ms) => {
  let timer = null

  return (...args) => {
    const onComplete = () => {
      f.apply(this, args)
      timer = null
    }

    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(onComplete, ms)
  }
}

export default ({ bundle, errors, touched, onChange, defaultValue, exclude }) => {
  const [items, setItems] = useState([])
  const [options, setOptions] = useState([])

  // useEffect(() => {
  //   dataService.getData({model: 'items', params: `?pagination=off&filter[0][key]=bundle_id&filter[0][value]=${bundle}`})
  //     .then(data => {
  //       const {result} = data
  //       const options = result.map(option => ({
  //         value: option.id,
  //         label: option.serial_number
  //       }))
  //       setItems(options)
  //     })
  // }, [bundle])

  const getOptions = e => e.length > 3 ? (debounce(e => {
    dataService.getData({ model: 'items', params: `?filter[0][key]=serial_number&filter[0][value]=${e}&exclude[0][key]=serial_number&exclude[0][value]=${exclude}` })
      .then(
        data => {
          const { result } = data
          const options = result.map(option => ({
            value: option.id,
            label: option.serial_number
          }))
          setOptions(options)
        }
      )
  }, 1000))(e) : undefined

  const addToBundle = oop => {
    debugger
  }

  return (
    <ReactSelect
      name={'bundle'}
      placeholder='Bundle'
      options={options}
      label='Bundle'
      isMulti
      errors={errors}
      touched={touched}
      defaultValue={defaultValue}
      onInputChange={e => getOptions(e)}
      onChange={bundles => onChange(bundles)}
    />
  )
}