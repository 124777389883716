import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export default ({
  selects,
  field: { ...fields },
  label,
  required,
  handleToggle,
  form: { touched, errors, ...rest },
  ...props
}) => {
  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{label}</FormLabel>
      <RadioGroup {...props}>
        {selects.map((select, index) => (
          <FormControlLabel value={select.value} control={<Radio />} label={select.label} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}