import axios from 'axios'

import { authHeader } from './helpers'

const host = process.env.REACT_APP_API

export const post = async (path, payload) => (
  axios({
    method: 'post',
    url: `${host}${path}`,
    data: payload,
    headers: authHeader(),
    validateStatus: status => status < 500
  })
    .then(response => response)
    .catch(error => error)
)

export const get = async path => (
  axios({
    method: 'get',
    url: `${host}${path}`,
    headers: authHeader(),
    validateStatus: status => status < 500
  })
    .then(response => response)
    .catch(error => error)
)

export const getExport = async (path, payload) => (
  axios({
    mathod: 'get',
    url: `${host}${path}`,
    headers: {
      ...authHeader(),
      Accept: 'text/csv'
    },
    responseType: 'blob'

  })
)

export const put = async (path, payload) => (
  axios({
    method: 'put',
    url: `${host}${path}`,
    data: payload,
    headers: authHeader(),
    validateStatus: status => status < 500
  })
    .then(response => response)
    .catch(error => {debugger})
)

export const remove = async path => (
  axios({
    method: 'delete',
    url: `${host}${path}`,
    headers: authHeader(),
    validateStatus: status => status < 500
  })
    .then(response => response)
    .catch(error => error)
)

export const selectQuery = keys =>
  keys.reduce((result, key, index) =>
    `${result}${result.length > 0 ? '&' : ''}select[${index}]=${key}`
  , '')
