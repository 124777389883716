import React from 'react'
import Table from '../Table'

const cols = [
  {
    id: 1,
    key: 'update_status',
    visible: true,
    sortable: true,
    label: 'Status',
    type: 'text'
  },
  {
    id: 2,
    key: 'storage_id',
    human_key: 'storage',
    visible: true,
    sortable: true,
    label: 'Lager',
    type: 'text'
  },
  {
    id: 3,
    key: 'storage_location_id',
    human_key: 'storage_location',
    visible: true,
    sortable: true,
    label: 'Lagerplats',
    type: 'text'
  },
  {
    id: 4,
    nested: {
      model: 'user',
      key: 'name',
      sortKey: 'users-name',
    },
    visible: true,
    sortable: true,
    label: 'Användare',
    type: 'text'
  },
  {
    id: 5,
    key: 'total_items',
    visible: true,
    sortable: true,
    label: 'Antal',
    type: 'text'
  },
  {
    id: 6,
    key: 'created_at',
    visible: true,
    sortable: true,
    label: 'Skapades',
    type: 'datetime'
  },
]

export default ({
  match,
  history,
  location
}) => (
  <Table
    match={match}
    history={history}
    location={location}
    cols={cols}
    title='Lagerflyttar'
    singular='Lagerflytt'
    model='stock_updates'
    defaultSort='order_by=created_at&direction=DESC'
    clickable
  />
)
