import { authenticationService } from '../services';

const handleResponse = (response) => {
  const { data, status, config } = response;

  if ((data && data.error) || (data && data.errors)) {
    if ([401].includes(status)) {
      authenticationService.logout();
    }
    let error = data.error || data.errors || response.statusText;
    let items = data && data.items;
    if ([423].includes(status)) {
      error = { status, error };
    }
    // Special case
    if (
      [400].includes(status) &&
      config.method === 'post' &&
      config.url.includes('stock_imports')
    ) {
      error = { status, error, items };
    }
    return Promise.reject(error);
  }
  return data;
};

export default handleResponse;
