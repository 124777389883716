import React, { useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'

import { withStyles } from '@material-ui/styles'

import { Paper } from '@material-ui/core'

import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Singles from './StockTaking/Singles'
import Groups from './StockTaking/Groups'
import Group from './StockTaking/Group'
import Location from './StockTaking/Location'

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />)

export default ({
  match,
  location
}) => {

  return (
    <Grid container spacing={2} style={{ alignItems: 'flex-start' }}>
      <Grid item xs={12}>
        <Paper>
          <Toolbar>
            <StyledTabs value={`/${location.pathname.split(`${match.url}/`).pop().split('/').shift()}` !== '/singles' ? '/' : '/singles'}>
              <StyledTab label='Gruppinventeringar' value={'/'} component={Link} to={match.url} />
              <StyledTab label='Enskilda' value={'/singles'} component={Link} to={`${match.url}/singles`} />
            </StyledTabs>
          </Toolbar>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Switch>
          <Route
            exact
            path={`${match.url}/singles`}
            render={props => <Singles {...props} />}
          />
          <Route
            exact
            path={`${match.url}/singles/:location`}
            render={props => <Location {...props} />}
          />
          <Route
            exact
            path={match.url}
            render={props => <Groups {...props} />}
          />
          <Route
            exact
            path={`${match.url}/:id`}
            render={props => <Group {...props} />}
          />
          <Route
            path={`${match.url}/:id/:location`}
            render={props => <Location {...props} />}
          />
        </Switch>
      </Grid>
    </Grid>
  )
}
