import React from 'react'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import StockStatus from './components/StockComponent/Table'


const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: 30
  }
}))

export default props => {
  const classes = useStyles()

  const timeOfDay = hour => {
    if (hour >= 17) {
      return 'God kväll'
    }
    else if (hour >= 12) {
      return 'God eftermiddag'
    }
    else if (hour >= 4) {
      return 'God morgon'
    }
    else {
      return ' Sov gott'
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4' className={classes.header}>{timeOfDay(format(new Date(), 'H'))}</Typography>
      </Grid>
      <Grid item xs={12}>
        <StockStatus {...props} />
      </Grid>
    </Grid>
  )
}
