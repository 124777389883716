import React, {useState, useEffect } from 'react'

import Table from './components/Table'
import { dataService } from './services'

const cols = [
  {
    id: 1,
    key: 'article_id',
    human_key: 'article',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Artikel',
    type: 'select',
    exclude: 'bulk_item_id'
  },
  {
    id: 2,
    key: 'bulk_item_id',
    human_key: 'bulk_item',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Plockvara',
    type: 'select',
    exclude: 'article_id'
  },
  {
    id: 3,
    key: 'storage_id',
    human_key: 'storage',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Lager',
    type: 'select'
  },
  {
    id: 4,
    key: 'min_amount',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Gräns',
    type: 'number'
  },
  {
    id: 5,
    key: 'request_amount',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Mängd',
    type: 'number'
  },
  {
    id: 6,
    key: 'external_order',
    visible: true,
    sortable: true,
    filterable: true,
    edit: true,
    create: true,
    read: true,
    label: 'Extern beställning',
    type: 'boolean'
  }
]

export default ({
  match,
  history,
  location
}) => {
  useEffect(() => {
    dataService.getData({model: 'articles', params: '?filter[0][key]=selectable&filter[0][value]=1&pagination=off'})
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        cols.find(col => col.id === 1).options = options
        dataService.getData({ model: 'bulk_items', params: '?pagination=off'})
          .then(data => {
            const { result } = data
            const options = []
            result.forEach(option => {
              options.push({ value: option.id, label: option.label })
            })
            cols.find(col => col.id === 2).options = options
            dataService.getData({ model: 'storages', params: '?pagination=off'})
              .then(data => {
                const { result } = data
                const options = []
                result.forEach(option => {
                  options.push({ value: option.id, label: option.label })
                })
                cols.find(col => col.id === 3).options = options
                setEnhanced(cols)
              })
          })
      })
  }, [])

  const [enhanced, setEnhanced] = useState(cols)

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Gränsvärden'
      singular='Gränsvärde'
      model='storage_monitors'
    />
  )
}