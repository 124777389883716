import React from 'react'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'

export default ({
  field: { ...fields },
  label,
  required,
  form: { touched, errors, ...rest },
  ...props
}) => (
  <React.Fragment>
    <Typography>
      {label}
    </Typography>
    <Slider
      {...props}
    />
  </React.Fragment>
)
