import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { Form, Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Check from '@material-ui/icons/Check'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'


import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/PlaylistAdd'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import StepConnector from '@material-ui/core/StepConnector'

import TextField from '../fields/TextField'
import { dataService } from '../../services'
import Button from '../Button'
import ReactSelect from '../fields/ReactSelect'
import { error, success } from '../colors'

const QontoConnector = withStyles({
  active: {
    '& $line': {
      borderColor: '#1a76d0',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1a76d0',
    },
  },
  line: {
    borderWidth: 3
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
    width: 26
  },
  active: {
    color: '#1a76d0',
    '&>$circle': {
      animation: `$pulseAnimation 1s infinite`
    }
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#1a76d0',
    zIndex: 1,
    fontSize: 18,
  },
  '@keyframes pulseAnimation': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(26,118,208, 0.4)'
    },
    '70%': {
      boxShadow: '0 0 0 10px rgba(26,118,208, 0)'
    },
    '100%': {
      boxShadow: ' 0 0 0 0 rgba(26,118,208, 0)'
    }
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}


const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: 70,
    overflow: 'scroll'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  },
  wrapper: {
    width: '100%',
    padding: 24,
    height: '100%'
  },
  stepContent: {
    borderWidth: 3
  },
  activeLabel: {
    fontSize: '1.5rem'
  },
  flexLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    flex: 2,
    paddingRight: 24
  },
  qty: {
    flex: 1,
    paddingRight: 24
  },
}))

export default ({ match }) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [storage, setStorage] = useState({})
  const [bulkItems, setBulkItems] = useState([])
  const [storages, setStorages] = useState([])
  const [receipt, setReceipt] = useState([])

  const { enqueueSnackbar } = useSnackbar()


  useEffect(() => {
    dataService.getData({ model: 'storages', params: '?pagination=off' })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        setStorages(options)
      })
    dataService.getData({ model: 'bulk_items', params: '?pagination=off' })
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        setBulkItems(options)
      })
  }, [])

  const setStorageValues = ({ from_storage_id, to_storage_id }) => {
    handleNext()
    setStorage({ from_storage_id, to_storage_id })
  }

  const goBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar}>
            <Typography variant='h6'>
              Lagerflytt
            </Typography>
          </Toolbar>
          <div className={classes.wrapper}>
            <Stepper activeStep={activeStep} connector={<QontoConnector />} orientation='vertical'>
              <Step>
                <StepLabel
                  classes={{
                    active: classes.activeLabel
                  }}
                  StepIconComponent={QontoStepIcon}
                  onClick={activeStep > 0 && activeStep !== 2 ? () => goBack() : undefined}
                >
                  Välj lager
                  {activeStep > 0 && activeStep !== 2 && (
                    <IconButton>
                      <EditIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  )}
                </StepLabel>
                <StepContent classes={{
                  root: classes.stepContent
                }}>
                  <Formik
                    initialValues={{}}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      setStorageValues(values)
                      setSubmitting(false)
                      resetForm()
                    }}
                    validationSchema={Yup.object().shape({
                      from_storage_id: Yup.number().required('Du måste välja från lager'),
                      to_storage_id: Yup.number().required('Du måste välja till lager'),
                    })}
                  >
                    {({
                      submitForm,
                      values,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched
                    }) => (
                      <Form>
                        <ReactSelect
                          name='from_storage_id'
                          placeholder='Välj lager'
                          label='Från lager'
                          options={storages}
                          onChange={option => setFieldValue('from_storage_id', option ? option.value : null)
                          }
                          onBlur={() => setFieldTouched('from_storage_id', true)}
                          errors={errors}
                          touched={touched}
                          value={storages.find(option => option.value === values['from_storage_id'])}
                        />
                        <ReactSelect
                          name='to_storage_id'
                          placeholder='Välj lager'
                          label='Till lager'
                          options={storages}
                          onChange={option => setFieldValue('to_storage_id', option ? option.value : null)}
                          onBlur={() => setFieldTouched('to_storage_id', true)}
                          errors={errors}
                          touched={touched}
                          value={storages.find(option => option.value === values['to_storage_id'])}
                        />
                        <Button
                          type='submit'
                          variant='contained'
                          aria-label='submit'
                          color='primary'
                          disabled={isSubmitting}
                        >
                          Nästa
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </StepContent>
              </Step>
              <Step>
                <StepLabel
                  classes={{
                    active: classes.activeLabel
                  }}
                  StepIconComponent={QontoStepIcon}
                >
                  Välj varor
                </StepLabel>
                <StepContent classes={{
                  root: classes.stepContent
                }}>
                  <Formik
                    initialValues={storage}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      const {
                        from_storage_id,
                        to_storage_id,
                        items,
                      } = values

                      const filteredItems = items.filter(item => Boolean(item.bulk_item_id) && Boolean(item.qty))

                      values = {
                        from_storage_id,
                        to_storage_id,
                        items: filteredItems
                      }

                      dataService.createData({ model: 'storage_bulk_items/move', values })
                        .then(
                          data => {
                            setSubmitting(false)
                            setReceipt(data)
                            resetForm()
                            handleNext()
                          },
                          error => {
                            setSubmitting(false)
                            if (Array.isArray(error)) {
                              error.forEach(err => {
                                enqueueSnackbar(err.message || err.type || err, { variant: 'error' })
                              })
                            } else {
                              enqueueSnackbar(error, { variant: 'error' })
                            }
                          }
                        )
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      submitForm,
                      resetForm,
                      setFieldTouched
                    }) => (
                      <Form>
                        <ReactSelect
                          name='from_storage_id'
                          isDisabled
                          label='Från lager'
                          options={storages}
                          errors={errors}
                          touched={touched}
                          value={storages.find(option => option.value === values['from_storage_id'])}
                        />
                        <ReactSelect
                          name='to_storage_id'
                          isDisabled
                          label='Till lager'
                          options={storages}
                          errors={errors}
                          touched={touched}
                          value={storages.find(option => option.value === values['to_storage_id'])}
                        />
                        <FieldArray
                          name='items'
                          render={arrayHelpers => (
                            <React.Fragment>
                              {values.items && values.items.map((item, index) => (
                                <div
                                  key={index}
                                  className={classes.itemRow}
                                >
                                  <div className={classes.select}>
                                    <ReactSelect
                                      name={`items[${index}][bulk_item_id]`}
                                      placeholder='Välj vara'
                                      label='Plockvara'
                                      options={bulkItems}
                                      onChange={option => setFieldValue(`items[${index}][bulk_item_id]`, option ? option.value : null)
                                      }
                                      onBlur={() => setFieldTouched(`items[${index}][bulk_item_id]`, true)}
                                      errors={errors}
                                      touched={touched}
                                      value={bulkItems.find(option => option.value === values['items'][index]['bulk_item_id'])}
                                    />
                                  </div>
                                  <Field
                                    className={classes.qty}
                                    name={`items[${index}][qty]`}
                                    label={'Antal'}
                                    variant='outlined'
                                    margin='normal'
                                    type='number'
                                    fullWidth
                                    component={TextField}
                                  // InputProps={{
                                  //   inputProps: {
                                  //     onBlur: index + 1 === values.items.length ? () => arrayHelpers.push({
                                  //       bulk_item_id: '',
                                  //       qty: ''
                                  //     }) : undefined
                                  //   }
                                  // }}
                                  />
                                  <IconButton
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              ))}
                              <div className={classes.itemRow}>
                                <div className={classes.select}>
                                  <ReactSelect
                                    name='bulk_item_id'
                                    placeholder='Välj vara'
                                    label='Plockvara'
                                    options={bulkItems}
                                    autoFocus
                                    onChange={option => setFieldValue('bulk_item_id', option ? option.value : null)
                                    }
                                    onBlur={() => setFieldTouched('bulk_item_id', true)}
                                    errors={errors}
                                    touched={touched}
                                    value={bulkItems.find(option => option.value === values.bulk_item_id) || ''}
                                  />
                                </div>
                                <Field
                                  className={classes.qty}
                                  name='qty'
                                  label='Antal'
                                  variant='outlined'
                                  margin='normal'
                                  type='number'
                                  fullWidth
                                  component={TextField}
                                  InputProps={{
                                    inputProps: {
                                      onBlur: () => {
                                        arrayHelpers.push({
                                          bulk_item_id: values.bulk_item_id,
                                          qty: values.qty
                                        })
                                        setFieldValue('bulk_item_id', '')
                                        setFieldTouched('bulk_item_id', false)
                                        setFieldValue('qty', '')
                                      }
                                    }
                                  }}
                                />
                              </div>
                              {values.items && Boolean(values.items.length) && (
                                <Typography variant='body2' color='textSecondary'>{values.items.length} individer</Typography>
                              )}
                              <Button
                                type='submit'
                                variant='contained'
                                aria-label='submit'
                                color='primary'
                                disabled={isSubmitting || !values.items || values.items.length < 1}
                              >
                                Slutför
                              </Button>
                            </React.Fragment>
                          )}
                        />
                      </Form>
                    )}
                  </Formik>
                </StepContent>
              </Step>
              <Step>
                <StepLabel
                  classes={{
                    active: clsx(classes.activeLabel, classes.flexLabel)
                  }}
                  StepIconComponent={QontoStepIcon}
                >
                  Kvittens
                  {activeStep === 2 && (
                    <div>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ marginLeft: 10 }}
                        onClick={(() => setActiveStep(0))}
                      >
                        Skapa ny lagerflytt
                      </Button>
                    </div>
                  )}
                </StepLabel>
                <StepContent classes={{
                  root: classes.stepContent
                }}>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Artikel
                        </TableCell>
                        <TableCell>
                          {Boolean(receipt.length) && receipt[0].from && receipt[0].from.storage}
                        </TableCell>
                        <TableCell>
                          {Boolean(receipt.length) && receipt[0].to && receipt[0].to.storage}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {receipt.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {item.from ? item.from.bulk_item : item.to.bulk_item}
                          </TableCell>
                          <TableCell>
                            {item.from && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <ArrowDropDownIcon
                                  style={{ color: error }}
                                />
                                {item.from.qty}
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            {item.to && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <ArrowDropUpIcon
                                  style={{ color: success }}
                                />
                                {item.to.qty}
                              </div>
                            )}
                          </TableCell>


                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}
