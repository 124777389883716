import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { dataService } from './services'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: theme.spacing(2)
  }
}))

export default () => {
  const classes = useStyles()
  const [apiVersion, setApiVersion] = useState('')

  useEffect(() => {
    dataService.getData({ model: 'version', params: ''})
      .then(
        data => {
          const { version } = data
          setApiVersion(version || 'n/a')
        },
        error => {
          // console.log(error)
        }
      )
  }, [])
  return (
    <footer className={classes.root}>
      <Typography variant='caption' color='textSecondary'>
        Version: {process.env.REACT_APP_FRONTEND_VERSION}
      </Typography>
      <Typography variant='caption' color='textSecondary'>
        API version: {apiVersion} 
      </Typography>
    </footer>
  )
}