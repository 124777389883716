import React from 'react'
import Barcode from 'react-barcode'
import { Typography } from '@material-ui/core'

export default class BarcodeGenerator extends React.Component {
  render() {
    const { barcode } = this.props
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Typography variant='h5' align='center'>
          {barcode.storage}
        </Typography>
        <Barcode value={barcode.code} width={3} text={barcode.label}/>
      </div>
    )
  }
}
