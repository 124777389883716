import React from 'react'
import TextField from '@material-ui/core/TextField'

export default ({
  field: { ...fields }, 
  form: { touched, errors, ...rest },
  ...props
}) => (
  <TextField
    {...props}
    {...fields}
    error={Boolean(touched[fields.name] && errors[fields.name])}
    helperText={touched[fields.name] && errors[fields.name]} 
  />
)
